import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from "@yandex/ui/Modal/desktop/bundle";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Head,
  CloseContainer,
  Divider,
  Caption,
  WalletContainer,
  WalletItem,
  WalletImageContainer,
  StyledWalletConnectImage,
  StyledTrustWalletImage,
} from './wallet-modal.styled';

import { ReactComponent as CloseImage } from '../../asset/images/close.svg';
import { ReactComponent as MetamaskImage } from "../../asset/images/Wallet/metamask.svg";
import { ReactComponent as CoinbaseImage } from "../../asset/images/Wallet/coinbase.svg";

import { setWalletModalOpen, selectWalletModalOpen } from "../../store/slices/app";
import { useWallet } from "./use-wallet";
import { Title } from '../../components/title/title';
import styled from 'styled-components';
import { TextMBold } from '../../components/UI/typography';
import { availableWallets } from './wallets';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../common/utils';
import { breakpoints } from '../../components/UI/breakpoints';
import { NoMetaMaskError } from '@web3-react/metamask';
import Loading from '../../components/UI/loading';
import { getInjection } from './utils';

export function WalletModal(): JSX.Element {
  const { t } = useTranslation();
  const [pendingWalletKey, setPendingWalletKey] = useState<string | null>(null);
  const promiseRef = useRef<Promise<unknown> | null>(null);
  const { account, isActive } = useWeb3React();
  const { connect, selectedWallet, disconnect } = useWallet();
  const dispatch = useDispatch();
  const walletModalOpen = useSelector(selectWalletModalOpen);

  useEffect(() => () => {
    promiseRef.current = null;
  }, []);

  const mapWalletToImage = {
    injected: getInjection().key === 'trust' ? <StyledTrustWalletImage /> : <MetamaskImage />,
    walletConnect: <StyledWalletConnectImage />,
    coinbase: <CoinbaseImage />,
  };

  return (
    <Modal
      theme="normal"
      // onClose={() => dispatch(setWalletModalOpen(false))}
      visible={walletModalOpen}
      zIndexGroupLevel={20}
    >
      <Container>
        <Head>
          <Title title={t(`Modal.Wallet.Title`)} />
          <CloseContainer onClick={() => dispatch(setWalletModalOpen(false))}>
            <CloseImage />
          </CloseContainer>
        </Head>
        <Divider />
        <Caption>{t(`Modal.Wallet.Caption`)}</Caption>
        <WalletContainer>
          {availableWallets.map((item, index) => {
            if (isMobile && !item.mobile) return null;
            return (
              <WalletItem
                key={index}
                onClick={() => {
                  disconnect();
                  if (pendingWalletKey || item.key === selectedWallet?.key) return;
                  setPendingWalletKey(item.key);
                  if (item.qr) {
                    dispatch(setWalletModalOpen(false));
                  }
                  promiseRef.current = connect(item).then(() => {
                    dispatch(setWalletModalOpen(false));
                  }).catch((e) => {
                    if (e instanceof NoMetaMaskError) window.open(item.urlToInstall, '_blank')?.focus();
                  }).finally(() => {
                    if (!promiseRef.current) return;
                    setPendingWalletKey(null);
                    promiseRef.current = null;
                  });
                }}
              >
                <WalletImageContainer>{mapWalletToImage[item.key]}</WalletImageContainer>
                <WalletTitle>{item.getTitle()} {pendingWalletKey === item.key && <StyledLoading variant="primary" />}</WalletTitle>
                {selectedWallet && selectedWallet.key === item.key && isActive && <>
                  {account && <StyledAddress>{shortenAddress(account)}</StyledAddress>}
                  <DisconnectButton onClick={(e) => {
                    e.stopPropagation();
                    disconnect();
                  }}>Disconnect</DisconnectButton>
                </>}
              </WalletItem>
            );
          })}
        </WalletContainer>
      </Container>
    </Modal>
  );
}

const WalletTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLoading = styled(Loading)`
  margin-left: 8px;
`;

const StyledAddress = styled(TextMBold)`
  margin-left: 12px;
  color: #9A9FA5;

  @media screen and (max-width: ${breakpoints.mobileM}px) {
  	display: none;
  }
`;

const DisconnectButton = styled(TextMBold)`
	margin-left: auto;
  color: #FF6A55;
  cursor: pointer;
`;
