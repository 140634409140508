import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { UserProfile, UserProfileState } from '../../interfaces/profile';
import * as API from '../../features/profile/api';
import { DefundsEthClient } from '../../contracts/defunds-eth-client';
import { Web3Provider } from '@ethersproject/providers';
import { ModalType } from '../../interfaces/ui';
import { ModalData } from '../../components/modal/modal-provider';
import { helperToast } from '../../common/toast';

const initialState: UserProfileState = {
	profile: null,
	loading: false,
};

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setProfile: (state: UserProfileState, action: PayloadAction<UserProfile>) => {
			state.profile = action.payload;
		},
		setLoading: (state: UserProfileState, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		}
	},
});

export const updateProfile = createAsyncThunk<void,
	{ provider: Web3Provider, ethClient: DefundsEthClient; name: string; email: string; CV: string; account: string; image: string | null; openModal: ({ type }: ModalData) => void; },
	{ state: RootState }>(
	'balances/updateBalance',
	async (
		{ openModal, provider, ethClient, name, email, CV, account, image },
		{ dispatch, rejectWithValue },
	) => {
		try {
			const signature = await provider.getSigner().signMessage('edit profile');
			if (signature) {
				const profile = await API.saveProfile({ address: account, signature, name, email, CV, image });
				dispatch(setProfile({ ...profile, updatedAt: Date.now() }));
				openModal({ type: ModalType.Success });
			}
		} catch (e: any) {
			let message = `Error updating profile`;
			if (e.message.includes('unknown format')) {
				message += ': image parsing error. Please try another image.';
			}
			helperToast.error(message);
			return rejectWithValue(null);
		}
	},
);

export const getProfile = createAsyncThunk<void, { account: string }, { state: RootState }>(
	'balances/updateBalance',
	async (
		{ account },
		{ dispatch },
	) => {
		try {
			dispatch(setLoading(true));
			const profile = await API.getProfile(account);
			dispatch(setProfile(profile));
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(setLoading(false));
		}
	},
);

export const {
	setProfile,
	setLoading,
} = profileSlice.actions;

export const selectProfile = (state: RootState): UserProfile | null => state.profile.profile;
export const selectLoading = (state: RootState): boolean => state.profile.loading;

export const profileReducer = profileSlice.reducer;
