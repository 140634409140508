import { Fund } from '../interfaces/fund';
import { BigNumber } from 'ethers';
import { useReadonlyTradingContract } from './use-readonly-trading-contract';
import { useQuery } from '@tanstack/react-query';

export function useFundDebt(fund: Fund | null): BigNumber | null {
	const trading = useReadonlyTradingContract(fund);
	const { data } = useQuery(['debt', fund], () => {
		return trading?.debt() ?? Promise.reject();
	});
	return data || null;
}
