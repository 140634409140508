import { BigNumber, ethers } from 'ethers';
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
	useRef,
	Fragment,
} from 'react';
import { useCopyToClipboard, usePrevious } from 'react-use';
import { getApproxSubaccountActionsCount, getButtonState } from './utils';
import { Button, TransparentButton } from '../../../components/UI/button';
import {
	useIsSubaccountActive,
	useSubaccount,
	useSubaccountContext,
} from './subaccount-provider';
import { formatTokenAmount } from '../gmx/v2/lib/tokens';
import { useWeb3React } from '@web3-react/core';
import { useTradeContext } from '../trade-provider';
import { useTransactionPending } from '../../../hooks/use-transaction-receipt';
import { SubaccountNotification } from './subaccount-notification';
import { toast } from 'react-toastify';
import { useGasPrice } from '../../../hooks/use-gas-price';
import { ExternalLink } from '../../../components/UI/external-link';
import { InfoLine } from '../../../components/info-line';
import { SubaccountRegistry__factory } from '../../../contracts/types';
import { contractAddresses, getAccountUrl, networkParams } from '../../../common/constants';
import { withdrawFromSubaccount } from './withdraw-from-subaccount';
import { SubaccountStatus } from './subaccount-status';
import { shortenAddressOrEns } from '../../../common/utils';
import { InputWidget } from '../../../components/UI/input-widget';
import { ReactComponent as CopyIcon } from '../../../asset/images/copy.svg';
import { ReactComponent as ExternalUrlIcon } from '../../../asset/images/external-url.svg';
import styled, { css } from 'styled-components';
import { formatUsd } from '../gmx/utils';
import { TextS, TextSM, TextSMBold } from '../../../components/UI/typography';
import { Row } from '../../../components/UI/row';
import { useEthBalance } from '../../../hooks/use-eth-balance';
import { useBaseCurrencyPrice } from '../../../hooks/use-base-currency-price';

const DUST_ETH = ethers.utils.parseEther('0.0001');

// TODO: i18n
const Trans = Fragment;

export type FormState = 'empty' | 'inactive' | 'activated';

export function SubaccountModalContent(): JSX.Element {
	const { chainId } = useTradeContext();
	const { provider, account } = useWeb3React();
	const [withdrawalLoading, setWithdrawalLoading] = useState(false);
	const [isSubaccountUpdating, setIsSubaccountUpdating] = useState(false);
	const subaccountContext = useSubaccountContext();
	const {
		subaccount: subaccountKeyPair,
		defaultExecutionFee: baseFeePerAction,
		notificationState,
		setNotificationState,
		clearSubaccount,
		generateSubaccount,
		closeModal,
		activeTx,
		setActiveTx,
		refetch: refetchSubaccount,
	} = subaccountContext;
	const subaccountAddress = subaccountKeyPair?.address ?? null;
	const nativeTokenSymbol = networkParams[chainId].nativeCurrency.symbol;
	const nativeTokenDecimals = networkParams[chainId].nativeCurrency.decimals;
	const baseCurrencyPrice = useBaseCurrencyPrice(chainId);
	const { balance: mainAccNativeTokenBalance, refetchBalance: refetchMainEthBalance } = useEthBalance(account, 5000);
	const { balance: subAccNativeTokenBalance, refetchBalance: refetchSubaccEthBalance } = useEthBalance(subaccountKeyPair?.address, 5000);
	const subaccountExplorerUrl = useMemo(
		() => subaccountAddress ? getAccountUrl(chainId, subaccountAddress) : null,
		[chainId, subaccountAddress]
	);
	const topUpInputRef = useRef<HTMLInputElement>(null);

	const approxNumberOfOperationsByBalance = useMemo(() => {
		return subAccNativeTokenBalance &&
		baseFeePerAction &&
		mainAccNativeTokenBalance
			? getApproxSubaccountActionsCount(
				subAccNativeTokenBalance,
				baseFeePerAction,
			)
			: null;
	}, [baseFeePerAction, mainAccNativeTokenBalance, subAccNativeTokenBalance]);

	const renderSubaccountBalanceTooltipContent = useCallback(() => {
		let value: ReactNode = '';

		value = approxNumberOfOperationsByBalance?.toString() ?? `Unknown`;
		return (
			<div>
				<Trans>
					Subaccount {nativeTokenSymbol} Balance is used to pay for the Network Fees. Use the "Top-up" field if you
					need to transfer {nativeTokenSymbol} to your Subaccount.
				</Trans>
				{approxNumberOfOperationsByBalance && <>
					<br />
					<br />
					<InfoLine currency="" title={`Expected Available Actions`} value={value} />
					<br />
					<Trans>Expected Actions are based on the current Network Fee.</Trans>
				</>}
			</div>
		);
	}, [approxNumberOfOperationsByBalance, nativeTokenSymbol]);

	const renderMainAccountBalanceTooltipContent = useCallback(() => {
		return (
			<div>
				<Trans>
					Main Account {nativeTokenSymbol} Balance is used to top up Subaccount Balance
				</Trans>
				<br />
				<br />
				<InfoLine
					title={nativeTokenSymbol}
					value={formatTokenAmount(mainAccNativeTokenBalance, nativeTokenDecimals, nativeTokenSymbol, {
						displayDecimals: 4,
					})}
					currency=""
				/>
			</div>
		);
	}, [mainAccNativeTokenBalance, nativeTokenDecimals, nativeTokenSymbol]);

	const [, copyToClipboard] = useCopyToClipboard();
	const [formState, setFormState] = useState<FormState>('empty');
	const [nextFormState, setNextFormState] = useState<FormState>('empty');
	const [topUp, setTopUp] = useState('');

	const isSubaccountActive = useIsSubaccountActive();
	const prevIsSubaccountActive = usePrevious(isSubaccountActive);
	const isTxPending = useTransactionPending(activeTx);
	const prevIsTxPending = usePrevious(isTxPending);

	useEffect(() => {
		if (nextFormState === formState) return;
		if (isSubaccountUpdating) return;

		setTopUp('');
		if (!isSubaccountActive && nextFormState === 'inactive') {
			setFormState('inactive');
		} else if (isSubaccountActive && nextFormState === 'activated') {
			setFormState('activated');
		} else if (nextFormState === 'empty') {
			setFormState('empty');
		}
	}, [isSubaccountUpdating, formState, isSubaccountActive, nextFormState, setTopUp]);

	useEffect(() => {
		if (!isTxPending) {
			setIsSubaccountUpdating(false);
		}
	}, [isTxPending]);


	const isSubaccountGenerated = Boolean(subaccountKeyPair?.address);
	const toastId = useMemo(() => Date.now(), []);

	const renderToast = useCallback(() => <SubaccountNotification
		chainId={chainId}
		toastId={toastId}
		subaccountWasAlreadyGenerated={isSubaccountGenerated}
		subaccountWasAlreadyActivated={isSubaccountActive}
		activeTx={activeTx}
		notificationState={notificationState}
	/>, [activeTx, chainId, isSubaccountActive, isSubaccountGenerated, notificationState, toastId]);

	const showToast = useCallback(() => {
		toast(renderToast(), { autoClose: false, toastId });
	}, [renderToast, toastId]);

	const { text: buttonText, disabled } = useMemo(
		() =>
			getButtonState({
				mainAccEthBalance: mainAccNativeTokenBalance,
				subaccountAddress,
				topUp: topUp ? BigNumber.from(ethers.utils.parseEther(topUp)) : null,
				withdrawalLoading,
				formState,
				notificationState,
				isTxPending,
				isSubaccountActive,
				accountUpdateLoading: isSubaccountUpdating,
				nativeTokenSymbol: nativeTokenSymbol,
			}),
		[mainAccNativeTokenBalance, subaccountAddress, topUp, formState, withdrawalLoading, notificationState, isTxPending, isSubaccountActive, isSubaccountUpdating, nativeTokenSymbol],
	);

	const gasPrice = useGasPrice(chainId);

	const subaccount = useSubaccount(chainId, null);

	const handleWithdrawClick = useCallback(async () => {
		if (withdrawalLoading) return;
		if (!subaccount) throw new Error('privateKey is not defined');
		if (!account) throw new Error('account is not defined');
		if (!provider) throw new Error('signer is not defined');
		if (!subAccNativeTokenBalance) throw new Error('subEthBalance is not defined');
		if (!gasPrice) throw new Error('gasPrice is not defined');

		setWithdrawalLoading(true);

		try {
			toast(
				`Withdrawing ${formatTokenAmount(subAccNativeTokenBalance, nativeTokenDecimals, nativeTokenSymbol, {
					displayDecimals: 4,
				})} to Main Account`,
			);

			await withdrawFromSubaccount(subaccount, account);

			toast(
				`Withdrawn ${formatTokenAmount(subAccNativeTokenBalance, nativeTokenDecimals, nativeTokenSymbol, {
					displayDecimals: 4,
				})} to Main Account`,
			);
			refetchMainEthBalance();
			refetchSubaccEthBalance();
		} finally {
			setWithdrawalLoading(false);
		}
	}, [withdrawalLoading, subaccount, account, provider, subAccNativeTokenBalance, gasPrice, nativeTokenDecimals, nativeTokenSymbol, refetchMainEthBalance, refetchSubaccEthBalance]);

	useEffect(() => {
		toast.update(toastId, { render: renderToast() });
	}, [notificationState, activeTx, toastId, renderToast]);

	const handleDeactivateClick = useCallback(async () => {
		if (!subaccountAddress) throw new Error('Subaccount address is not set');
		if (!provider) throw new Error('Signer is not set');

		showToast();

		setNotificationState('deactivating');
		setIsSubaccountUpdating(true);
		if (isSubaccountActive) {
			try {
				if (subAccNativeTokenBalance.gte(DUST_ETH)) {
					await handleWithdrawClick();
				}
				const contract = SubaccountRegistry__factory.connect(contractAddresses[chainId].subaccountRegistry, provider.getSigner());
				const res = await contract.disableAccount(subaccountAddress);
				setActiveTx(res.hash);
			} catch (err) {
				setNotificationState('deactivationFailed');
				setIsSubaccountUpdating(false);
				throw err;
			}
		}

		clearSubaccount();
		setIsSubaccountUpdating(false);
		setNotificationState('deactivated');
		setNextFormState('inactive');
	}, [chainId, clearSubaccount, handleWithdrawClick, isSubaccountActive, provider, setActiveTx, setNotificationState, showToast, subAccNativeTokenBalance, subaccountAddress]);

	useEffect(() => {
		setNextFormState(isSubaccountActive ? 'activated' : 'inactive');
	}, [isSubaccountActive, setNextFormState]);

	useEffect(() => {
		if (prevIsTxPending === true && !isTxPending) {
			setActiveTx(null);
			setTopUp('');
			setNextFormState('activated');

			if (!prevIsSubaccountActive) {
				closeModal();
			}
		}
	}, [isTxPending, prevIsTxPending, prevIsSubaccountActive, closeModal, setActiveTx, setNextFormState, setTopUp]);

	useEffect(() => {
		if (!isTxPending && prevIsTxPending && notificationState === 'activating') {
			setNotificationState('activated');
		}

		if (!isTxPending && prevIsTxPending && notificationState === 'deactivating') {
			setNotificationState('deactivated');
		}
	}, [isTxPending, notificationState, prevIsTxPending, setNotificationState]);


	const handleButtonClick = useCallback(async () => {

		setIsSubaccountUpdating(true);
		try {
			if (!account) throw new Error('Account is not defined');
			if (!provider?.getSigner()) throw new Error('Signer is not defined');

			let address = subaccountAddress;

			setNotificationState(isSubaccountGenerated ? 'activating' : 'generating');
			showToast();

			if (!isSubaccountGenerated) {
				try {
					address = await generateSubaccount();

					// user rejects
					if (!address) {
						setNotificationState('generationFailed');
						return;
					}
				} catch (error) {
					console.error(error);
					setNotificationState('generationFailed');
					throw error;
				}
			}

			if (!address) {
				setNotificationState('activationFailed');
				throw new Error('address is not defined');
			}

			setNotificationState('activating');

			try {
				const value = ethers.utils.parseEther(topUp || '0');
				let tx: ethers.ContractTransaction | null = null;
				if (!subaccountAddress) {
					const contract = SubaccountRegistry__factory.connect(contractAddresses[chainId].subaccountRegistry, provider?.getSigner());
					const currentOwner = await contract.accountOwners(address);
					if (currentOwner.toLowerCase() === account.toLowerCase()) {
						if (value.gt(0)) {
							tx = await provider?.getSigner().sendTransaction({
								to: address,
								value,
							});
						}
					} else {
						tx = await contract.enableAccount(address, { value });
					}
				} else {
					tx = await provider?.getSigner().sendTransaction({
						to: subaccountAddress,
						value,
					});
				}
				if (tx) {
					setActiveTx(tx.hash);
					await tx.wait();
				}
				setIsSubaccountUpdating(false);
				setNotificationState('activated');
				refetchMainEthBalance();
				refetchSubaccEthBalance();
				refetchSubaccount();
			} catch (err) {
				setNotificationState('activationFailed');
				throw err;
			}
		} catch (error) {
			// if success - setIsSubaccountUpdating will be set to false in useEffect
			setIsSubaccountUpdating(false);
			throw error;
		}
	}, [account, provider, subaccountAddress, setNotificationState, isSubaccountGenerated, showToast, generateSubaccount, topUp, refetchMainEthBalance, refetchSubaccEthBalance, refetchSubaccount, chainId, setActiveTx]);

	useEffect(() => {
		setNotificationState('none');
	}, [chainId, setNotificationState]);

	const handleCopyClick = useCallback(() => {
		if (!subaccountAddress) return;

		copyToClipboard(subaccountAddress);
		toast(`Address copied to your clipboard`);
	}, [copyToClipboard, subaccountAddress]);

	const subAccNativeTokenBalanceFormatted = useMemo(
		() =>
			formatTokenAmount(subAccNativeTokenBalance, nativeTokenDecimals, nativeTokenSymbol, {
				displayDecimals: 4,
			}),
		[nativeTokenDecimals, nativeTokenSymbol, subAccNativeTokenBalance],
	);

	const focusTopUpInput = useCallback(() => {
		topUpInputRef.current?.focus();
	}, []);

	return (
		<div>
			<Container>
				<SubaccountStatus onTopUpClick={focusTopUpInput} />
				{subaccountAddress && (
					<Row>
						<StyledInfoLine currency="" title="Subaccount:" value={<>
							{shortenAddressOrEns(subaccountAddress ?? '', 13)}
							<CopyButton onClick={handleCopyClick} />
							{subaccountExplorerUrl && <LinkWrap href={subaccountExplorerUrl}>
                <ExternalUrlIcon />
              </LinkWrap>}
						</>} />
					</Row>
				)}
				{subaccountAddress && <InfoLine
          title={<Row>
						<span>Subaccount Balance</span>
						{subAccNativeTokenBalance?.gte(DUST_ETH) && <WithdrawButton disabled={withdrawalLoading} onClick={handleWithdrawClick}>
              ({withdrawalLoading ? <Trans>withdrawing...</Trans> : <Trans>withdraw</Trans>})
            </WithdrawButton>}
					</Row>}
          value={subAccNativeTokenBalanceFormatted}
          valueTooltip={{
						content: renderSubaccountBalanceTooltipContent(),
					}}
          currency=""
        />}
				<MainAccLine
					title={`Main Account Balance`}
					value={formatTokenAmount(mainAccNativeTokenBalance, nativeTokenDecimals, nativeTokenSymbol, {
						displayDecimals: 4,
					})}
					valueTooltip={{
						direction: ['top', 'right'],
						content: renderMainAccountBalanceTooltipContent(),
					}}
					currency=""
				/>
			</Container>
			<StyledInputWidget
				ref={topUpInputRef}
				value={topUp}
				onChange={setTopUp}
				currency={networkParams[chainId].nativeCurrency.symbol}
			>
				{topUp && baseCurrencyPrice && <TopUpAmountUSD>~${formatTokenAmount(ethers.utils.parseEther(topUp).mul(baseCurrencyPrice), nativeTokenDecimals)}</TopUpAmountUSD>}
			</StyledInputWidget>
			<Description>
				This amount of {nativeTokenSymbol} will be sent from your Main Account to your Subaccount to pay for transaction fees.
			</Description>
			<StyledButton onClick={handleButtonClick} disabled={disabled}>
				{buttonText}
			</StyledButton>
			{subaccountAddress && <DeactivateButton disabled={notificationState === 'deactivating'} onClick={handleDeactivateClick}>
				{notificationState === 'deactivating' ? <Trans>Deactivating Subaccount...</Trans> : <Trans>Deactivate Subaccount</Trans>}
      </DeactivateButton>}
		</div>
	);
}

const Container = styled.div`
	padding: 0 4px;
`;

const TopUpAmountUSD = styled(TextS)`
	position: absolute;
	top: 12px;
	left: 22px;
	color: #888;
`;

const MainAccLine = styled(InfoLine)`
	margin-bottom: 16px;
`;

const DeactivateButton = styled(TransparentButton)`
	border: 2px solid #FF6A55;
	margin-top: 12px;
	color: #FF6A55;
	width: calc(100% - 40px);
`;

const StyledButton = styled(Button)`
	width: calc(100% - 40px);
`;

const Description = styled(TextSM)`
	margin: 16px 4px;
`;

const StyledInfoLine = styled(InfoLine)`
	display: flex;
	align-items: center;
	width: 100%;
`;

const StyledInputWidget = styled(InputWidget)`
	position: relative;
`;

const LinkWrap = styled(ExternalLink)`
	height: 20px;
	width: 20px;
	outline: none;
`;

const WithdrawButton = styled(TextSMBold)<{ disabled?: boolean }>`
	color: #2a85ff;
	cursor: pointer;
	margin-left: 4px;

	${props => props.disabled && css`
		opacity: .4;
		cursor: default;
	`};
`;

const CopyButton = styled(CopyIcon)<{ disabled?: boolean }>`
	cursor: pointer;
	margin-left: 8px;
`;
