import { useEffect } from "react";
import { getWhiteLists } from './slice';
import { useAppDispatch } from '../../hooks/redux';
import { ChainId, SUPPORTED_CHAINS } from '../../common/constants';

export function useLoadWhiteLists(): void {
  const dispatch = useAppDispatch();
  useEffect(() => {
    for (const chainId of SUPPORTED_CHAINS) {
      dispatch(getWhiteLists({ chainId: chainId as unknown as ChainId }));
    }
  }, [dispatch]);
}
