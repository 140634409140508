const translation = {
  hi: "Привет",
  Tooltip: {
    Sf: 'На данный момент с инвестора будет взиматься комиссия в размере % от суммы депозита в USDT (<Link>подробнее</Link>).',
    Pf: 'Эта комиссия является вашей компенсацией за генерацию положительной прибыли для ваших инвесторов. Она вычитается из прибыли фонда только в случае достижения положительной прибыли за отчетный период (<Link>подробнее</Link>).',
    ReportingPeriod: 'Это период, в течение которого оценивается производительность фонда и рассчитываются комиссии. Заявки на вывод и депозиты также обрабатываются в конце каждого отчетного периода (<Link>подробнее</Link>).',
    Indent: 'Заявки на вывод, поданные в течение периода ожидания, переносятся на следующий отчетный период (<Link>подробнее</Link>).',
    HWM: 'Включение этой функции обеспечивает взымание комиссий по результатам только с новых прибылей, превышающих предыдущую максимальную стоимость фонда. Это предотвращает взымание комиссий с ранее потерянного капитала, который был восстановлен (<Link>подробнее</Link>).',
    HWMValue: 'Управляющий получит комиссию за результат в следующем отчете, если доходность фонда превысит это значение <Link>(подробнее)</Link>.',
    AvailableTokens: 'У каждого фонда есть свой список разрешенных токенов ERC20, с которыми менеджер может оперировать (<Link>подробнее</Link>).',
    Invested: 'Сумма инвестированных USDT в этот фонд за все время',
    Withdrawn: 'Сумма выведенных USDT в этот фонд за все время',
    Pnl: 'Как много вы заработали с этого фонда за все время',
    CommissionsPaid: 'Сумма комиссий, которые вы заплатили этому фонду за все время',
    ProfileCV: 'Несколько слов о вас',
    SlippageTolerance: 'Допустимое влияние на цену в процентах',
    FundsReceived: 'Сумма средств, которая будет зачислена на этот фонд на момент отчета <Link>(подробнее)</Link>.',
    Withdrawals: 'Сумма средств, которая будет выведена из этого фонда на момент отчета <Link>(подробнее)</Link>.',
    MustBePaid: 'Сумма средств, которую вам необходимо иметь на балансе фонда заранее перед отчетом, чтобы избежать нежелательного автоматического закрытия ваших позиций <Link>(подробнее)</Link>.',
    DirectTransfers: 'Наличие прямых переводов в обход стандартного механизма инвестирования может свидетельствовать о манипулировании показателями доходности.',
    TokenDescription: 'Размер вашей доли в этом фонде',
  },
  Sidebar: {
    Menu: {
      Market: "Рынок DeFunds",
      Fund: "Мои DeFunds",
      Investment: "Мои инвестиции",
    },
    Theme: {
      Light: "Светлая",
      Dark: "Тëмная",
      Soon: "Скоро",
    },
  },
  Navbar: {
    ConnectWallet: "Ваш аккаунт",
    SwitchNetwork: "Сменить сеть",
    UnsupportedNetwork: "Сеть не поддерживается",
    ApproveInWallet: "Подтверждение в кошельке...",
  },
  Modal: {
    Success: {
      Title: "Успешно!",
      Caption: "DeFund создан успешно",
      Button: {
        SeeMyFunds: "К моим фондам",
      },
    },
    Wallet: {
      Title: "Выберите кошелёк",
      Caption: "Выберите кошелёк, чтобы войти на сайт DeFunds",
      InstallMetamask:
        "Вам необходимо установить MetaMask, чтобы продолжить. После того, как вы его установили, обновите страницу.",
      Button: {
        Back: "Назад к кошелькам",
        OpenMetaMask: "Открыть MetaMask",
      },
      WalletNotConnectedToChain: 'Ваш кошелек не подключен к {{chain}}',
      UnsupportedChain: "Сеть, выбранная в вашем кошельке, недоступна. Доступные сети: {{supportedChainNames}}."
    },
  },
  Investment: {
    Indicators: {
      Title: "Статистика моих инвестиций",
      CurrentInvestments: "Суммарно вложено",
      Invested: "вложено",
      Withdrawn: "выведено",
      ProfitOrLoss: "Доход / Убыток",
      Profitability: "Доходность",
    },
    Chart: {
      Title: "График доходности",
    },
    Summary: {
      Title: "Статистика доходности",
      MaxDrawdown: "Макс. просадка",
      Current: "Текущая",
      Max: "Максимальный",
      AllTimeIncome: "Доход за всё время",
    },
    Empty: {
      Title: "У вас пока нет инвестиций",
      Caption: "Перейдите к рынку DeFunds и выберите фонд, в который вы хотите инвестировать",
      Button: "К рынку DeFunds",
    },
    MyDeFunds: {
      Title: "Мои инвестиции в DeFunds",
      Active: "Активные",
      Closed: "Закрытые",
      EmptyTitle: "Инвестиции не найдены",
    }
  },
  Profile: {
    Title: "Информация о профиле",
    NameLabel: "Имя",
    NamePlaceholder: "Введите имя",
    EmailLabel: "Адрес электронная почта",
    EmailPlaceholder: "Введите адрес электронной почты",
    DescriptionPlaceholder: "Введите описание",
    Notifications: "Уведомления",
    ReportingPeriod: "Отчетный период",
    FundConditionsChange: "Изменение параметров фонда",
    CV: "Резюме",
    Name: "Имя",
    Address: "Адрес",
    Email: "Почта",
  },
  Swap: {
    SettingsModal: {
      Title: "Настройки",
      Auto: "По умолчанию",
    },
    TokenListModal: {
      Title: "Выберите токен",
      SearchPlaceholder: 'Найти токен',
    },
    Button: {
      ChooseTokens: "Выберите пару токенов",
      ChooseAmount: "Введите сумму",
      InsufficientBalance: "Сумма превышает баланс",
      Swap: "Обменять",
      SelectToken: "Выберите токен",
    },
    Balance: "Баланс",
    ExchangeRate: "Курс обмена",
    MinimumReceived: "Ожидается к получению",
    PriceImpact: "Влияние на цену",
    SlippageTolerance: "Допустимое проскальзывание",
    NetworkFee: "Комиссия сети",
    Error: {
      Title: "Ошибка от 0x",
      Description: "Вероятно, курс обмена устарел",
      TryOptions: "Чтобы избежать повторного возникновения проблемы, вы можете",
      Option1: "Увеличить проскальзывание в настройках",
      Option2: "Изменить сумму обмена",
      Option3: "Повторно совершить обмен с обновлённым курсом",
      NoEth: 'Недостаточно {{baseCurrency}}, чтобы покрыть комиссию'
    },
    SuccessTitle: "Вы успешно обменяли <TextBlue>{{from}}</TextBlue> на <TextBlue>{{to}}</TextBlue>",
  },
  Trade: {
    OneClickTrading: "Моментальная торговля",
  },
  Market: {
    ListWidget: {
      SearchPlaceholder: "Найти DeFund",
    },
    Empty: {
      Title: "DeFunds не найдены",
    },
    Filter: {
      Title: "Показано {{count}} фонда из {{total}}",
      Risk: "Риск",
      High: "Высокий",
      Medium: "Средний",
      Low: "Низкий",
      ValidityPeriod: "Период валидации",
      All: "Любой",
      Rating: "Рейтинг",
      Options: "Настройки",
      ExtendedRM: "Расширенный риск-менеджмент",
      KYCManager: "KYC Менеджер",
    },
  },
  Fund: {
    Activity: "Активность",
    NoActivity: "Активности нет",
    AUM: "AUM:",
    FundsReceived: "Средств получено",
    Withdrawal: "Средств снято",
    Withdrawn: "Выведено",
    ReportPeriod: "Отчетный период:",
    IsPrivate: "Приватный фонд",
    ManagerAddress: "Адрес менеджера",
    ManagerShare: "Доля менеджера",
    ManagerSettings: "Настройки менеджера",
    NextReport: "Следующий отчет",
    Income: "Прибыль",
    NoDescription: "Нет описания",
    Yield: "Доходность",
    CommissionEarned: "Комиссии получено",
    CommissionPaid: "Комиссии оплачено",
    UnclaimedCommissions: "Комиссий к выводу",
    Claim: "Вывести",
    PendingPf: "Комиссия за текущий период",
    TotalInvestors: "Всего инвесторов:",
    AnnualYield: "Годовая прибыль:",
    Investors: "Инвесторов",
    HWMLevel: "Уровень HWM",
    InvestmentIndicators: "Показатели фонда",
    SubscriptionFee: "Комиссия за подписку",
    ManagementFee: "Комиссия за управление",
    PerformanceFee: "Комиссия за прибыль",
    ExecutionFee: "Комиссия за выполнение",
    ExecutionFeeDescription: "Эта комиссия покрывает транзакционные издержки и взымается в USDT из баланса фонда в момент отчета <Link>(подробнее)</Link>.",
    PerReport: "за отчёт",
    Performance: "Доходность",
    PendingDeposit: "Ожидается к пополнению",
    PendingWithdrawal: "Ожидается к выводу",
    InvestmentConditions: "Условия инвестирования",
    InvestmentDate: "Дата инвестирования",
    Description: 'Описание',
    Commissions: "Коммиссии",
    WhiteList: "Список токенов",
    Indent: "Смещение",
    Top50CapExtended: "Топ 50 по капитализации (расширенный)",
    Top50CapToBTC: "Топ 50 по капитализации к BTC",
    Top50CapToStables: "Топ 50 по капитализации к USDT/USDC/DAI",
    AvailableTokens: "Разрешенные токены",
    MyAssets: "Моих средств в этом DeFund",
    MustBeRepaid: "К выплате",
    EditFund: "Редактировать DeFund",
    UpdateTime: "Время обновления",
    BackToFund: "Назад к фонду",
    Trading: "Торговля",
    PendingUpdateRequest: "Запрос на обновление менеджера",
    RequestUpdate: "Запросить обновление",
    RequestUpdateManager: "Запросить обновление менеджера",
    CancelUpdateManager: "Отменить обновление менеджера",
    TransferOwnership: "Передать владение фондом",
    RequestUpdateDescription: "Ваше обновление будет обрабатываться в течение {{дней}} дней. По истечении этого времени вы сможете выполнить транзакцию, которая завершит обновление. Инвесторы будут уведомлены о обновлении и смогут вывести свои средства до его выполнения.",
    LowAumTooltipText: "В целях экономии средств мы не генерируем отчеты для фондов с AUM ниже {{threshold}} USDT. Для продолжения работы с фондом вам нужно инвестировать еще <TextBlue>{{needToInvestAmount}} USDT</TextBlue>.",
    Empty: {
      Title: "Вы пока не создали ни одного DeFund",
      Caption: "Чтобы начать торговлю, нужно создать DeFund",
      Button: "Создать DeFund",
    },
    InvestModal: {
      Title: "Вы инвестируете в DeFund со следующими параметрами",
      AmountLabel: "Введите сумму",
      Available: "Доступно",
      PendingInfo: "Ваши токены будут внесены в контракт в начале отчетного периода",
    },
    WithdrawModal: {
      Title: "Вывод средств",
      SuccessText: "Вы получите USDT на ваш кошелек во время отчета",
      RedemptionLabel: "Это примерное значение, финальное количество будет посчитано и отправлено в моменту отчета",
      AmountLabel: "Введите сумму",
      Tokens: "токенов",
      Available: "Доступно",
      IndentDescription: "Вы собираетесь подать заявку на вывод средств во время смещения - она будет обработана уже в следующем отчетном периоде.",
      AmountToReceive: "Вы получите"
    },
    Period: {
      Title: "Отчётный период",
      Hourly: "Раз в час",
      Monthly: "Раз в месяц",
      Weekly: "Раз в неделю",
      Quarterly: "Раз в квартал",
    },
    HWM: {
      Title: "HWM",
      Enabled: "Включено",
      Disabled: "Отключено",
    },
    RiskManagement: {
      Title: 'Управление рисками',
      StopOnDeposit: 'Стоп на депозит',
      StopOnDepositDesc: 'Срабатывает, когда фонд достигает максимальной просадки. После срабатывания торговые операции становятся недоступными, и фонд работает только на вывод средств.',
      StopOnManager: 'Стоп на менеджера',
      StopOnManagerDesc: 'Срабатывает при достижении фондом просадки, просадка считается с момента назначения менеджера. После срабатывания менеджером становится владелец фонда.',
    },
    MaxDD: "Макс. просадка",
    Invested: "Вложено",
    Button: {
      Details: "Страница фонда",
      Trade: "Торговать",
      Invest: "Инвестировать",
      Withdraw: "Вывод средств",
      BackToMarket: "Назад к списку DeFunds",
    },
  },
  CreateFund: {
    Title: "Создать DeFund",
    MainInfo: "Общая информация",
    Name: "Название",
    NamePlaceholder: "Введите название",
    Description: "Описание",
    DescriptionPlaceholder: "Введите описание",
    WhiteList: "Торгуемые пары",
    CreateDescription: `Вы создаёте новый DeFund в сети {{network}}. От вас потребуется подтвердить транзакцию с помощью
подключенного на текущий момент кошелька. С вас будет снято {{cost}} USDT за создание фонда, и небольшая сумма в {{currency}} за газ.
Финальная сумма комиссии за транзакцию будет определена вашим кошельком.`,
    ConfirmCreateFundLabel: "Я согласен с тем, что комиссия за создание фонда составляет {{cost}} USDT. Финальный размер комиссии будет опредён кошельком.",
    ConfirmButton: "Подтвердить",
    Modal: {
      Title: "Вы создаёте DeFund со следующими параметрами",
      Trader: "Трейдер",
      Service: "Сервис",
      Investor: "Инвестор",
      Private: "Фонд закрыт для сторонних инвесторов",
      PeriodDescription: "Подсчёт выплат и комиссий будет происходить <TextBlue>{{period}}</TextBlue> со смещением в <TextBlue>{{indent}}</TextBlue>, если баланс фонда выше <TextBlueCapital>{{threshold}} USDT</TextBlueCapital>",
      HVMDescription: "Обратите внимание на <TextBlue capital>HWM</TextBlue> при рассчёте комиссий",
      ManagerInfo: "Аккаунт <TextBlue>{{account}}</TextBlue> - менеджер фонда с долей <TextBlue>{{share}}%</TextBlue>",
    },
    IamManager: "Я торгую сам",
  },
  MyFunds: {
    Total: {
      Title: "Статистика",
      ThisMonth: "в этом месяце",
      Commission: "Комиссия",
      Investors: "Инвесторов",
    },
    Managed: {
      Title: "Мои DeFunds",
      CreateButton: "Создать DeFund",
      Active: "Активные",
      Closed: "Закрытые",
    },
  },
  Member: {
    Button: {
      BackToMarket: "Назад к списку DeFunds",
    },
  },
  Common: {
    Loading: "Загрузка",
    Warning: "Внимание",
    Edit: "Редактировать",
    Max: "MAX",
    Image: "Изображение",
    Processing: "В процессе",
    Error: "Ошибка",
    ConfirmTransactionLabel: "Я согласен с тем, что комиссия за транзакцию будет списана с моего кошелька. Точный размер комиссии будет опредён кошельком.",
    Button: {
      Create: "Создать",
      Cancel: "Отмена",
      Approve: "Одобрить",
      Reset: "Сбросить",
      Apply: "Применить",
      UploadPicture: "Загрузить изображение",
      Save: "Сохранить",
      Close: "Закрыть",
      Open: "Открыть",
      Continue: "Продолжить",
      Update: "Обновить",
    },
    Week: "{{count}} недель",
    Minute_one: "{{count}} минута",
    Minute_few: "{{count}} минуты",
    Minute_many: "{{count}} минут",
    Hour_one: "{{count}} час",
    Hour_few: "{{count}} часа",
    Hour_many: "{{count}} часов",
    Day_one: "{{count}} день",
    Day_few: "{{count}} дня",
    Day_many: "{{count}} дней",
    Months: "{{count}} месяцев",
    Clock: {
      Day: "{{count}}д",
      Hour: "{{count}}ч",
      Minute: "{{count}}м",
    },
    NoData: "Нет данных",
    TimeRange: {
      AllTime: "За всё время",
      ThisMonth: "За этот месяц",
      ThisQuarter: "За этот квартал",
      ThisYear: "За этот год",
    },
    ImagePicker: {
      IncorrectFormat: "Разрешенный формат изображения - JPG или PNG",
      SelectSingleFile: "Пожалуйста выберите один файл",
      FileIsTooBig: "Максимальный размер файла - {{size}} MB",
    },
    Anonymous: "Аноним",
    AuditDisclaimer: "КОНТРАКТ НЕ ПРОХОДИЛ АУДИТ. ИСПОЛЬЗУЙТЕ ЕГО НА СВОЙ СТРАХ И РИСК.",
  },
  Settings: {
    Title: 'Настройки доступа',
    FundOwner: 'Владелец фонда',
    FundManager: 'Менеджер фонда',
    ChangeManager: 'Сменить менеджера',
    CurrentManager: 'Текущий менеджер',
    TradeLock: 'Блокировка торговли',
    LockTrade: 'Заблокировать торговлю',
    LockUntil: 'Длительность блокировки',
    NewManager: 'Новый менеджер',
    NewShare: 'Новая доля',
    CurrentTradelockInfo: 'Менеджер <TextBlue>{{address}}</TextBlue> не может торговать до <TextBlue>{{date}}</TextBlue>',
    BlockCurrentManager: 'Заблокировать торговлю для текущего менеджера <TextBlue>{{address}}</TextBlue>',
    UpdateManagerWarning: 'Изменения менеджера и его доли вступят в силу в момент следующего отчета.',
    TransferOwnership: 'Передать владение',
    TransferOwnershipWarning: 'Вы уверены, что вы хотите передать владение фондом <TextBlue>{{fund}}</TextBlue>? Вы потеряете доступ к управлению фондом; это действие не может быть отменено. Ваши заработанные комиссии с этого фонда будут выведены на ваш адрес вместе с передачей владения.',
    AdminSettings: 'Управление фондом',
  },
};

export default translation;
