import { toast } from 'react-toastify';
import { useEffect } from 'react';

const TOAST_AUTO_CLOSE_TIME = 7000;

export function useToastAutoClose(isCompleted: boolean, toastTimestamp: number): void {
	useEffect(
		function autoClose() {
			let timerId: NodeJS.Timeout;

			if (isCompleted) {
				timerId = setTimeout(() => {
					toast.dismiss(toastTimestamp);
				}, TOAST_AUTO_CLOSE_TIME);
			}

			return () => {
				clearTimeout(timerId);
			};
		},
		[isCompleted, toastTimestamp],
	);
}
