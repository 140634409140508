import { BigNumber } from "ethers";
import { useWeb3React } from '@web3-react/core';
import { useQuery } from '@tanstack/react-query';

export function useGasPrice(chainId: number): BigNumber | undefined {
  const { provider } = useWeb3React();

  const { data: gasPrice } = useQuery<BigNumber | undefined>(
    ["gasPrice", chainId, !!provider],
    () => provider?.getGasPrice()
  );

  return gasPrice;
}
