import { ChainId } from '../../../common/constants';
import { expandDecimals } from './utils';
import { ethers } from 'ethers';

export const USD_DECIMALS = 30;
export const BASIS_POINTS_DIVISOR = 10000;
export const DUST_BNB = "2000000000000000";
export const MARGIN_FEE_BASIS_POINTS = 10;
export const DEFAULT_ACCEPABLE_PRICE_IMPACT_BUFFER = 30; // 0.3%

export const MAX_LEVERAGE = 100 * BASIS_POINTS_DIVISOR;
export const FUNDING_RATE_PRECISION = 1000000;
export const LIQUIDATION_FEE = expandDecimals(5, USD_DECIMALS);
export const DUST_USD = expandDecimals(1, USD_DECIMALS);
export const PRECISION = expandDecimals(1, 30);
export const SUPPORTED_RESOLUTIONS: Record<string, string> = { 1: "1m", 5: "5m", 15: "15m", 60: "1h", 240: "4h", "1D": "1d" };
export const DEFAULT_COLLATERAL_SYMBOL = 'USDC';
export const MAX_ALLOWED_LEVERAGE = 50 * BASIS_POINTS_DIVISOR;
export const USDG_DECIMALS = 18;
export const DEFAULT_SLIPPAGE_AMOUNT = 30;
export const MAX_ALLOWED_SLIPPAGE_SHORT = 10000;

export const INCREASE_ORDER_EXECUTION_GAS_FEE = ethers.utils.parseEther("0.0003");
export const DECREASE_ORDER_EXECUTION_GAS_FEE = ethers.utils.parseEther("0.000300001");
export const UI_FEE_RECEIVER_ACCOUNT = ethers.constants.AddressZero;

export const CHART_PERIODS: Record<string, number> = {
  "1m": 60,
  "5m": 60 * 5,
  "15m": 60 * 15,
  "1h": 60 * 60,
  "4h": 60 * 60 * 4,
  "1d": 60 * 60 * 24,
};

export const leverageMarks = {
  2: "2x",
  5: "5x",
  10: "10x",
  15: "15x",
  20: "20x",
  25: "25x",
  30: "30x",
  35: "35x",
  40: "40x",
  45: "45x",
  50: "50x",
};

export const GMX_BACKEND_URLS: Record<number, string> = {
  [ChainId.Arbitrum]: "https://gmx-server-mainnet.uw.r.appspot.com",
  //[ChainId.Avalanche]: "https://gmx-avax-server.uc.r.appspot.com",
};

export const gmxContractAddresses: Record<number, Record<string, string>> = {
  [ChainId.Arbitrum]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    GlpManager: "0x3963FfC9dff443c2A94f21b129D429891E32ec18",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    GlpRewardRouter: "0xB95DB5B167D75e6d04227CfFFA61069348d271F5",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",

    // Synthetics
    DataStore: "0xFD70de6b91282D8017aA4E741e9Ae325CAb992d8",
    EventEmitter: "0xC8ee91A54287DB53897056e12D9819156D3822Fb",
    ExchangeRouter: "0x7C68C7866A64FA2160F78EEaE12217FFbf871fa8",
    DepositVault: "0xF89e77e8Dc11691C9e8757e84aaFbCD8A67d7A55",
    WithdrawalVault: "0x0628D46b5D145f183AdB6Ef1f2c97eD1C4701C55",
    OrderVault: "0x31eF83a530Fde1B38EE9A18093A333D8Bbbc40D5",
    SyntheticsReader: "0xf60becbba223EEA9495Da3f606753867eC10d139",
    SyntheticsRouter: "0x7452c558d45f8afC8c83dAe62C3f8A5BE19c71f6",
  },
}

export function getGmxContractAddress(chainId: number, name: string): string {
  return gmxContractAddresses[chainId]?.[name];
}

export const USDG_ADDRESS = getGmxContractAddress(ChainId.Arbitrum, 'USDG');
export const HIGH_SPREAD_THRESHOLD = expandDecimals(1, 30).div(100); // 1%