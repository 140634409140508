import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { ChainId } from '../common/constants';
export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: '03c5172236be539c38ce4c0627fca82f', // TODO: move to env
        chains: [ChainId.Arbitrum],
        optionalChains: [1, ChainId.BNBChain],
        showQrModal: true,
      },
    })
)
