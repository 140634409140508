/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { FundFactory, FundFactoryInterface } from "../FundFactory";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "beacon",
        type: "address",
      },
    ],
    name: "BeaconUpgraded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newFees",
        type: "address",
      },
    ],
    name: "FeesChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "manager",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "hwm",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "sf",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "pf",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "mf",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "period",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "whitelistMask",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "serviceMask",
        type: "uint256",
      },
    ],
    name: "FundCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newTrigger",
        type: "address",
      },
    ],
    name: "TriggerChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "Upgraded",
    type: "event",
  },
  {
    inputs: [],
    name: "createFundFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "manager",
            type: "address",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "subscriptionFee",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "managementFee",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "performanceFee",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "investPeriod",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "indent",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "serviceMask",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "managerShare",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "managerStopValue",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "globalStopValue",
                type: "uint256",
              },
            ],
            internalType: "struct IFundFactory.Numbers",
            name: "numbers",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "bool",
                name: "hwm",
                type: "bool",
              },
              {
                internalType: "bool",
                name: "isPrivate",
                type: "bool",
              },
              {
                internalType: "bool",
                name: "managerStopEnabled",
                type: "bool",
              },
              {
                internalType: "bool",
                name: "globalStopEnabled",
                type: "bool",
              },
            ],
            internalType: "struct IFundFactory.Flags",
            name: "flags",
            type: "tuple",
          },
          {
            internalType: "bytes",
            name: "whitelistMask",
            type: "bytes",
          },
        ],
        internalType: "struct IFundFactory.FundInfo",
        name: "fundInfo",
        type: "tuple",
      },
    ],
    name: "newFund",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "proxiableUUID",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "createFundFee_",
        type: "uint256",
      },
    ],
    name: "setCreateFundFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
    ],
    name: "upgradeTo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "upgradeToAndCall",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
] as const;

export class FundFactory__factory {
  static readonly abi = _abi;
  static createInterface(): FundFactoryInterface {
    return new utils.Interface(_abi) as FundFactoryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): FundFactory {
    return new Contract(address, _abi, signerOrProvider) as FundFactory;
  }
}
