import styled, { css } from 'styled-components';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from './UI/loading';
import { MiniButtonType, MiniButton } from './UI/mini-button';
import { Tooltip, TooltipHandle, TooltipIconState } from './UI/tooltip';

export interface InfoLineTooltipInfo {
	content: ReactNode;
	state?: TooltipIconState;
	direction?: string[];
	maxWidth?: number;
}

export interface InfoLineProps {
	title: ReactNode;
	value?: ReactNode;
	valueTooltip?: InfoLineTooltipInfo;
	labelTooltip?: InfoLineTooltipInfo;
	currency?: string;
	onButtonClick?: () => void;
	className?: string;
	buttonText?: string;
	buttonType?: MiniButtonType;
	buttonAfter?: boolean;
	positive?: boolean;
	loading?: boolean;
	highlightCurrency?: boolean;
	onClick?: () => void;
}

export function InfoLine({
	title,
	onButtonClick,
	onClick,
	currency = 'USDT',
	value,
	className,
	buttonAfter,
	buttonText,
	labelTooltip,
	valueTooltip,
	highlightCurrency = true,
	buttonType = 'cancel',
	positive = false,
	loading = false,
}: InfoLineProps): JSX.Element {
	const { t } = useTranslation();
	const button = <MiniButton
		buttonType={buttonType}
		buttonAfter={buttonAfter}
		onClick={onButtonClick}
	>{buttonText || t('Common.Button.Cancel')}{loading && <StyledLoading variant="primary" size={15} />}</MiniButton>;

	const valueElement = useMemo(() => {
		if (valueTooltip) {
			return <StyledTooltip
				content={valueTooltip.content}
				handle={<TooltipHandle>{value}</TooltipHandle>}
				direction={valueTooltip.direction}
				maxWidth={valueTooltip.maxWidth}
				iconState={valueTooltip.state}
			/>;
		}
		return value;
	}, [value, valueTooltip]);

	return (
		<Container className={className} onClick={onClick}>
			<Title>
				{title}
				{labelTooltip && <StyledTooltip
					content={labelTooltip.content}
					direction={labelTooltip.direction}
					maxWidth={labelTooltip.maxWidth}
					iconState={labelTooltip.state}
				/>}
			</Title>
			<FigureContainer positive={positive}>
				{onButtonClick && !buttonAfter && button}
				<Value>{valueElement}</Value>{currency && <>&nbsp; <Text positive={positive} highlight={highlightCurrency}>{currency}</Text></>}
				{onButtonClick && buttonAfter && button}
			</FigureContainer>
		</Container>
	);
}

const Title = styled.span`
	display: flex;
	align-items: center;
	margin-right: 32px;
`;

const Value = styled.span`
	display: flex;
	align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
	margin-left: 4px;
`;

export const FigureContainer = styled.div<{ positive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.positive && css`
    color: #68cb84;
  `};
`;

export const INFO_LINE_LINE_HEIGHT = 24
export const INFO_LINE_FONT_SIZE = 14

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: ${INFO_LINE_FONT_SIZE}px;
  line-height: ${INFO_LINE_LINE_HEIGHT}px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
`;

export const Text = styled.span<{ positive?: boolean; highlight?: boolean }>`
  color: ${props => props.positive ? '#68cb84' : (props.highlight ? '#6f767e' : '#1a1d1f')};
`;

const StyledLoading = styled(Loading)`
	margin: 0 0 0 4px;
`;
