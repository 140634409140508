import React, { Suspense, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useWeb3React } from '@web3-react/core';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

import {
	Content,
	ContentContainer,
	Layout,
	NavbarContainer,
	SidebarContainer,
	Sticky,
	Wrap,
} from './app.styled';
import { selectTheme } from './store/slices/app';
import { dark, light } from './common/theme';
import { Sidebar } from './components/layout/sidebar';
import { Navbar } from './components/layout/navbar';
import { CreateModal } from './features/fund/create-form/create-modal';
import { WalletModal } from './features/wallet/wallet-modal';
import { useRestoreWallet } from './features/wallet/use-restore-wallet';
import { useLoadWhiteLists } from './features/whitelists/use-load-whitelists';
import { OptionsContext } from './contexts/options';
import { ONE_DAY, ONE_MONTH, ONE_QUARTER, ONE_WEEK } from './common/constants';
import { useLoadProfile } from './features/profile/use-load-profile';
import { Modal } from './components/modal';
import { PrivateRoute } from './components/private-route';
import { TimePeriod } from './interfaces/ui';
import { useLoadBaseTokenDecimals } from './hooks/use-load-base-token-decimals';
import { useScreenDimensions } from './hooks/use-screen-dimensions';
import { breakpoints } from './components/UI/breakpoints';
import { usePreventNumberInputScroll } from './hooks/use-prevent-number-input-scroll';
import { usePageViewEvent } from './hooks/use-page-view-event';
import { TokenListProvider } from './contexts/token-list-provider';
import { ModalProvider } from './components/modal/modal-provider';
import { useRestoreCanvases } from './hooks/use-restore-canvas';


const AdminPage = React.lazy(() => import('./features/admin/admin'));
const Market = React.lazy(() => import('./features/market/market'));
const MarketView = React.lazy(() => import('./features/market/market-view'));
const ProfileView = React.lazy(() => import('./features/profile/profile-view'));
const MyFunds = React.lazy(() => import('./features/fund/my-funds'));
const FundCreate = React.lazy(() => import('./features/fund/create-form/create'));
const EditFundContainer = React.lazy(() => import('./features/fund/edit-fund/edit-fund'));
const Investment = React.lazy(() => import('./features/investment/investment'));
const ProfilePage = React.lazy(() => import('./features/profile/page'));
const TradePage = React.lazy(() => import('./features/trade/trade'));


export function App(): JSX.Element {
	const location = useLocation();
	const { t } = useTranslation();
	useRestoreWallet();
	useLoadProfile();
	useLoadWhiteLists();
	useLoadBaseTokenDecimals();
	usePreventNumberInputScroll();
	usePageViewEvent();
	const OSComponentRef = useRef<OverlayScrollbarsComponent | null>(null);
	const [width] = useScreenDimensions();
	useRestoreCanvases();
	const { isActive } = useWeb3React();

	const theme = useSelector(selectTheme);

	useLayoutEffect(() => {
		OSComponentRef.current?.osInstance().scroll({ top: 0 });
	}, [location.pathname]);

	const options = useMemo(() => ({
		fundPeriod: [
			{ id: ONE_WEEK, label: t('Fund.Period.Weekly') },
			{ id: ONE_MONTH, label: t('Fund.Period.Monthly') },
			{ id: ONE_QUARTER, label: t('Fund.Period.Quarterly') },
		],
		fundIndent: new Array(7).fill(null).map((_, index) => ({
			id: (index + 1) * ONE_DAY,
			label: t('Common.Day', { count: index + 1 }),
		})),
		timeRange: [
			{ id: TimePeriod.AllTime, label: t('Common.TimeRange.AllTime') },
			/*{ id: TimePeriod.Month, label: t('Common.TimeRange.ThisMonth') },
			{ id: TimePeriod.Quarter, label: t('Common.TimeRange.ThisQuarter') },
			{ id: TimePeriod.Year, label: t('Common.TimeRange.ThisYear') },*/
		],
		sortFundsBy: [
			{ id: 'tvl', label: 'AUM' },
			{ id: 'investors', label: t('Fund.Investors') },
		],
	}), [t]);

	const page = (Component: (...props: unknown[]) => JSX.Element | null): JSX.Element => <>
		<Modal />
		<Component />
	</>;

	return (
		<ModalProvider>
			<TokenListProvider>
				<OptionsContext.Provider value={options}>
					<ThemeProvider theme={theme === 'light' ? light : dark}>
						<Layout>
							<SidebarContainer>
								<Sidebar />
							</SidebarContainer>
							<Wrap>
								<Sticky>
									<NavbarContainer>
										<Navbar />
									</NavbarContainer>
								</Sticky>
								<ContentContainer>
									<OverlayScrollbarsComponent
										options={{ scrollbars: { autoHide: 'never' } }}
										style={{ maxHeight: `calc(100vh - ${width > breakpoints.tablet ? '90px' : '150px'})` }}
										className="os-theme-thin-dark"
										ref={OSComponentRef}
									>
										<Content>
											<Suspense fallback={<></>}>
                    <Routes>
                      <Route path="/" element={<Navigate to="/market" replace />} />
                      <Route path="/admin" element={page(AdminPage)} />
                      <Route path="/market" element={page(Market)} />
                      <Route path="/market/:id" element={page(MarketView)} />
                      <Route path="/member/:address" element={page(ProfileView)} />
                      <Route element={<PrivateRoute />}>
                        <Route path="/fund" element={page(MyFunds)} />
                        <Route path="/fund/create" element={page(FundCreate)} />
                        <Route path="/fund/edit/:id" element={page(EditFundContainer)} />
                        <Route path="/investment" element={page(Investment)} />
                        <Route path="/profile" element={page(ProfilePage)} />
                        <Route path="/trade/:id/*" element={<TradePage />} />
                      </Route>
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </Suspense>
											{isActive && (
												<>
													<CreateModal />
												</>
											)}
											<WalletModal />
										</Content>
									</OverlayScrollbarsComponent>
								</ContentContainer>
							</Wrap>
						</Layout>
					</ThemeProvider>
				</OptionsContext.Provider>
			</TokenListProvider>
		</ModalProvider>
	);
}
