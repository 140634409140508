import { Fragment, ReactNode, useMemo } from 'react';
import { ExternalLink } from '../../../components/UI/external-link';
import Loading from '../../../components/UI/loading';
import { getExplorerUrl } from '../../../common/constants';
import { SubaccountNotificationState } from './subaccount-provider';
import { usePrevious } from '../../../hooks/use-previous';
import { mustNeverExist } from '../../../common/assertions';
import styled from 'styled-components';
import { useToastAutoClose } from '../../../hooks/use-toast-auto-close';
import { TextS, TextSMBold } from '../../../components/UI/typography';
import { Row } from '../../../components/UI/row';

// TODO: i18n
const Trans = Fragment;

export function SubaccountNotification({
  toastId,
	chainId,
  subaccountWasAlreadyGenerated,
  subaccountWasAlreadyActivated,
	activeTx,
	notificationState,
}: {
	toastId: number;
	chainId: number;
	subaccountWasAlreadyGenerated: boolean;
	subaccountWasAlreadyActivated: boolean;
	activeTx: string | null;
	notificationState: SubaccountNotificationState;
}): JSX.Element | null {
	const hasError = false;
	const isCompleted = useMemo(() => {
		const completedStates: SubaccountNotificationState[] = [
			'activated',
			'deactivated',
			'deactivationFailed',
			'activationFailed',
			'generationFailed',
		];
		return completedStates.includes(notificationState);
	}, [notificationState]);
	useToastAutoClose(isCompleted, toastId);

	let content: ReactNode = null;
	let title: ReactNode = null;
	const latestTransactionHash = usePrevious(activeTx);
	const tx = isCompleted ? latestTransactionHash : activeTx;

	const link = useMemo(() => {
		if (!tx) return null;

		const txUrl = getExplorerUrl(chainId) + 'tx/' + tx;
		return (
			<StyledLink href={txUrl}>
				<Trans>View status</Trans>
			</StyledLink>
		);
	}, [chainId, tx]);

	const isUpdate = subaccountWasAlreadyGenerated && subaccountWasAlreadyActivated;
	const renderActivationTitle = (): string => {
		if (isUpdate) return `Updating Subaccount`;
		return subaccountWasAlreadyGenerated ? `Activating Subaccount` : `Generating and activating Subaccount`;
	};
	const renderActivationContent = (step: 'generating' | 'activating' | 'activated'): JSX.Element => {
		if (isUpdate) {
			if (step === 'activating')
				return (
					<Row>
						<Trans>Pending Wallet transaction sign</Trans>
						<StyledLoading size={20} variant="primary" />
					</Row>
				);
			return <Trans>Subaccount is updated</Trans>;
		}

		return (
			<div>
				<Row>
					{step === 'generating' ? <Trans>Pending Wallet message sign</Trans> : <Trans>Subaccount created</Trans>}{' '}
					{step === 'generating' ? <StyledLoading size={20} variant="primary" /> : null}
				</Row>
				<Row>
					{step === 'activated' ? <Trans>Subaccount activated</Trans> :
						<Trans>Pending Wallet transaction sign</Trans>}{' '}
					{step === 'activating' ? <StyledLoading size={20} variant="primary" /> : null}
				</Row>
			</div>
		);
	};

	switch (notificationState) {
		case 'generating':
			title = renderActivationTitle();
			content = renderActivationContent('generating');
			break;

		case 'generationFailed':
			title = renderActivationTitle();
			content = `Subaccount generation failed`;
			break;

		case 'activating':
			title = renderActivationTitle();
			content = renderActivationContent('activating');
			break;

		case 'activated':
			title = renderActivationTitle();
			content = renderActivationContent('activated');
			break;

		case 'activationFailed':
			title = renderActivationTitle();
			content = `Subaccount activation failed`;
			break;

		case 'deactivating':
			title = `Deactivation`;
			content = (
				<Row>
					<Trans>Deactivating subaccount</Trans>
					<StyledLoading variant="primary" size={20} />
				</Row>
			);
			break;

		case 'deactivated':
			title = `Deactivation`;
			content = `Subaccount deactivated`;
			break;

		case 'deactivationFailed':
			title = `Deactivation`;
			content = `Subaccount deactivation failed`;
			break;

		case 'none':
			return null;

		default:
			mustNeverExist(notificationState);
	}

	return (
		<StatusNotification hasError={hasError}>
			<TextSMBold>{title}</TextSMBold>
			<Content>{content}</Content>
			{link}
		</StatusNotification>
	);
}

const Content = styled(TextS)`
	margin: 6px 0;
`;

const StatusNotification = styled.div<{ hasError: boolean }>`

`;

const StyledLoading = styled(Loading)`
	margin-left: 4px;
`;

const StyledLink = styled(ExternalLink)`
	display: flex;
	margin: 6px 0;
`;
