import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const REACT_APP_MIXPANEL_KEY = '9a5635c3ef53f1e2bb211b4b84beeb87';

export function usePageViewEvent(): void {
	const location = useLocation();

	useEffect(() => {
		mixpanel.init(REACT_APP_MIXPANEL_KEY, { debug: true });
	}, []);

	useEffect(() => {
		mixpanel.track('Page view', { url: window.location.href });
	}, [location]);
}
