export const getIsInjected = (): boolean => Boolean(window.ethereum)

const InjectedWalletTable: Record<string, InjectedWalletInfo> = {
	//isBraveWallet: { name: 'Brave', icon: BRAVE_ICON },
	//isRabby: { name: 'Rabby', icon: RABBY_ICON },
	isTrust: { key: 'trust', name: 'Trust Wallet' },
	isMetaMask: { key: 'metamask', name: 'MetaMask' },
	//isLedgerConnect: { name: 'Ledger', icon: LEDGER_ICON },
}

export type InjectedWalletType = 'trust' | 'metamask' | 'browser';

export interface InjectedWalletInfo {
	key: InjectedWalletType;
	name: string;
}

export function getInjection(): InjectedWalletInfo {
	for (const [key, wallet] of Object.entries(InjectedWalletTable)) {
		if (window.ethereum?.[key as keyof Window['ethereum']]) return wallet;
	}
	return { key: 'browser', name: 'Browser Wallet' };
}
