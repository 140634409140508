import { DefundsEthClient } from '../contracts/defunds-eth-client';
import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ChainId, contractAddresses } from '../common/constants';

export function useDefundsEthClient(): DefundsEthClient | null {
	const { chainId, account, provider } = useWeb3React();
	return useMemo(
		() => (provider && chainId && account && contractAddresses[chainId as ChainId]) ? new DefundsEthClient(provider, chainId, account) : null,
		[chainId, provider, account]
	);
}