import { Token, WhiteList } from '../../interfaces/whitelists';
import { ChainId, REACT_APP_API_URL } from '../../common/constants';

export function getTokens(chainId: number): Promise<Token[]> {
	return fetch(`${REACT_APP_API_URL}token?chainId=${chainId}`)
		.then(r => r.json()).then(tokens => tokens.filter((token: Token) => !token.isDeleted));
}

export function removeToken(tokenId: number, chainId: number, password: string): Promise<void> {
	return fetch(`${REACT_APP_API_URL}admin/token/${tokenId}?chainId=${chainId}`, {
		method: 'DELETE',
		headers: { 'X-ADMIN-PASS': password },
	}).then(r => r.json());
}

export function getWhiteLists(chainId: ChainId): Promise<WhiteList[]> {
	return fetch(`${REACT_APP_API_URL}whitelist?chainId=${chainId}`).then(r => r.json()).then(r => r.map((list: WhiteList) => ({
		...list,
		chainId,
	})));
}

export function createWhitelist(chainId: number, name: string, password: string): Promise<WhiteList> {
	return fetch(`${REACT_APP_API_URL}admin/whitelist`, {
		method: 'POST',
		headers: {
			'X-ADMIN-PASS': password,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ name, tokens: [], chainId }),
	}).then(r => r.json());
}

export function addToken(tokenData: TokenData, password: string): Promise<Token> {
	return fetch(`${REACT_APP_API_URL}admin/token`, {
		method: 'POST',
		headers: {
			'X-ADMIN-PASS': password,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(tokenData),
	}).then(r => r.json());
}

export interface TokenData {
	address: string;
	name: string;
	chainId: number;
	decimals: number;
	index: number;
	isSynthetic: boolean;
	cmcId?: number;
}

export async function updateWhitelist(whiteListId: number, name: string, tokenIds: number[], password: string): Promise<WhiteList> {
	return fetch(`${REACT_APP_API_URL}admin/whitelist/${whiteListId}`, {
		headers: {
			'X-ADMIN-PASS': password,
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify({ name: name, tokens: tokenIds }),
	}).then(r => r.json());
}

export function updateToken(
	chainId: number,
	address: string,
	password: string,
	decimals: number,
): Promise<unknown> {
	return fetch(`${REACT_APP_API_URL}admin/token`, {
		headers: {
			'X-ADMIN-PASS': password,
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify({ tokenAddress: address, chainId, decimals }),
	}).then(r => r.json());
}
