import { Commissions } from '../interfaces/fund';
import { useQuery } from '@tanstack/react-query';
import { useWeb3Providers } from './use-web3-providers';
import { useEffect, useMemo } from 'react';
import { Fees__factory } from '../contracts/types';
import { ChainId, contractAddresses } from '../common/constants';
import { utils } from 'ethers';

export function useServiceFees(chainId: ChainId): Commissions | null {
	const providers = useWeb3Providers();
	const contract = useMemo(() => {
		return Fees__factory.connect(contractAddresses[chainId].fees, providers[chainId])
	}, [chainId, providers])
	const { data: serviceFees } = useQuery(
		["serviceFees"],
		() => contract.serviceFees().then(([sf, pf, mf]) => ({
			sf: Number(utils.formatEther(sf)) * 100,
			pf: Number(utils.formatEther(pf)) * 100,
			mf: Number(utils.formatEther(mf)) * 100,
		}))
	);
	useEffect(() => () => {
		contract?.removeAllListeners();
	}, [contract]);
	return serviceFees || null;
}
