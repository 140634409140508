import { useEffect } from 'react';

export function useRestoreCanvases(): void {
	useEffect(() => {
		const canvasBitmaps: Map<HTMLCanvasElement, ImageBitmap | null> = new Map();

		const onVisibilityChange = async (): Promise<void> => {
			const canvases = document.querySelectorAll<HTMLCanvasElement>('canvas');
			for (const canvas of Array.from(canvases)) {
				const ctx = canvas.getContext('2d');
				if (!ctx) continue;

				if (document.visibilityState === 'hidden') {
					const bmp = await createImageBitmap(canvas);
					canvasBitmaps.set(canvas, bmp);
				} else {
					const bmp = canvasBitmaps.get(canvas);
					if (!bmp) continue;

					ctx.globalCompositeOperation = 'copy';
					ctx.drawImage(bmp, 0, 0);
					ctx.globalCompositeOperation = 'source-over';
					canvasBitmaps.set(canvas, null);
				}
			}
		};

		document.addEventListener('visibilitychange', onVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, []);
}
