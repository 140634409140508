import { BigNumber } from 'ethers';
import { SubaccountNotificationState } from './subaccount-provider';
import { FormState } from './subaccount-modal';

const ZERO = BigNumber.from(0);

export function getButtonState({
  topUp,
  subaccountAddress,
  isTxPending,
  notificationState,
  formState,
  withdrawalLoading,
  mainAccEthBalance,
  isSubaccountActive,
  accountUpdateLoading,
  nativeTokenSymbol,
}: {
	topUp: BigNumber | null;
	subaccountAddress: string | null;
	isTxPending: boolean;
	notificationState: SubaccountNotificationState;
	formState: FormState;
	withdrawalLoading: boolean;
	mainAccEthBalance: BigNumber | undefined;
	isSubaccountActive: boolean;
	accountUpdateLoading: boolean;
	nativeTokenSymbol: string;
}): { text: string; disabled?: true } {
	if (!mainAccEthBalance) {
		return { disabled: true, text: `${nativeTokenSymbol} is not available` };
	}

	if (mainAccEthBalance.lt(topUp ?? ZERO)) {
		return { disabled: true, text: `Insufficient ${nativeTokenSymbol} balance` };
	}

	if (isTxPending) {
		if (notificationState === 'activating' && formState === 'inactive') {
			return { disabled: true, text: 'Activating Subaccount...' };
		} else if (notificationState === 'deactivating') {
			return { disabled: true, text: !topUp ? 'Enter Amount' : 'Top Up' };
		} else if (notificationState === 'generating' && formState === 'inactive') {
			return { disabled: true, text: 'Generating Subaccount...' };
		}
		return { disabled: true, text: 'Waiting for transaction...' };
	}

	if (withdrawalLoading) {
		return { disabled: true, text: 'Withdrawing...' };
	}

	if (accountUpdateLoading) {
		if (notificationState === 'activating' && formState === 'inactive') {
			return { disabled: true, text: 'Activating Subaccount...' };
		} else if (notificationState === 'deactivating' && formState === 'activated') {
			return { disabled: true, text: !topUp ? 'Enter Amount' : 'Top Up' };
		} else if (notificationState === 'generating' && formState === 'inactive') {
			return { disabled: true, text: 'Generating Subaccount...' };
		}

		return { disabled: true, text: 'Updating...' };
	} else if (isSubaccountActive) {
		if (!topUp) return { disabled: true, text: `Enter Amount` };
		return { text: `Top Up` };
	} else if (!isSubaccountActive && notificationState !== 'activated') {
		if (subaccountAddress) {
			return { text: `Activate Subaccount` };
		} else {
			return { text: `Generate & Activate Subaccount` };
		}
	}

	return { text: `Update`, disabled: true };
}

export function getApproxSubaccountActionsCount(subAccNativeTokenBalance: BigNumber, executionFee: BigNumber): BigNumber | null {
	if (executionFee.gt(subAccNativeTokenBalance)) {
		return BigNumber.from(0);
	}

	if (!executionFee || executionFee.lte(0)) {
		return null;
	}

	return subAccNativeTokenBalance.div(executionFee);
}
