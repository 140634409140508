import useSWR, { SWRConfiguration } from 'swr';
import { CacheKey, MulticallRequestConfig, MulticallResult, SkipKey } from './types';
import { executeMulticall } from './utils';
import { useCallback } from 'react';
import { isSupportedNetwork } from '../../../utils';

export function useMulticall<TConfig extends MulticallRequestConfig<any>, TResult = MulticallResult<TConfig>>(
	chainId: number,
	name: string,
	params: {
		key: CacheKey | SkipKey;
		refreshInterval?: number | null;
		clearUnusedKeys?: boolean;
		keepPreviousData?: boolean;
		request: TConfig | ((chainId: number, key: CacheKey) => TConfig);
		parseResponse?: (result: MulticallResult<TConfig>, chainId: number, key: CacheKey) => TResult;
	},
): { data: TResult | undefined; isLoading: boolean; refetch: () => void; } {
	let swrFullKey = Array.isArray(params.key) && chainId && isSupportedNetwork(chainId) && name
		? [chainId, name, ...params.key]
		: null;

	const swrOpts: SWRConfiguration = {
		keepPreviousData: params.keepPreviousData,
	};

	// SWR resets global options if pass undefined explicitly
	if (params.refreshInterval !== undefined) {
		swrOpts.refreshInterval = params.refreshInterval || undefined;
	}

	const { data, mutate } = useSWR<TResult | undefined>(swrFullKey, {
		...swrOpts,
		fetcher: async () => {
			try {
				// prettier-ignore
				const request = typeof params.request === 'function'
					? params.request(chainId, params.key as CacheKey)
					: params.request;

				if (Object.keys(request).length === 0) {
					throw new Error(`Multicall request is empty`);
				}

				const response = await executeMulticall(chainId, request);

				if (!response) {
					throw new Error(`Multicall response is empty`);
				}

				// prettier-ignore
				const result = typeof params.parseResponse === 'function'
					? params.parseResponse(response, chainId, params.key as CacheKey)
					: response;

				return result as TResult;
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(`Multicall request failed: ${name}`, e);

				throw e;
			}
		},
	});

	const refetch = useCallback(() => mutate(), [mutate]);

	return {
		data,
		isLoading: Boolean(swrFullKey) && !data,
		refetch,
	};
}
