import { NetworkParamsMap, Wallet } from '../interfaces/web3';
import { ContractAddresses } from '../interfaces/contracts';
import { ExternalMarket, Token } from '../interfaces/whitelists';

export const CONFIRMATION_COUNT = 3;

export const ONE_MINUTE = 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const ONE_MONTH = ONE_DAY * 30;
export const ONE_QUARTER = ONE_MONTH * 3;
export const ONE_YEAR = ONE_QUARTER * 4;
export const DRIP_GAS_USAGE = 1530000;

export const DEFAULT_CURRENCY = 'USDT';

export const USDT_PRECISION = 2;
export const TOKEN_PRECISION = 6;
export const BASE_DECIMALS = 18;
export const REPORT_THRESHOLD = 50;

export const DEFAULT_SLIPPAGE = 0.001;

export const WALLET_LOCALSTORAGE_KEY = 'connectedWalletProvider';

export enum ChainId {
	Optimism = 10,
	BNBChain = 56,
	Arbitrum = 42161,
	//Avalanche = 43114,
}

// Chainlink feeds in arbitrum
export const BASE_CURRENCY_FEED_ADDRESSES: Record<ChainId, string> = {
	[ChainId.BNBChain]: '0x6970460aabF80C5BE983C6b74e5D06dEDCA95D4A', // BNB
	[ChainId.Arbitrum]: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612', // ETH
	[ChainId.Optimism]: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612', // ETH
}

export const mapChainIdToDecimals: Record<ChainId, number> = {
	[ChainId.BNBChain]: 18,
	[ChainId.Optimism]: 6,
	[ChainId.Arbitrum]: 6,
};

export const DEFAULT_ENABLED_SERVICES = {
	'0x': true,
	aave: false,
	gmx: false,
};

export const SUPPORTED_EXTERNAL_MARKETS: Record<ChainId, ExternalMarket[]> = {
	[ChainId.BNBChain]: ['0x'],
	[ChainId.Optimism]: ['0x', 'aave'],
	[ChainId.Arbitrum]: ['0x', 'aave', 'gmx'],
};

export function isSupportedMarket(market: ExternalMarket, chainId: ChainId | number): boolean {
	return Boolean(SUPPORTED_EXTERNAL_MARKETS[chainId as ChainId]?.includes(market));
}

export const networks = [
	{ id: ChainId.Optimism, label: 'Optimism' },
	{ id: ChainId.Arbitrum, label: 'Arbitrum' },
	{ id: ChainId.BNBChain, label: 'BNB Chain' },
];

export const REACT_APP_API_URL = process.env.NODE_ENV === 'production' ? '/api/' : process.env.REACT_APP_API_URL!;

export const REACT_APP_TURNSTILE_SITE_KEY = '0x4AAAAAAAcfU8E1woHaLtoj'

export const networkParams: NetworkParamsMap = {
	[ChainId.Optimism]: {
		chainId: '0xa', // 10
		rpcUrls: ['https://optimism-mainnet.public.blastapi.io', 'https://optimism.meowrpc.com', 'https://optimism.llamarpc.com'],
		chainName: 'Optimism',
		nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH', wrappedSymbol: 'WETH' },
		blockExplorerUrls: ['https://optimistic.etherscan.io'],
	},
	[ChainId.Arbitrum]: {
		chainId: '0xa4b1', // 42161
		rpcUrls: ['https://arbitrum.meowrpc.com', 'https://rpc.ankr.com/arbitrum', 'https://arbitrum.llamarpc.com', 'https://endpoints.omniatech.io/v1/arbitrum/one/public'],
		networkProviderUrl: `${REACT_APP_API_URL}rpc/42161`,
		chainName: 'Arbitrum',
		nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH', wrappedSymbol: 'WETH' },
		blockExplorerUrls: ['https://arbiscan.io/'],
		wsUrl: 'wss://arb-mainnet.g.alchemy.com/v2/eCZaPwUOE8HogMraIt_HZ7xa0HQOSm_j',
	},
	[ChainId.BNBChain]: {
		rpcUrls: [
			'https://bsc-dataseed.binance.org',
			'https://bsc-dataseed1.defibit.io',
			'https://bsc-dataseed1.ninicoin.io',
			'https://bsc-dataseed2.defibit.io',
			'https://bsc-dataseed3.defibit.io',
			'https://bsc-dataseed4.defibit.io',
			'https://bsc-dataseed2.ninicoin.io',
			'https://bsc-dataseed3.ninicoin.io',
			'https://bsc-dataseed4.ninicoin.io',
			'https://bsc-dataseed1.binance.org',
			'https://bsc-dataseed2.binance.org',
			'https://bsc-dataseed3.binance.org',
			'https://bsc-dataseed4.binance.org',
		],
		chainId: '0x38',
		chainName: 'BSC',
		nativeCurrency: {
			name: 'BNB',
			wrappedSymbol: 'WBNB',
			symbol: 'BNB',
			decimals: 18,
		},
		blockExplorerUrls: ['https://bscscan.com'],
	},
};

export function getChainName(chainId: ChainId): string {
	return networkParams[chainId].chainName;
}

export function getExplorerUrl(chainId: ChainId): string {
	const network = networkParams[chainId]
	if (!network || network.blockExplorerUrls.length === 0) {
		return "https://etherscan.io/"
	}
	return network.blockExplorerUrls[0];
}

export function getAccountUrl(chainId: ChainId, account: string): string {
	if (!account) {
		return getExplorerUrl(chainId);
	}
	return getExplorerUrl(chainId) + 'address/' + account;
}

export const SUPPORTED_CHAINS = Object.keys(networkParams);
export const SUPPORTED_CHAIN_NAMES = SUPPORTED_CHAINS.map(chainId => networkParams[chainId].chainName).join(', ');

export function isMobileWallet(t: Wallet): boolean {
	return t.key === 'walletConnect';
}

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';
export const contractAddresses: Record<ChainId, ContractAddresses> = {
	[ChainId.Optimism]: {
		fundFactory: '0x28502A64F6a9998168832ad7096fBDd8fDa9DfB1',
		interaction: '0x7B19ACC1fa94373b12ad23A0D4d07Fc38F41694F',
		fees: '0xF47d46e80806bee47cF050c3539952E3d1A35ccA',
		token: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
		tradeParamsUpgrader: '0x862a1d7875a7A3E97003aa165963429EFe1a8D26',
		feeder: '0xbd9B1F47F71230c86ba14db705F885b908BD93Af',
		whitelist: '0x9F4b2413De98942DBCA005217676ad57a72825D2',
		subaccountRegistry: '0xbf4ce4153c3CEbEBb4102D2bde7C0274364F091b',
		dripOperator: '0x7A529bc10631128F69BF29f8b761dCb00895a378',
	},
	[ChainId.Arbitrum]: process.env.REACT_APP_ENV === 'development' ? {
		fundFactory: '0xa8c4a785Ce7B2eE85a939ff65Ebe0Cb2a74482CD',
		token: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
		fees: '0x18b6613a2b61ca4adD5041cD673E00B4BE322026',
		interaction: '0x1AA9809d6B45897C52f4aD82E8F00c64ED9c6211',
		tradeParamsUpgrader: '0x8F9Fe143704a3A18dBA570cEb14A435aEe8e595d',
		feeder: '0x5480618e8a8042854f6cD322Cd9d8a9a848D2360',
		whitelist: '0x2872265760368BD508a3aED70d1F32fac487B78a',
		subaccountRegistry: '0x714A450f56CeeF255c3909e73c32B56c19239b2d',
		dripOperator: '0xC40110E5d03b7e512b8C3Ebeb74e532b5E1D22eC',
	} : {
		fundFactory: '0x734e415cD8a08E442404D8A3E36597C1be4D9e69',
		token: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
		fees: '0xBed75bEE9FfA4Ec80ef33b4A0beb258da3c80269',
		interaction: '0x8A1D20808dD657864840F7e089f3FCC0230a4550',
		tradeParamsUpgrader: '0x1B252B8Ec9aA8d4DeFeaa5E2d0e86C0D53aD545A',
		feeder: '0xF989582F22592df2DEFAf9D38522Ef25C29e6A8e',
		whitelist: '0xC6c7ED9E47e30579A801d25cC53c99cE3E94d53d',
		subaccountRegistry: '0xf40A0438d10b9Ee20eB2D1513bA3097A4E77272A',
		dripOperator: '0xD1E0F43DF50aB236991591f3257b723974bd7960',
	},
	[ChainId.BNBChain]: {
		fundFactory: '0x1C7add989c8f8289f8C721FD812b6673F1823eFD',
		token: '0x55d398326f99059fF775485246999027B3197955',
		interaction: '0x9062d558C3316eAFa6dFB91dDA7697a4Da3f625C',
		fees: '0xbeBcEAc58E639ba256a84cfffA67bA299b12b5ed',
		tradeParamsUpgrader: '0x922Ee7e066fa167522d3BA8c9119EF261c89B59e',
		feeder: '0x388A0E630E5162A8a9286a165a5F805F286C8D4F',
		whitelist: '0xDF61274A5b873597B66ef3877930442E082C965b',
		subaccountRegistry: '0xd432AC1a06777B26B4c556f30D548B9097950439',
		dripOperator: '0x75D99f251A197DefF301B30051d458F968F14dc6',
	},
};

export function getUSDTToken(chainId: ChainId): Token {
	return {
		id: -1,
		name: 'USDT',
		address: contractAddresses[chainId].token,
		isSynthetic: false,
		index: -1,
		decimals: mapChainIdToDecimals[chainId],
		isDeleted: false,
	};
}

export const GAS_PRICE_MULTIPLIER: Record<ChainId, number> = {
	[ChainId.Arbitrum]: 2.1,
	[ChainId.Optimism]: 2,
	[ChainId.BNBChain]: 2.1,
}
