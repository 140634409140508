import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export function Toast(): JSX.Element {
	return <StyledToastContainer
		position="bottom-right"
		autoClose={3000}
		hideProgressBar
		newestOnTop={false}
		closeOnClick={false}
		rtl={false}
		pauseOnFocusLoss
		draggable={false}
		pauseOnHover
		closeButton={false}
		theme="colored"
	/>
}

const StyledToastContainer = styled(ToastContainer)`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
`;
