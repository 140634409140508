import styled from "styled-components";
import { flexGap } from "../../common/flex-gap";
import { TextSMBold } from '../UI/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  padding: 24px;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 16px;
  width: 100%;
  max-width: 800px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #efefef;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const CheckContainer = styled.div`
  margin-top: 44px;
  margin-bottom: 20px;
`;
export const CheckItem = styled(TextSMBold)`
  display: flex;
  align-items: center;
  color: #1a1d1f;
  margin-bottom: 24px;
`;
export const CheckImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;
export const TextGray = styled.span`
  color: #6f767e;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  ${flexGap({ row: "16px", column: "16px" })}
`;

export const UnlockImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  cursor: pointer;
  user-select: none;
`;

export const InputLabelContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #6f767e;
  margin-bottom: 12px;
`;
export const CommissionInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  ${flexGap({ row: "32px", column: "8px" })};
`;
export const CommissionInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;
export const AvailableContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
`;
export const AvailableLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-right: 10px;
`;
export const InputCommissionContainer = styled.div`
  display: flex;
  align-items: center;
  /* padding: 12px; */
  border-radius: 12px;
  border: 2px solid #efefef;
  background: #fff;
  user-select: none;
  overflow: hidden;
  width: 90%;
`;
export const InputCommission = styled.input`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  background: #fff;
  border: none;
  outline: none;
  text-decoration: none !important;
  width: 100%;
`;
export const InputBefore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
  height: 48px;
  background: #f4f4f4;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #6f767e;
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: #fff;
  padding: 12px;
`;