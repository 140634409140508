import { useEffect } from 'react';
import { useAppDispatch } from './redux';
import { useWallet } from '../features/wallet/use-wallet';
import { updateDecimals } from '../store/slices/decimals';
import { ChainId, contractAddresses } from '../common/constants';
import { useWeb3React } from '@web3-react/core';

export function useLoadBaseTokenDecimals(): void {
	const dispatch = useAppDispatch();
	const { provider, chainId } = useWeb3React();
	const { isRestoringWallet } = useWallet();
	useEffect(() => {
		if (!provider || !chainId || isRestoringWallet || !contractAddresses[chainId as ChainId]) return;
		dispatch(updateDecimals({ provider, chainId, tokenAddress: contractAddresses[chainId as ChainId].token }));
	}, [provider, dispatch, isRestoringWallet, chainId]);
}