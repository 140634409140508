import {
	useIsSubaccountActive,
	useMainAccountInsufficientFunds,
	useSubaccountDefaultExecutionFee,
	useSubaccountInsufficientFunds,
} from './subaccount-provider';
import { Fragment, ReactNode } from 'react';
import { useTradeContext } from '../trade-provider';
import { getNativeToken } from '../gmx/tokens';
import { ExternalLink } from '../../../components/UI/external-link';
import styled from 'styled-components';
import { TextM, TextSM } from '../../../components/UI/typography';

export const SUBACCOUNT_DOCS_URL = "https://defunds.gitbook.io/defunds/for-managers#one-click-trading";

// TODO: i18n
const Trans = Fragment;

export function SubaccountStatus({ onTopUpClick }: { onTopUpClick: () => void }): JSX.Element | null {
	const isSubaccountActive = useIsSubaccountActive();

	const baseFeePerAction = useSubaccountDefaultExecutionFee();
	const subAccountInsufficientFunds = useSubaccountInsufficientFunds(baseFeePerAction);
	const mainAccountInsufficientFunds = useMainAccountInsufficientFunds(baseFeePerAction);
	const { chainId } = useTradeContext();
	const shouldShowSubaccountInsufficientFundsError = isSubaccountActive && subAccountInsufficientFunds;
	const shouldShowMainAccountInsufficientFundsError = isSubaccountActive && mainAccountInsufficientFunds;

	const content: ReactNode[] = [];

	if (shouldShowSubaccountInsufficientFundsError || shouldShowMainAccountInsufficientFundsError) {
		if (shouldShowSubaccountInsufficientFundsError) {
			content.push(
				<AlertInfo>
					<Trans>
						There are insufficient funds in your Subaccount for One-Click Trading. Use the "
						<span onClick={onTopUpClick}>
              Top-up
            </span>
						" field to increase the Subaccount Balance.
					</Trans>
				</AlertInfo>,
			);
		}

		if (shouldShowMainAccountInsufficientFundsError) {
			const nativeToken = getNativeToken(chainId);
			content.push(
				<AlertInfo>
					<Trans>
						Not enough {nativeToken.symbol} on your Main Account.
					</Trans>
				</AlertInfo>,
			);
		}
	} else if (!isSubaccountActive) {
		return (
			<AlertInfo>
				<Trans>
					Generate and activate a Subaccount for{' '}
					<ExternalLink href={SUBACCOUNT_DOCS_URL}>One-Click Trading</ExternalLink> to reduce signing popups.
				</Trans>
			</AlertInfo>
		);
	} else {
		return null;
	}

	return <div>{content}</div>;
}

const AlertInfo = styled(TextSM)`
	margin-bottom: 24px;
`;
