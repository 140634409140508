import { useEffect, useMemo, useState } from 'react';
import { OrderStatuses, PendingOrderData } from '../v2/types/events';
import { MarketsInfoData } from '../v2/lib/markets';
import { GMXTokensData } from '../types';
import { getWrappedToken } from '../tokens';
import {
	getPendingOrderKey,
	getTriggerNameByOrderType,
	isDecreaseOrderType,
	isIncreaseOrderType,
	isLimitOrderType,
	isMarketOrderType,
} from '../v2/lib/orders';
import { getByKey } from '../v2/lib/positions';
import { getSwapPathOutputAddresses } from '../v2/lib/trade';
import { TransactionStatus } from './tx-status';
import { formatUsd } from '../utils';
import { useToastAutoClose } from '../../../../hooks/use-toast-auto-close';
import { formatTokenAmount } from '../v2/lib/tokens';
import { TextSMBold } from '../../../../components/UI/typography';

export type TransactionStatusType = 'muted' | 'loading' | 'success' | 'error';

interface OrderStatusNotificationProps {
	toastTimestamp: number;
	pendingOrderData: PendingOrderData;
	chainId: number;
	marketsInfoData?: MarketsInfoData;
	tokensData?: GMXTokensData;
	hideTxLink?: 'creation' | 'execution' | 'none';
	orderStatuses: OrderStatuses;
	setOrderStatusViewed: (key: string) => void;
}

export function OrderStatusNotification({
	pendingOrderData,
	marketsInfoData,
	tokensData,
	toastTimestamp,
	hideTxLink = 'none',
	chainId,
  orderStatuses,
	setOrderStatusViewed,
}: OrderStatusNotificationProps): JSX.Element | null {
	const wrappedNativeToken = getWrappedToken(chainId);
	const [orderStatusKey, setOrderStatusKey] = useState<string>();

	const pendingOrderKey = useMemo(() => getPendingOrderKey(pendingOrderData), [pendingOrderData]);
	const orderStatus = getByKey(orderStatuses, orderStatusKey);

	const hasError = Boolean(orderStatus?.cancelledTxnHash);

	const orderData = useMemo(() => {
		if (!marketsInfoData || !orderStatuses || !tokensData || !wrappedNativeToken) {
			return undefined;
		}

		const marketInfo = getByKey(marketsInfoData, pendingOrderData.marketAddress);
		const initialCollateralToken = getByKey(tokensData, pendingOrderData.initialCollateralTokenAddress);
		const { outTokenAddress } = getSwapPathOutputAddresses({
			marketsInfoData,
			initialCollateralAddress: pendingOrderData.initialCollateralTokenAddress,
			swapPath: pendingOrderData.swapPath,
			wrappedNativeTokenAddress: wrappedNativeToken.address,
			shouldUnwrapNativeToken: pendingOrderData.shouldUnwrapNativeToken,
		});
		const targetCollateralToken = getByKey(tokensData, outTokenAddress);

		return {
			...pendingOrderData,
			marketInfo,
			initialCollateralToken,
			targetCollateralToken,
		};
	}, [marketsInfoData, orderStatuses, pendingOrderData, tokensData, wrappedNativeToken]);

	const title = useMemo(() => {
		if (!orderData) {
			return `Unknown order`;
		}

		const { marketInfo, sizeDeltaUsd, orderType, isLong, initialCollateralDeltaAmount, initialCollateralToken } =
			orderData;

		const longShortText = isLong ? `Long` : `Short`;
		const positionText = `${marketInfo?.indexToken.symbol} ${longShortText}`;

		if (sizeDeltaUsd.eq(0)) {
			if (isIncreaseOrderType(orderType)) {
				return `Depositing ${formatTokenAmount(
					initialCollateralDeltaAmount,
					initialCollateralToken?.decimals,
					initialCollateralToken?.symbol,
				)} to ${positionText}`;
			} else {
				return `Withdrawing ${formatTokenAmount(
					initialCollateralDeltaAmount,
					initialCollateralToken?.decimals,
					initialCollateralToken?.symbol,
				)} from ${positionText}`;
			}
		} else {
			let orderTypeText = '';

			if (isMarketOrderType(orderType)) {
				orderTypeText = isIncreaseOrderType(orderType) ? `Increasing` : `Decreasing`;
			} else {
				if (isLimitOrderType(orderType)) {
					orderTypeText = `Limit order for`;
				} else if (isDecreaseOrderType(orderType)) {
					orderTypeText = `${getTriggerNameByOrderType(orderType, true)} order for`;
				}
			}

			const sign = isIncreaseOrderType(orderType) ? '+' : '-';

			return `${orderTypeText} ${marketInfo?.indexToken?.symbol} ${longShortText}: ${sign}${formatUsd(sizeDeltaUsd)}`;
		}
	}, [orderData]);

	const creationStatus = useMemo(() => {
		let text = `Sending order request`;
		let status: TransactionStatusType = 'loading';

		if (orderStatus?.createdTxnHash) {
			status = 'success';
			text = `Order request sent`;
		}

		return (
			<TransactionStatus
				status={status}
				txnHash={hideTxLink !== 'creation' ? orderStatus?.createdTxnHash : undefined}
				text={text}
				chainId={chainId}
			/>
		);
	}, [orderStatus?.createdTxnHash, hideTxLink, chainId]);

	const executionStatus = useMemo(() => {
		if (!orderData || !isMarketOrderType(orderData?.orderType)) {
			return null;
		}

		let text = `Fulfilling order request`;
		let status: TransactionStatusType = 'muted';
		let txnHash: string | undefined;

		if (orderStatus?.createdTxnHash) {
			status = 'loading';
		}

		if (orderStatus?.executedTxnHash) {
			text = `Order executed`;
			status = 'success';
			txnHash = orderStatus?.executedTxnHash;
		}

		if (orderStatus?.cancelledTxnHash) {
			text = `Order cancelled`;
			status = 'error';
			txnHash = orderStatus?.cancelledTxnHash;
		}

		return <TransactionStatus
			chainId={chainId}
			status={status}
			txnHash={hideTxLink !== 'execution' ? txnHash : undefined}
			text={text}
		/>;
	}, [chainId, orderData, orderStatus, hideTxLink]);

	useEffect(
		function getOrderStatusKey() {
			if (orderStatusKey) {
				return;
			}

			const matchedStatusKey = Object.values(orderStatuses).find((orderStatus) => {
				return !orderStatus.isViewed && getPendingOrderKey(orderStatus.data).toLowerCase() === pendingOrderKey.toLowerCase();
			})?.key;

			if (matchedStatusKey) {
				setOrderStatusKey(matchedStatusKey);
				setOrderStatusViewed(matchedStatusKey);
			}
		},
		[orderStatus, orderStatusKey, orderStatuses, pendingOrderKey, setOrderStatusViewed, toastTimestamp],
	);

	return (
		<>
			<TextSMBold>{title}</TextSMBold>
			{creationStatus}
			{executionStatus}
		</>
	);
}

export function OrdersStatusNotificiation({
  pendingOrderData,
  marketsInfoData,
  tokensData,
  toastTimestamp,
  chainId,
  orderStatuses: allOrderStatuses,
  setOrderStatusViewed,
}: {
	pendingOrderData: PendingOrderData | PendingOrderData[];
	marketsInfoData?: MarketsInfoData;
	tokensData?: GMXTokensData;
	toastTimestamp: number;
	chainId: number;
	orderStatuses: OrderStatuses;
	setOrderStatusViewed: (key: string) => void;
}): JSX.Element | null {
	const [isCancelOrderProcessing, setIsCancelOrderProcessing] = useState(false);
	const pendingOrders = useMemo(
		() => (Array.isArray(pendingOrderData) ? pendingOrderData : [pendingOrderData]),
		[pendingOrderData],
	);

	const [matchedOrderStatusKeys, setMatchedOrderStatusKeys] = useState<string[]>([]);

	const matchedOrderStatuses = useMemo(
		() => matchedOrderStatusKeys.map((key) => allOrderStatuses[key]),
		[allOrderStatuses, matchedOrderStatusKeys],
	);

	const orderByKey = useMemo(() => {
		const map = new Map<string, PendingOrderData>();
		pendingOrders.forEach((order) => {
			const key = getPendingOrderKey(order);
			map.set(key, order);
		});
		return map;
	}, [pendingOrders]);

	useEffect(() => {
		Object.values(allOrderStatuses).forEach((orderStatus) => {
			const key = getPendingOrderKey(orderStatus.data);

			if (orderStatus.isViewed || !orderByKey.has(key)) return;

			setMatchedOrderStatusKeys((prev) => [...prev, orderStatus.key]);
			setOrderStatusViewed(orderStatus.key);
		});
	}, [allOrderStatuses, orderByKey, setOrderStatusViewed]);

	const isCompleted = useMemo(() => {
		return pendingOrders.every((pendingOrder) => {
			const orderStatus = matchedOrderStatuses.find(
				(status) => getPendingOrderKey(status.data) === getPendingOrderKey(pendingOrder),
			);
			return isMarketOrderType(pendingOrder.orderType)
				? Boolean(orderStatus?.executedTxnHash)
				: Boolean(orderStatus?.createdTxnHash);
		});
	}, [matchedOrderStatuses, pendingOrders]);

	useToastAutoClose(isCompleted, toastTimestamp);

	return (
		<div>
			{pendingOrders.map((order, index) => {
				return (
					<OrderStatusNotification
						key={index}
						pendingOrderData={order}
						marketsInfoData={marketsInfoData}
						tokensData={tokensData}
						toastTimestamp={toastTimestamp}
						chainId={chainId}
						hideTxLink={pendingOrders.length > 1 ? 'creation' : 'none'}
						orderStatuses={allOrderStatuses}
						setOrderStatusViewed={setOrderStatusViewed}
					/>
				);
			})}
		</div>
	);
}
