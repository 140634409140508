export interface SelectOption {
	id: number | string;
	label: string;
}

export type Theme = 'light' | 'dark';

export interface Customizable {
	className?: string;
}

export enum ModalType {
	Success = 'success',
	Warning = 'warning',
	CloseFundWarning = 'close-fund-warning',
	AaveSupply = 'aave-supply',
	AaveWithdraw = 'aave-withdraw',
	GMXOpen = 'gmx-open',
	GMXClose = 'gmx-close',
	GMXEdit = 'gmx-edit',
}

export interface CloseFundWarningModalData {
	id: string;
}

export enum TimePeriod {
	AllTime,
	Year,
	Quarter,
	Month,
}

export type Loadable<T> = { loading: true } | { loading: false; data: T };
