import { Modal as ModalComponent } from '@yandex/ui/Modal/desktop/bundle';
import { Success } from './modal/success';
import React from 'react';
import styled from 'styled-components';
import { Title } from './title/title';
import { ReactComponent as CloseImage } from '../asset/images/close.svg';
import { useModalContext } from './modal/modal-provider';
import { WarningModalContent } from './warning-modal-content';

export function Modal(): JSX.Element | null {
	const { currentModal, closeModal } = useModalContext();

	if (!currentModal) return null;
	const { id, type, caption, content, maxWidth, title, titleDotColor } = currentModal;
	const close = (): void => closeModal(id)
	return (
		<ModalComponent theme="normal" onClose={close} visible>
			<Container maxWidth={maxWidth}>
				<Head>
					{title && <Title title={title} dotColor={titleDotColor} />}
					<CloseContainer onClick={close}>
						<CloseImage />
					</CloseContainer>
				</Head>
				{type === 'success' && <Success
					onClose={close}
					caption={caption}
				/>}
				{currentModal.type === 'warning' && <WarningModalContent
          id={id}
          caption={caption}
          title={title}
          onContinue={currentModal.onContinue}
        />}
				{content}
			</Container>
		</ModalComponent>
	);
}

const Container = styled.div<{ maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  padding: 24px;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 16px;
  width: 100%;
  max-width: ${props => props.maxWidth || '800px'};
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const CloseContainer = styled.div`
  cursor: pointer;
	margin-left: auto;
`;
