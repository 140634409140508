import { useEffect } from 'react';

export function usePreventNumberInputScroll(): void {
	useEffect(() => {
		const onWheel = (): void => {
			if (document.activeElement instanceof HTMLInputElement && document.activeElement?.type === 'number') {
				document.activeElement?.blur();
			}
		};
		document.addEventListener('wheel', onWheel);
		return () => document.removeEventListener('wheel', onWheel);
	}, []);
}
