import { useEffect, useState } from "react";
import { useWeb3React } from '@web3-react/core';

export function useTransactionPending(hash: string | null | undefined): boolean {
  const { provider } = useWeb3React();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    async function waitPending(): Promise<void> {
      if (!hash || !provider) {
        setStatus(false);
        return;
      }

      const tx = await provider.getTransaction(hash);
      setStatus(true);
      await tx.wait();
      setStatus(false);
    }

    waitPending();
  }, [hash, provider]);

  return status;
}
