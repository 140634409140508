// TODO: get rid of yandex lib
import {
	Checkbox as CheckboxComponent,
	ICheckboxProps,
} from '@yandex/ui/Checkbox/desktop/bundle';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Customizable } from '../interfaces/ui';

export interface CheckboxRowProps extends ICheckboxProps, Customizable {
	title?: ReactNode;
	value?: boolean;
	onChange?: (v: boolean) => void;
	controlled?: boolean;
}

export function CheckboxRow({ title, value, onChange, controlled = false, className, ...rest }: CheckboxRowProps): JSX.Element {
	const [localValue, setLocalValue] = useState(value || false);

	useEffect(() => {
		if (controlled) return;
		onChange?.(localValue);
	}, [controlled, localValue, onChange]);

	return <ControlContainer className={className}>
		<ControlWrapper onClick={() => controlled ? onChange?.(!value) : setLocalValue(!localValue)}>
			<CheckboxComponent
				label={false}
				size="m"
				view="default"
				checked={controlled ? value : localValue}
				{...rest}
			/>
			<ControlTitle>
				{title}
			</ControlTitle>
		</ControlWrapper>
	</ControlContainer>;
}

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  margin-bottom: 8px;
`;
const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;
const ControlTitle = styled.div`
  margin-left: 12px;
`;
