import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { TokenListItem } from '../interfaces/whitelists';
import { ensureNotNull } from '../common/assertions';

export interface TokenListContextValues {
	onSelectToken: (token: TokenListItem) => void;
	tokenOptions: TokenListItem[];
	isTokenModalVisible: boolean;
	setIsTokenModalVisible: (v: boolean) => void;
	shouldShowBalances: boolean;
	openTokenListModal: (tokens: TokenListItem[], setToken: (t: TokenListItem) => void, shouldShowBalances: boolean) => void;
}

export const TokenListContext = createContext<TokenListContextValues | null>(null);

export function TokenListProvider({ children }: { children: ReactNode }): JSX.Element {
	const onSelectTokenRef = useRef<(token: TokenListItem) => void>(() => {});
	const setOnSelectToken = useCallback((func: (token: TokenListItem) => void) => {
		onSelectTokenRef.current = func;
	}, []);
	const [tokenOptions, setTokenOptions] = useState<TokenListItem[]>([]);
	const [isTokenModalVisible, setIsTokenModalVisible] = useState(false);
	const [shouldShowBalances, setShouldShowBalances] = useState(true);

	const openTokenListModal = useCallback(
		(tokenOptions: TokenListItem[], setToken: (t: TokenListItem) => void, shouldShowBalances: boolean): void => {
			setTokenOptions(tokenOptions);
			setOnSelectToken(setToken);
			setIsTokenModalVisible(true);
			setShouldShowBalances(shouldShowBalances);
		},
		[setTokenOptions, setOnSelectToken, setIsTokenModalVisible, setShouldShowBalances]
	);

	return (
		<TokenListContext.Provider value={{
			onSelectToken: onSelectTokenRef.current,
			tokenOptions,
			isTokenModalVisible,
			setIsTokenModalVisible,
			shouldShowBalances,
			openTokenListModal,
		}}>
			{children}
		</TokenListContext.Provider>
	);
}

export function useTokenListModal(): TokenListContextValues {
	return ensureNotNull(useContext(TokenListContext));
}
