import styled from "styled-components";
import { breakpoints } from './components/UI/breakpoints';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const SIDEBAR_WIDTH = 292;

export const Layout = styled.div`
  height: 100vh;
  display: flex;
`;

export const Wrap = styled.div`
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`;

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const SidebarContainer = styled.div`
  height: 100vh;
  background-color: #fff;
	width: ${SIDEBAR_WIDTH}px;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    height: auto;
	  z-index: 1;
	  width: 100%;
	  position: fixed;
	  bottom: 0;
	  left: 0;
	  right: 0;
    box-shadow: inset 0 1px 0 #F4F4F4;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 40px;
  background-color: #fff;
  border-left: 1px solid #f4f4f4;
  width: 100%;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    border-left: none;
    padding: 12px 24px;
    box-shadow: inset 0 -1px 0 #F4F4F4;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const StyledOverlayScrollbarsComponent = styled(OverlayScrollbarsComponent)`
	padding-bottom: 64px;
`;

export const Content = styled.div`
  padding: 40px;
	height: 100%;

  @media screen and (max-width: ${breakpoints.laptopS}px) {
    padding: 16px;
  }
`;
