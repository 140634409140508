import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CONFIRMATION_COUNT } from '../../common/constants';

import { DefundsEthClient } from '../../contracts/defunds-eth-client';
import type { RootState } from '../store';
import { ModalType, Theme } from '../../interfaces/ui';
import { updateApplications } from '../../features/fund/slice';
import { TFunction } from 'i18next';
import { ModalData } from '../../components/modal/modal-provider';

const initialState: {
  theme: Theme;
  wallet: string | null;
  investLoading: boolean;
  withdrawLoading: boolean;
  approveLoading: boolean;
  walletModalOpen: boolean;
  investModalOpen: boolean;
  withdrawModalOpen: boolean;
  createSuccess: boolean;
  investAmount: string;
  withdrawAmount: string;
} = {
  theme: "light",
  wallet: null,
  investLoading: false,
  withdrawLoading: false,
  approveLoading: false,
  walletModalOpen: false,
  investModalOpen: false,
  withdrawModalOpen: false,
  createSuccess: false,
  investAmount: "",
  withdrawAmount: "",
};

export const invest = createAsyncThunk<unknown, { ethClient: DefundsEthClient, fundId: string; amount: string; t: TFunction; openModal: ({ type }: ModalData) => void; }, { state: RootState }>(
  "ethereum/invest",
  async (
    { ethClient, fundId, amount, t, openModal },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const tx = await ethClient.stake({
        fundId,
        amount,
      });
      await tx.wait(CONFIRMATION_COUNT);
      dispatch(updateApplications({ ethClient, id: fundId }));
      openModal({
        type: ModalType.Success,
        caption: t<string>('Fund.InvestModal.PendingInfo')
      });
      dispatch(setInvestModalOpen(false));
    } catch (e) {
      console.log(e);
      return rejectWithValue(null);
    }
  }
);

export const withdraw = createAsyncThunk<unknown, { ethClient: DefundsEthClient, fundId: string; amount: string; t: TFunction; openModal: ({ type }: ModalData) => void; }, { state: RootState }>(
  "ethereum/withdraw",
  async (
    { ethClient, fundId, amount, t, openModal },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const tx = await ethClient.unstake({
        fundId,
        amount,
      });
      await tx.wait(CONFIRMATION_COUNT);
      dispatch(updateApplications({ ethClient, id: fundId }));
      openModal({
        type: ModalType.Success,
        caption: t<string>('Fund.WithdrawModal.SuccessText')
      });
      dispatch(setWithdrawModalOpen(false));
    } catch (e) {
      console.error(e);
      return rejectWithValue(null);
    }
  }
);

export const approve = createAsyncThunk<
  unknown,
  { ethClient: DefundsEthClient, token: string; amount: string; spender?: string },
  { state: RootState }
>(
  "ethereum/approve",
  async (
    { ethClient, token, amount, spender },
    { rejectWithValue }
  ) => {
    try {
      const tx = await ethClient.approve({
        token,
        amount,
        spender,
      });
      return tx.wait(CONFIRMATION_COUNT);
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setWallet: (state, action: PayloadAction<string | null>) => {
      state.wallet = action.payload;
    },
    setInvestLoading: (state, action: PayloadAction<boolean>) => {
      state.investLoading = action.payload;
    },
    setWithdrawLoading: (state, action: PayloadAction<boolean>) => {
      state.withdrawLoading = action.payload;
    },
    setApproveLoading: (state, action: PayloadAction<boolean>) => {
      state.approveLoading = action.payload;
    },
    setCreateSuccess: (state, action: PayloadAction<boolean>) => {
      state.createSuccess = action.payload;
    },
    setWalletModalOpen: (state, action: PayloadAction<boolean>) => {
      state.walletModalOpen = action.payload;
    },
    setInvestModalOpen: (state, action: PayloadAction<boolean>) => {
      state.investModalOpen = action.payload;
    },
    setWithdrawModalOpen: (state, action: PayloadAction<boolean>) => {
      state.withdrawModalOpen = action.payload;
    },
    setInvestAmount: (state, action: PayloadAction<string>) => {
      state.investAmount = action.payload;
    },
    setWithdrawAmount: (state, action: PayloadAction<string>) => {
      state.withdrawAmount = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(invest.pending, (state) => {
        state.investLoading = true;
      })
      .addCase(invest.fulfilled, (state) => {
        state.investLoading = false;
        state.investAmount = '';
      })
      .addCase(invest.rejected, (state) => {
        state.investLoading = false;
      })
      .addCase(withdraw.pending, (state) => {
        state.withdrawLoading = true;
      })
      .addCase(withdraw.fulfilled, (state) => {
        state.withdrawLoading = false;
        state.withdrawAmount = '';
      })
      .addCase(withdraw.rejected, (state) => {
        state.withdrawLoading = false;
      })
      .addCase(approve.pending, (state) => {
        state.approveLoading = true;
      })
      .addCase(approve.fulfilled, (state) => {
        state.approveLoading = false;
      })
      .addCase(approve.rejected, (state) => {
        state.approveLoading = false;
      });
  },
});

export const {
  setTheme,
  setWallet,
  setWithdrawLoading,
  setCreateSuccess,
  setWithdrawModalOpen,
  setWalletModalOpen,
  setInvestModalOpen,
  setInvestAmount,
  setWithdrawAmount,
} = appSlice.actions;

export const selectTheme = (state: RootState): Theme => state.app.theme as Theme; // TODO: fix types for theme
export const selectWallet = (state: RootState): string | null => state.app.wallet;

export const selectInvestLoading = (state: RootState): boolean => state.app.investLoading;
export const selectWithdrawLoading = (state: RootState): boolean => state.app.withdrawLoading;
export const selectWalletModalOpen = (state: RootState): boolean => state.app.walletModalOpen;
export const selectInvestModalOpen = (state: RootState): boolean => state.app.investModalOpen;
export const selectWithdrawModalOpen = (state: RootState): boolean => state.app.withdrawModalOpen;
export const selectInvestAmount = (state: RootState): string => state.app.investAmount;
export const selectWithdrawAmount = (state: RootState): string => state.app.withdrawAmount;
export const selectApproveLoading = (state: RootState): boolean => state.app.approveLoading;

export default appSlice.reducer;
