import { BigNumber, constants, ethers } from 'ethers';
import { GMXToken, GMXTokenInfo, GMXTradeMode } from './types';
import { ChainId } from '../../../common/constants';
import { PRECISION, USDG_ADDRESS } from './constants';
import { expandDecimals } from './utils';

export const NATIVE_TOKEN_ADDRESS = ethers.constants.AddressZero;
export const TOKENS: { [chainId: number]: GMXToken[] } = {
  [ChainId.Arbitrum]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      isV1Available: true,
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      isV1Available: true,
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      assetSymbol: "WBTC",
      decimals: 8,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
      coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      explorerUrl: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      isV1Available: true,
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      priceDecimals: 3,
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      imageUrl: "https://assets.coingecko.com/coins/images/16547/small/photo_2023-03-29_21.47.00.jpeg?1680097630",
      coingeckoUrl: "https://www.coingecko.com/en/coins/arbitrum",
      explorerUrl: "https://arbiscan.io/token/0x912ce59144191c1204e64559fe8253a0e49e6548",
    },
    {
      name: "Wrapped SOL (Wormhole)",
      symbol: "SOL",
      decimals: 9,
      address: "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
      imageUrl: "https://assets.coingecko.com/coins/images/4128/small/solana.png?1640133422",
      coingeckoUrl: "https://www.coingecko.com/en/coins/solana",
      explorerUrl: "https://arbiscan.io/token/0x2bCc6D6CdBbDC0a4071e48bb3B969b06B3330c07",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      priceDecimals: 3,
      address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
      coingeckoUrl: "https://www.coingecko.com/en/coins/chainlink",
      explorerUrl: "https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
      isV1Available: true,
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      priceDecimals: 3,
      address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
      coingeckoUrl: "https://www.coingecko.com/en/coins/uniswap",
      explorerUrl: "https://arbiscan.io/token/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
      isV1Available: true,
    },
    {
      name: "Bridged USDC (USDC.e)",
      symbol: "USDC.e",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      explorerUrl: "https://arbiscan.io/token/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isV1Available: true,
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      isStable: true,
      isV1Available: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      explorerUrl: "https://arbiscan.io/address/0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
      explorerUrl: "https://arbiscan.io/address/0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      isV1Available: true,
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
      explorerUrl: "https://arbiscan.io/token/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isV1Available: true,
    },
    {
      name: "Frax",
      symbol: "FRAX",
      decimals: 18,
      address: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
      coingeckoUrl: "https://www.coingecko.com/en/coins/frax",
      explorerUrl: "https://arbiscan.io/token/0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
      isV1Available: true,
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      decimals: 18,
      address: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
      isV1Available: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x47904963fc8b2340414262125aF798B9655E58Cd",
      isSynthetic: true,
      decimals: 8,
      imageUrl: "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579",
      //TODO: remove explorerUrl in future
      explorerUrl: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 8,
      priceDecimals: 4,
      address: "0xC4da4c24fd591125c3F47b340b6f4f76111883d8",
      isSynthetic: true,
      imageUrl: "https://assets.coingecko.com/coins/images/5/small/dogecoin.png?1547792256",
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
    },
    {
      name: "Litecoin",
      symbol: "LTC",
      decimals: 8,
      address: "0xB46A094Bc4B0adBD801E14b9DB95e05E28962764",
      isSynthetic: true,
      imageUrl: "https://assets.coingecko.com/coins/images/2/small/litecoin.png?1547033580",
      coingeckoUrl: "https://www.coingecko.com/en/coins/litecoin",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 6,
      priceDecimals: 4,
      address: "0xc14e065b0067dE91534e032868f5Ac6ecf2c6868",
      imageUrl: "https://assets.coingecko.com/coins/images/44/small/xrp-symbol-white-128.png?1605778731",
      coingeckoUrl: "https://www.coingecko.com/en/coins/xrp",
      isSynthetic: true,
    },
    {
      name: "Wrapped BNB (LayerZero)",
      symbol: "BNB",
      assetSymbol: "WBNB (LayerZero)",
      address: "0xa9004A5421372E1D83fB1f85b0fc986c912f91f3",
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970",
      coingeckoUrl: "https://www.coingecko.com/en/coins/bnb",
      explorerUrl: "https://arbiscan.io/token/0xa9004A5421372E1D83fB1f85b0fc986c912f91f3",
    },
    {
      name: "Cosmos",
      symbol: "ATOM",
      assetSymbol: "ATOM",
      address: "0x7D7F1765aCbaF847b9A1f7137FE8Ed4931FbfEbA",
      decimals: 6,
      imageUrl: "https://assets.coingecko.com/coins/images/1481/standard/cosmos_hub.png?1696502525",
      coingeckoUrl: "https://www.coingecko.com/en/coins/cosmos-hub",
      isSynthetic: true,
    },
    {
      name: "Near",
      symbol: "NEAR",
      assetSymbol: "NEAR",
      address: "0x1FF7F3EFBb9481Cbd7db4F932cBCD4467144237C",
      decimals: 24,
      imageUrl: "https://assets.coingecko.com/coins/images/10365/standard/near.jpg?1696510367",
      coingeckoUrl: "https://www.coingecko.com/en/coins/near",
      isSynthetic: true,
    },
    {
      name: "Aave",
      symbol: "AAVE",
      assetSymbol: "AAVE",
      address: "0xba5DdD1f9d7F570dc94a51479a000E3BCE967196",
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/12645/standard/AAVE.png?1696512452",
      coingeckoUrl: "https://www.coingecko.com/en/coins/aave",
    },
    {
      name: "Wrapped AVAX (Wormhole)",
      symbol: "AVAX",
      assetSymbol: "WAVAX (Wormhole)",
      address: "0x565609fAF65B92F7be02468acF86f8979423e514",
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
      coingeckoUrl: "https://www.coingecko.com/en/coins/avalanche",
    },
    {
      name: "Optimism",
      symbol: "OP",
      address: "0xaC800FD6159c2a2CB8fC31EF74621eB430287a5A",
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/25244/standard/Optimism.png?1696524385",
      coingeckoUrl: "https://www.coingecko.com/en/coins/optimism",
    },
  ],
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: GMXToken } } = {};
export const V1_TOKENS: { [chainId: number]: GMXToken[] } = {};
export const V2_TOKENS: { [chainId: number]: GMXToken[] } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: GMXToken } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: GMXToken } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: GMXToken } = {};

const CHAIN_IDS = [ChainId.Arbitrum];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  V1_TOKENS[chainId] = [];
  V2_TOKENS[chainId] = [];
  let tokens = TOKENS[chainId];

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = {
      ...token,
      address: token.address,
    };
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }

    if (token.isV1Available && !token.isTempHidden) {
      V1_TOKENS[chainId].push(token);
    }

    if (!token.isPlatformToken && !token.isTempHidden) {
      V2_TOKENS[chainId].push(token);
    }
  }
}

export function getWrappedToken(chainId: number): GMXToken {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number): GMXToken {
  return NATIVE_TOKENS_MAP[chainId];
}

const empty: GMXToken[] = []
export function getTokens(chainId?: number): GMXToken[] {
  return chainId ? TOKENS[chainId] : empty;
}

export function isValidToken(chainId: number, address: string): boolean {
  if (!TOKENS_MAP[chainId]) {
    return false;
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string): GMXToken {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokensMap(chainId: number): { [address: string]: GMXToken } {
  return TOKENS_MAP[chainId];
}

export function getV2Tokens(chainId: number): GMXToken[] {
  return V2_TOKENS[chainId] || [];
}

export function getTokenBySymbol(chainId: number, symbol: string): GMXToken {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}
export function getNormalizedTokenSymbol(tokenSymbol: string): string {
  if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

export function getTokenInfo(
  infoTokensMap: Record<string, GMXTokenInfo>,
  tokenAddress: string,
  replaceNative?: boolean,
  nativeTokenAddress?: string
): GMXTokenInfo {
  if (replaceNative && tokenAddress === nativeTokenAddress) {
    return infoTokensMap[constants.AddressZero];
  }

  return infoTokensMap[tokenAddress];
}

export function getUsd(
  amount: BigNumber | undefined,
  tokenAddress: string,
  max: boolean,
  infoTokens: Record<string, GMXTokenInfo>,
  orderOption?: GMXTradeMode,
  triggerPriceUsd?: BigNumber
): BigNumber | null {
  if (!amount) {
    return null;
  }
  if (tokenAddress === USDG_ADDRESS) {
    return amount.mul(PRECISION).div(expandDecimals(1, 18));
  }
  const info = getTokenInfo(infoTokens, tokenAddress);
  const price = getTriggerPrice(tokenAddress, max, info, orderOption, triggerPriceUsd);
  if (!price) {
    return null;
  }

  return amount.mul(price).div(expandDecimals(1, info.decimals));
}

function getTriggerPrice(
  tokenAddress: string,
  max: boolean,
  info: GMXTokenInfo,
  orderOption?: GMXTradeMode,
  triggerPriceUsd?: BigNumber,
): BigNumber | null {
  // Limit/stop orders are executed with price specified by user
  if (orderOption && orderOption !== GMXTradeMode.Market && triggerPriceUsd) {
    return triggerPriceUsd;
  }

  // Market orders are executed with current market price
  if (!info) {
    return null;
  }
  if (max && !info.maxPrice) {
    return null;
  }
  if (!max && !info.minPrice) {
    return null;
  }
  return (max ? info.maxPrice : info.minPrice) || null;
}

export function getTokenAmountFromUsd(
  infoTokens: Record<string, GMXTokenInfo>,
  tokenAddress: string,
  usdAmount?: BigNumber,
  opts: {
    max?: boolean;
    overridePrice?: BigNumber;
  } = {}
): BigNumber | null {
  if (!usdAmount) {
    return null;
  }

  if (tokenAddress === USDG_ADDRESS) {
    return usdAmount.mul(expandDecimals(1, 18)).div(PRECISION);
  }

  const info: GMXTokenInfo | undefined = getTokenInfo(infoTokens, tokenAddress);

  if (!info) {
    return null;
  }

  const price = opts.overridePrice || (opts.max ? info.maxPrice : info.minPrice);

  if (!BigNumber.isBigNumber(price) || price.lte(0)) {
    return null;
  }

  return usdAmount.mul(expandDecimals(1, info.decimals)).div(price);
}

export function getIsEquivalentTokens(token1: GMXToken, token2: GMXToken): boolean {
  if (token1.address === token2.address) {
    return true;
  }

  if (token1.wrappedAddress === token2.address || token2.wrappedAddress === token1.address) {
    return true;
  }

  if ((token1.isSynthetic || token2.isSynthetic) && token1.symbol === token2.symbol) {
    return true;
  }

  return false;
}

export function convertTokenAddress(chainId: number, address: string, convertTo?: "wrapped" | "native"): string {
  const wrappedToken = getWrappedToken(chainId);

  if (convertTo === "wrapped" && address === NATIVE_TOKEN_ADDRESS) {
    return wrappedToken.address;
  }

  if (convertTo === "native" && address === wrappedToken.address) {
    return NATIVE_TOKEN_ADDRESS;
  }

  return address;
}