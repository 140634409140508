import { ReactNode } from 'react';
import { useTranslation } from "react-i18next";

import {
  Container,
  Title,
  Caption,
  SuccessCloseButton,
} from "./success.styled";

import hand from "../../asset/images/hand.png";
import hand2x from "../../asset/images/hand@2x.png";
import hand3x from "../../asset/images/hand@3x.png";

export interface SuccessProps {
  onClose: () => void;
  caption?: ReactNode;
  buttonText?: string;
}

export function Success({ onClose, caption, buttonText }: SuccessProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <img src={hand} srcSet={`${hand2x} 2x, ${hand3x} 3x`} alt="" />
      <Title>{t(`Modal.Success.Title`)}</Title>
      {caption && <Caption>{caption}</Caption>}
      {buttonText && <SuccessCloseButton onClick={onClose}>{buttonText}</SuccessCloseButton>}
    </Container>
  );
}
