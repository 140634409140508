import { ChainId } from '../../../../../../common/constants';

const ORACLE_KEEPER_URLS: Record<ChainId, string[]> = {
	[ChainId.Arbitrum]: ['https://arbitrum-api.gmxinfra.io', 'https://arbitrum-api.gmxinfra2.io'],
	[ChainId.BNBChain]: [],
	[ChainId.Optimism]: [],
};

export function getOracleKeeperUrl(chainId: ChainId, index: number): string | null {
	const urls = ORACLE_KEEPER_URLS[chainId];

	if (!urls.length) {
		return null;
	}

	return urls[index] || urls[0] || null;
}

export function getOracleKeeperNextIndex(chainId: ChainId, currentIndex: number): number {
	const urls = ORACLE_KEEPER_URLS[chainId];

	if (!urls.length) {
		throw new Error(`No oracle keeper urls for chain ${chainId}`);
	}

	return urls[currentIndex + 1] ? currentIndex + 1 : 0;
}
