import { BigNumber } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { useQuery } from '@tanstack/react-query';

export function useEthBalance(address?: string, interval?: number): { isLoading: boolean; balance: BigNumber; refetchBalance: () => void } {
	const { provider } = useWeb3React();
	const { data: balance, isLoading, refetch } = useQuery(
		['ethBalance', address],
		() => address && provider ? provider.getBalance(address) : BigNumber.from(0),
		{ refetchInterval: interval }
	);

	return { balance: balance || BigNumber.from(0), isLoading, refetchBalance: refetch };
}
