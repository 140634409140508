import { useAppSelector } from './redux';
import { selectDecimals } from '../store/slices/decimals';
import { useWeb3React } from '@web3-react/core';
import { getToken, isValidToken } from '../features/trade/gmx/tokens';

export function useDecimals(tokenAddress?: string | null, chainId?: number): number | null {
	const { chainId: currentChainId = null } = useWeb3React();
	const decimals = useAppSelector(
		state => selectDecimals(state, { token: tokenAddress, chainId: chainId || currentChainId })
	);
	const gmxToken = chainId && tokenAddress && isValidToken(chainId, tokenAddress)
		? getToken(chainId, tokenAddress)
		: null;
	return gmxToken ? gmxToken.decimals : decimals;
}
