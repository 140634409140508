import { getCappedPositionImpactUsd, getPriceImpactByAcceptablePrice } from '../../fees';
import { MarketInfo } from '../../markets';
import { OrderType } from '../../orders';
import { convertToTokenAmount } from '../../tokens';
import { BigNumber } from 'ethers';
import { applyFactor, expandDecimals, getBasisPoints, roundUpMagnitudeDivision } from '../../../../utils';
import { TriggerThresholdType } from '../types';
import { GMXTokenPrices } from '../../../../types';
import { BASIS_POINTS_DIVISOR, DEFAULT_ACCEPABLE_PRICE_IMPACT_BUFFER } from '../../../../constants';

export function getMarkPrice(p: { prices: GMXTokenPrices; isIncrease: boolean; isLong: boolean }): BigNumber {
	const { prices, isIncrease, isLong } = p;

	const shouldUseMaxPrice = getShouldUseMaxPrice(isIncrease, isLong);

	return shouldUseMaxPrice ? prices.maxPrice : prices.minPrice;
}

export function getAcceptablePriceInfo(p: {
	marketInfo: MarketInfo;
	isIncrease: boolean;
	isLong: boolean;
	indexPrice: BigNumber;
	sizeDeltaUsd: BigNumber;
	maxNegativePriceImpactBps?: BigNumber;
}): {
	acceptablePrice: BigNumber;
	acceptablePriceDeltaBps: BigNumber;
	priceImpactDeltaAmount: BigNumber;
	priceImpactDeltaUsd: BigNumber;
	priceImpactDiffUsd: BigNumber;
} {
	const { marketInfo, isIncrease, isLong, indexPrice, sizeDeltaUsd, maxNegativePriceImpactBps } = p;
	const { indexToken } = marketInfo;

	const values = {
		acceptablePrice: BigNumber.from(0),
		acceptablePriceDeltaBps: BigNumber.from(0),
		priceImpactDeltaAmount: BigNumber.from(0),
		priceImpactDeltaUsd: BigNumber.from(0),
		priceImpactDiffUsd: BigNumber.from(0),
	};

	if (!sizeDeltaUsd.gt(0)) {
		return values;
	}

	const shouldFlipPriceImpact = getShouldUseMaxPrice(p.isIncrease, p.isLong);

	// For Limit / Trigger orders
	if (maxNegativePriceImpactBps?.gt(0)) {
		let priceDelta = indexPrice.mul(maxNegativePriceImpactBps).div(BASIS_POINTS_DIVISOR);
		priceDelta = shouldFlipPriceImpact ? priceDelta?.mul(-1) : priceDelta;

		values.acceptablePrice = indexPrice.sub(priceDelta);
		values.acceptablePriceDeltaBps = maxNegativePriceImpactBps.mul(-1);

		const priceImpact = getPriceImpactByAcceptablePrice({
			sizeDeltaUsd,
			acceptablePrice: values.acceptablePrice,
			indexPrice,
			isLong,
			isIncrease,
		});

		values.priceImpactDeltaUsd = priceImpact.priceImpactDeltaUsd;
		values.priceImpactDeltaAmount = priceImpact.priceImpactDeltaAmount;

		return values;
	}

	values.priceImpactDeltaUsd = getCappedPositionImpactUsd(
		marketInfo,
		isIncrease ? sizeDeltaUsd : sizeDeltaUsd.mul(-1),
		isLong,
		{
			fallbackToZero: !isIncrease,
		},
	);

	if (!isIncrease && values.priceImpactDeltaUsd.lt(0)) {
		const minPriceImpactUsd = applyFactor(sizeDeltaUsd, marketInfo.maxPositionImpactFactorNegative).mul(-1);

		if (values.priceImpactDeltaUsd.lt(minPriceImpactUsd)) {
			values.priceImpactDiffUsd = minPriceImpactUsd.sub(values.priceImpactDeltaUsd);
			values.priceImpactDeltaUsd = minPriceImpactUsd;
		}
	}

	if (values.priceImpactDeltaUsd.gt(0)) {
		values.priceImpactDeltaAmount = convertToTokenAmount(
			values.priceImpactDeltaUsd,
			indexToken.decimals,
			indexToken.prices.maxPrice,
		)!;
	} else {
		values.priceImpactDeltaAmount = roundUpMagnitudeDivision(
			values.priceImpactDeltaUsd.mul(expandDecimals(1, indexToken.decimals)),
			indexToken.prices.minPrice,
		);
	}

	const priceImpactForPriceAdjustment = shouldFlipPriceImpact
		? values.priceImpactDeltaUsd.mul(-1)
		: values.priceImpactDeltaUsd;

	values.acceptablePrice = indexPrice.mul(sizeDeltaUsd.add(priceImpactForPriceAdjustment)).div(sizeDeltaUsd);

	const priceDelta = indexPrice.sub(values.acceptablePrice).mul(shouldFlipPriceImpact ? 1 : -1);

	values.acceptablePriceDeltaBps = getBasisPoints(priceDelta, p.indexPrice);

	return values;
}

export function getAcceptablePrice(p: {
	isIncrease: boolean;
	isLong: boolean;
	indexPrice: BigNumber;
	priceImpactDeltaUsd?: BigNumber;
	sizeDeltaUsd: BigNumber;
	acceptablePriceImpactBps?: BigNumber;
}): {
	acceptablePrice: BigNumber;
	priceDiffBps: BigNumber;
} {
	if (!p.sizeDeltaUsd?.gt(0)) {
		return {
			acceptablePrice: p.indexPrice,
			priceDiffBps: BigNumber.from(0),
		};
	}

	let acceptablePrice = p.indexPrice;
	let priceDiffBps = p.acceptablePriceImpactBps || BigNumber.from(0);

	const shouldFlipPriceImpact = getShouldUseMaxPrice(p.isIncrease, p.isLong);

	if (priceDiffBps.abs().gt(0)) {
		let priceDelta = p.indexPrice.mul(priceDiffBps).div(BASIS_POINTS_DIVISOR);
		priceDelta = shouldFlipPriceImpact ? priceDelta?.mul(-1) : priceDelta;

		acceptablePrice = p.indexPrice.sub(priceDelta);
	} else if (p.priceImpactDeltaUsd?.abs().gt(0)) {
		const priceImpactForPriceAdjustment = shouldFlipPriceImpact ? p.priceImpactDeltaUsd.mul(-1) : p.priceImpactDeltaUsd;
		acceptablePrice = p.indexPrice.mul(p.sizeDeltaUsd.add(priceImpactForPriceAdjustment)).div(p.sizeDeltaUsd);

		const priceDelta = p.indexPrice
			.sub(acceptablePrice)
			.abs()
			.mul(p.priceImpactDeltaUsd.isNegative() ? -1 : 1);

		priceDiffBps = getBasisPoints(priceDelta, p.indexPrice);
	}

	return {
		acceptablePrice,
		priceDiffBps,
	};
}

export function applySlippageToMinOut(allowedSlippage: number, minOutputAmount: BigNumber): BigNumber {
	const slippageBasisPoints = BASIS_POINTS_DIVISOR - allowedSlippage;

	return minOutputAmount.mul(slippageBasisPoints).div(BASIS_POINTS_DIVISOR);
}

export function getShouldUseMaxPrice(isIncrease: boolean, isLong: boolean): boolean {
	return isIncrease ? isLong : !isLong;
}

export function getTriggerThresholdType(orderType: OrderType, isLong: boolean): TriggerThresholdType {
	// limit order
	if (orderType === OrderType.LimitIncrease) {
		return isLong ? TriggerThresholdType.Below : TriggerThresholdType.Above;
	}

	// take profit order
	if (orderType === OrderType.LimitDecrease) {
		return isLong ? TriggerThresholdType.Above : TriggerThresholdType.Below;
	}

	// stop loss order
	if (orderType === OrderType.StopLossDecrease) {
		return isLong ? TriggerThresholdType.Below : TriggerThresholdType.Above;
	}

	throw new Error('Invalid trigger order type');
}

export function getTriggerDecreaseOrderType(p: {
	triggerPrice: BigNumber;
	markPrice: BigNumber;
	isLong: boolean;
}): OrderType.LimitDecrease | OrderType.StopLossDecrease {
	const { triggerPrice, markPrice, isLong } = p;

	const isTriggerAboveMarkPrice = triggerPrice.gt(markPrice);

	if (isTriggerAboveMarkPrice) {
		return isLong ? OrderType.LimitDecrease : OrderType.StopLossDecrease;
	} else {
		return isLong ? OrderType.StopLossDecrease : OrderType.LimitDecrease;
	}
}

export function getAcceptablePriceByPriceImpact(p: {
	isIncrease: boolean;
	isLong: boolean;
	indexPrice: BigNumber;
	sizeDeltaUsd: BigNumber;
	priceImpactDeltaUsd: BigNumber;
}): {
	acceptablePrice: BigNumber;
	acceptablePriceDeltaBps: BigNumber;
	priceDelta: BigNumber;
} {
	const { indexPrice, sizeDeltaUsd, priceImpactDeltaUsd } = p;

	if (!sizeDeltaUsd.gt(0) || indexPrice.eq(0)) {
		return {
			acceptablePrice: indexPrice,
			acceptablePriceDeltaBps: BigNumber.from(0),
			priceDelta: BigNumber.from(0),
		};
	}

	const shouldFlipPriceImpact = getShouldUseMaxPrice(p.isIncrease, p.isLong);

	const priceImpactForPriceAdjustment = shouldFlipPriceImpact ? priceImpactDeltaUsd.mul(-1) : priceImpactDeltaUsd;
	const acceptablePrice = indexPrice.mul(sizeDeltaUsd.add(priceImpactForPriceAdjustment)).div(sizeDeltaUsd);
	const priceDelta = indexPrice.sub(acceptablePrice).mul(shouldFlipPriceImpact ? 1 : -1);
	const acceptablePriceDeltaBps = getBasisPoints(priceDelta, p.indexPrice);

	return {
		acceptablePrice,
		acceptablePriceDeltaBps,
		priceDelta,
	};
}

export function getDefaultAcceptablePriceImpactBps(p: {
	isIncrease: boolean;
	isLong: boolean;
	indexPrice: BigNumber;
	sizeDeltaUsd: BigNumber;
	priceImpactDeltaUsd: BigNumber;
	acceptablePriceImapctBuffer?: number;
}): BigNumber {
	const {
		indexPrice,
		sizeDeltaUsd,
		priceImpactDeltaUsd,
		acceptablePriceImapctBuffer = DEFAULT_ACCEPABLE_PRICE_IMPACT_BUFFER,
	} = p;

	if (priceImpactDeltaUsd.gt(0)) {
		return BigNumber.from(acceptablePriceImapctBuffer);
	}

	const baseAcceptablePriceValues = getAcceptablePriceByPriceImpact({
		isIncrease: p.isIncrease,
		isLong: p.isLong,
		indexPrice,
		sizeDeltaUsd,
		priceImpactDeltaUsd,
	});

	if (baseAcceptablePriceValues.acceptablePriceDeltaBps.lt(0)) {
		return baseAcceptablePriceValues.acceptablePriceDeltaBps.abs().add(acceptablePriceImapctBuffer);
	}

	return BigNumber.from(acceptablePriceImapctBuffer);
}
