import { useMemo } from 'react';
import { useTokenBalances } from './useTokenBalances';
import { useTokenRecentPrices } from './useTokenRecentPricesData';
import { GMXTokensData } from '../../../types';
import { getTokensMap, getV2Tokens } from '../../../tokens';

type TokensDataResult = {
	tokensData?: GMXTokensData;
	pricesUpdatedAt?: number;
};

export function useTokensDataRequest(chainId: number): TokensDataResult {
	const tokenConfigs = getTokensMap(chainId);
	const { balancesData } = useTokenBalances(chainId);
	const { pricesData, updatedAt: pricesUpdatedAt } = useTokenRecentPrices(chainId);

	return useMemo(() => {
		const tokenAddresses = getV2Tokens(chainId).map((token) => token.address);

		if (!pricesData) {
			return {
				tokensData: undefined,
				pricesUpdatedAt: undefined,
			};
		}

		return {
			tokensData: tokenAddresses.reduce((acc: GMXTokensData, tokenAddress) => {
				const prices = pricesData[tokenAddress];
				const balance = balancesData?.[tokenAddress];
				const tokenConfig = tokenConfigs[tokenAddress];

				if (!prices) {
					return acc;
				}

				acc[tokenAddress] = {
					...tokenConfig,
					prices,
					balance,
				};
				return acc;
			}, {} as GMXTokensData),
			pricesUpdatedAt,
		};
	}, [chainId, pricesData, pricesUpdatedAt, balancesData, tokenConfigs]);
}
