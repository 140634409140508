import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';
import { DraftFund, Fund, FundsSortState, FundsState, FundState } from '../../interfaces/fund';
import { DefundsEthClient } from '../../contracts/defunds-eth-client';
import {
	CONFIRMATION_COUNT,
	DEFAULT_ENABLED_SERVICES,
	ONE_DAY,
	ONE_WEEK,
} from '../../common/constants';
import * as API from './api';
import { Web3Provider } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { ModalType } from '../../interfaces/ui';
import { toast } from 'react-toastify';
import { allowedTokensToWhitelistMask } from '../whitelists/parser';
import { boolArrayToHex, percentsToEth } from '../../common/utils';
import { ModalData } from '../../components/modal/modal-provider';
import { Trading__factory } from '../../contracts/types';
import { helperToast } from '../../common/toast';
import { Token } from '../../interfaces/whitelists';
import { BigNumber } from 'ethers';

const defaultDraftFund: DraftFund = {
	name: '',
	description: '',
	subscriptionFee: 0.5,
	managementFee: 0,
	performanceFee: 15,
	reportingPeriod: ONE_WEEK,
	indent: 3 * ONE_DAY,
	hwm: false,
	tokenIds: [],
	enabledServices: DEFAULT_ENABLED_SERVICES,
	imageBase64: null,
	managerAddress: '',
	managerShare: 100,
	isPrivate: false,
	riskManagement: {
		managerTrailingStopEnabled: false,
		managerTrailingStopValue: '0',
		globalTrailingStopEnabled: false,
		globalTrailingStopValue: '0',
	}
};

const initialState: FundsState = {
	createModalOpen: false,
	createLoading: false,
	createSuccess: false,
	draft: defaultDraftFund,
	fundsById: {},
	fundLoadingById: {},
	applicationsById: {},
	cancelDepositLoadingById: {},
	cancelWithdrawLoadingById: {},
	setIsClosedLoadingById: {},
	claimFundFeesLoadingById: {},
	sortBy: {
		market: 'tvl',
		investment: 'tvl',
		myFunds: 'tvl',
	},
};

export const fundsSlice = createSlice({
	name: 'funds',
	initialState,
	reducers: {
		updateDraft: (state, action: PayloadAction<Partial<DraftFund>>) => {
			state.draft = {
				...state.draft,
				...action.payload,
			};
		},
		setCreateLoading: (state, action: PayloadAction<boolean>) => {
			state.createLoading = action.payload;
		},
		setCreateModalOpen: (state, action: PayloadAction<boolean>) => {
			state.createSuccess = false;
			state.createModalOpen = action.payload;
		},
		setFund: (state, action: PayloadAction<Fund>) => {
			state.fundsById[action.payload.id] = action.payload;
		},
		setLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
			state.fundLoadingById[action.payload.id] = action.payload.loading;
		},
		setCancelDepositLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
			state.cancelDepositLoadingById[action.payload.id] = action.payload.loading;
		},
		setCancelWithdrawLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
			state.cancelWithdrawLoadingById[action.payload.id] = action.payload.loading;
		},
		setIsClosedLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
			state.setIsClosedLoadingById[action.payload.id] = action.payload.loading;
		},
		setClaimFeesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
			state.claimFundFeesLoadingById[action.payload.id] = action.payload.loading;
		},
		setApplications: (state, action: PayloadAction<{ id: string; applications: [string, string, string] }>) => {
			const { applications, id } = action.payload;
			state.applicationsById[id] = applications;
		},
		setSort: (state, action: PayloadAction<{ key: keyof FundsSortState; value: string }>) => {
			const { key, value } = action.payload;
			state.sortBy[key] = value;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(create.pending, (state) => {
				state.createLoading = true;
			})
			.addCase(create.fulfilled, (state) => {
				state.createLoading = false;
				state.createSuccess = true;
				state.draft = defaultDraftFund;
			})
			.addCase(create.rejected, (state) => {
				state.createLoading = false;
			});
	},
});

export const create = createAsyncThunk<unknown, { ethClient: DefundsEthClient; account: string; tokens: Token[] }, { state: RootState }>(
	'fund/create',
	async ({ ethClient, account, tokens }, { getState, rejectWithValue }) => {
		const { draft } = getState().funds;

		try {
			const allowedTokens: string[] = [];
			for (const id of draft.tokenIds) {
				const token = tokens.find(t => t.id === id);
				if (token) {
					allowedTokens.push(token.address.toLowerCase());
				}
			}
			const { id } = await API.createFund({
				name: draft.name,
				description: draft.description,
				manager: account,
				chainId: ethClient.chainId,
				image: draft.imageBase64,
				isPrivate: draft.isPrivate,
			});
			const manager = draft.managerAddress || account;
			const managerShare = draft.managerAddress ? percentsToEth(draft.managerShare) : percentsToEth(100);
			const tx = await ethClient.create({
				id,
				fund: draft,
				investPeriod: draft.reportingPeriod,
				indent: draft.indent,
				whitelistMask: allowedTokensToWhitelistMask(tokens.filter(t => !t.isDeleted), allowedTokens),
				serviceMask: boolArrayToHex([draft.enabledServices.gmx, draft.enabledServices.aave]),
				managerShare,
				manager,
				isPrivate: draft.isPrivate,
			});
			return tx.wait(CONFIRMATION_COUNT);
		} catch (e) {
			const NAME_ALREADY_EXIST_ERROR = '1200';
			console.error(e);
			if ((e as any).message === NAME_ALREADY_EXIST_ERROR) {
				// TODO: i18n
				toast('Fund with such name already exists.', { type: 'error' });
			} else {
				toast('An error occurred.', { type: 'error' });
			}
			return rejectWithValue(null);
		}
	},
);

export const updateApplications = createAsyncThunk<unknown, { id: string; ethClient: DefundsEthClient }, { state: RootState }>(
	'fund/updateFund',
	async (
		{ id, ethClient },
		{ dispatch },
	) => {
		const applications = await ethClient.getPendingApplications(id, ethClient.account);
		dispatch(setApplications({ id, applications }));
	},
);

export const getFund = createAsyncThunk<unknown, { id: string; account?: string | null }, { state: RootState }>(
	'fund/updateFund',
	async (
		{ id, account },
		{ dispatch },
	) => {
		try {
			dispatch(setLoading({ id, loading: true }));
			const fund = await API.getFund(id, account);
			dispatch(setFund(fund));
		} finally {
			dispatch(setLoading({ id, loading: false }));
		}
	},
);

export const updateFund = createAsyncThunk<unknown,
	{
		provider: Web3Provider;
		id: string;
		image: string | null;
		description: string;
		account: string;
		openModal: ({ type }: ModalData) => void;
	},
	{ state: RootState }>(
	'fund/updateFund',
	async (
		{ provider, id, image, description, account, openModal },
		{ dispatch, rejectWithValue },
	) => {
		try {
			const signature = await provider.getSigner().signMessage('edit fund');
			if (signature) {
				const fund = await API.updateFund({ id, image, description, signature, account });
				dispatch(setFund(fund));
				openModal({ type: ModalType.Success });
			}
		} catch (e: any) {
			let message = `Error updating profile`;
			if (e.message.includes('unknown format')) {
				message += ': image parsing error. Please try another image.';
			}
			helperToast.error(message);
			return rejectWithValue(null);
		}
	},
);

export const cancelDeposit = createAsyncThunk<unknown,
	{ ethClient: DefundsEthClient, fundId: string; openModal: ({ type }: ModalData) => void; },
	{ state: RootState }>(
	'fund/cancelDeposit',
	async (
		{ ethClient, fundId, openModal },
		{ dispatch, rejectWithValue },
	) => {
		try {
			dispatch(setCancelDepositLoading({ id: fundId, loading: true }));
			const tx = await ethClient.cancelDeposit(fundId);
			await tx.wait(CONFIRMATION_COUNT);
			dispatch(updateApplications({ id: fundId, ethClient }));
			openModal({ type: ModalType.Success });
		} catch (e) {
			console.log(e);
			return rejectWithValue(null);
		} finally {
			dispatch(setCancelDepositLoading({ id: fundId, loading: false }));
		}
	},
);

export const cancelWithdrawal = createAsyncThunk<unknown,
	{ ethClient: DefundsEthClient, fundId: string; openModal: ({ type }: ModalData) => void; },
	{ state: RootState }>(
	'fund/cancelWithdrawal',
	async (
		{ ethClient, fundId, openModal },
		{ dispatch, rejectWithValue },
	) => {
		try {
			dispatch(setCancelWithdrawLoading({ id: fundId, loading: true }));
			const tx = await ethClient.cancelWithdraw(fundId);
			await tx.wait(CONFIRMATION_COUNT);
			dispatch(updateApplications({ id: fundId, ethClient }));
			openModal({ type: ModalType.Success });
		} catch (e) {
			console.log(e);
			return rejectWithValue(null);
		} finally {
			dispatch(setCancelWithdrawLoading({ id: fundId, loading: false }));
		}
	},
);

export const setIsClosed = createAsyncThunk<unknown,
	{ provider: Web3Provider; id: string; isClosed: boolean; closeModal: (id: string) => void; openModal: ({ type }: ModalData) => void; },
	{ state: RootState }>(
	'fund/setIsVisible',
	async (
		{ closeModal, provider, id, isClosed, openModal },
		{ dispatch, getState, rejectWithValue },
	) => {
		try {
			dispatch(setIsClosedLoading({ id, loading: true }));
			let tx: TransactionResponse;

			const fund = selectFund(getState(), { fundId: id });
			if (!fund) return;
			const tradeContract = Trading__factory.connect(fund.tradingAddress, provider.getSigner());
			if (isClosed) {
				tx = await tradeContract.setState(FundState.Opened);
			} else {
				tx = await tradeContract.setState(FundState.Closed);
			}
			await tx.wait(CONFIRMATION_COUNT);
			dispatch(setFund({ ...fund, show: isClosed }));
			closeModal(ModalType.CloseFundWarning);
			openModal({ type: ModalType.Success });
		} catch (e) {
			console.error(e);
			rejectWithValue(null);
		} finally {
			dispatch(setIsClosedLoading({ id, loading: false }));
		}
	},
);

export const {
	updateDraft,
	setCreateModalOpen,
	setFund,
	setLoading,
	setSort,
	setApplications,
	setCancelDepositLoading,
	setCancelWithdrawLoading,
	setIsClosedLoading,
	setClaimFeesLoading,
} = fundsSlice.actions;

function getFundId(_: RootState, { fundId }: { fundId: string | null }): string | null {
	return fundId;
}

function getLoadingById(map: Record<string, boolean>, id?: string | null): boolean {
	return id ? map[id] || false : false;
}

export const selectCreateModalOpen = (state: RootState): boolean => state.funds.createModalOpen;
export const selectCreateSuccess = (state: RootState): boolean => state.funds.createSuccess;
export const selectCreateLoading = (state: RootState): boolean => state.funds.createLoading;
export const selectDraftFund = (state: RootState): DraftFund => state.funds.draft;


const selectFundsById = (state: RootState): Record<string, Fund> => state.funds.fundsById;
const selectSortBy = (state: RootState): FundsSortState => state.funds.sortBy;
const selectFundIsLoadingById = (state: RootState): Record<string, boolean> => state.funds.fundLoadingById;
const selectCancelDepositIsLoadingById = (state: RootState): Record<string, boolean> => state.funds.cancelDepositLoadingById;
const selectCancelWithdrawIsLoadingById = (state: RootState): Record<string, boolean> => state.funds.cancelWithdrawLoadingById;
const selectIsClosedLoadingById = (state: RootState): Record<string, boolean> => state.funds.setIsClosedLoadingById;
const selectApplicationsById = (state: RootState): Record<string, [string, string, string]> => state.funds.applicationsById;

export const selectFund = createSelector(
	selectFundsById,
	getFundId,
	(funds, fundId) => fundId ? funds[fundId] || null : null,
);

export const selectSortByKey = createSelector(
	selectSortBy,
	(_: RootState, { key }: { key: keyof FundsSortState }) => key,
	(sortBy, key) => sortBy[key],
);

export const selectFundIsLoading = createSelector(selectFundIsLoadingById, getFundId, getLoadingById);
export const selectCancelDepositIsLoading = createSelector(selectCancelDepositIsLoadingById, getFundId, getLoadingById);
export const selectCancelWithdrawIsLoading = createSelector(selectCancelWithdrawIsLoadingById, getFundId, getLoadingById);
export const selectSetIsClosedLoading = createSelector(selectIsClosedLoadingById, getFundId, getLoadingById);

const defaultApplications: [string, string, string] = ['0', '0', '0'];
export const selectFundApplications = createSelector(
	selectApplicationsById,
	getFundId,
	(applications, fundId) => fundId ? applications[fundId] || defaultApplications : defaultApplications,
);

export const fundsReducer = fundsSlice.reducer;
