import { BigNumber } from 'ethers';

export type ActivityFilter = 'swap' | 'aave' | 'gmx' | 'reporting' | 'management' | 'whitelist' | 'riskManagement';


export enum ActivityType {
	Swap = 'swap',
	AaveSupply = 'aave-supply',
	AaveWithdraw = 'aave-withdraw',
	GmxV2Increase = 'gmx-v2-increase',
	GmxV2Decrease = 'gmx-v2-decrease',
	GmxV2Liquidation = 'gmx-v2-liquidation',
	Reporting = 'reporting',
	OwnershipTransferred = 'ownership-transferred',
	ManagerChanged = 'manager-changed',
	WhitelistUpdated = 'whitelist-updated',
	ServicesUpdated = 'services-updated',
	TrailingStopUpdated = 'trailing-stop-updated',
}

export interface BaseActivityItem {
	txHash: string;
	timestamp: number;
	chainId: number;
}

export interface SwapActivityItem extends BaseActivityItem {
	type: ActivityType.Swap;
	fromTokenAddress: string;
	toTokenAddress: string;
	fromAmount: BigNumber;
	toAmount: BigNumber;
}

export interface AAVESupplyActivityItem extends BaseActivityItem {
	type: ActivityType.AaveSupply;
	tokenAddress: string;
	amount: BigNumber;
}

export interface AAVEWithdrawActivityItem extends BaseActivityItem {
	type: ActivityType.AaveWithdraw;
	tokenAddress: string;
	amount: BigNumber;
}

export interface GMXBaseActivityItem extends BaseActivityItem {
	indexTokenAddress: string;
	collateralTokenAddress: string;
	isLong: boolean;
	executionPrice: BigNumber;
	executionFee: BigNumber;
	sizeDelta: BigNumber;
	collateralDelta: BigNumber;
	leverageDelta: string;
	prevSize: BigNumber;
	prevCollateral: BigNumber;
	prevLeverage: string;
}

interface GMXLiquidationActivityItem extends BaseActivityItem {
	indexTokenAddress: string;
	collateralTokenAddress: string;
	prevSize: BigNumber;
	isLong: boolean;
}

export interface GMXV2IncreaseActivityItem extends GMXBaseActivityItem {
	type: ActivityType.GmxV2Increase;
}

export interface GMXV2DecreaseActivityItem extends GMXBaseActivityItem {
	type: ActivityType.GmxV2Decrease;
	basePnlUsd: BigNumber;
	uncappedBasePnlUsd: BigNumber;
}

export interface GMXV2LiquidationActivityItem extends GMXLiquidationActivityItem {
	type: ActivityType.GmxV2Liquidation;
}

export interface ReportingActivityItem extends BaseActivityItem {
	type: ActivityType.Reporting;
	deposits: BigNumber;
	withdrawals: BigNumber;
	pf: BigNumber;
	prevAum: BigNumber;
	aum: BigNumber;
	executionFee: BigNumber;
	prevInvestors: number;
	investors: number;
}

export interface TrailingStopUpdatedActivityItem extends BaseActivityItem {
	type: ActivityType.TrailingStopUpdated;
	globalEnabled: boolean;
	managerEnabled: boolean;
	globalValue: BigNumber;
	managerValue: BigNumber;
}

export interface OwnerUpdatedActivityItem extends BaseActivityItem {
	type: ActivityType.OwnershipTransferred;
	newOwner: string;
}

export interface ManagerUpdatedActivityItem extends BaseActivityItem {
	type: ActivityType.ManagerChanged;
	newManager: string;
}

export interface ServicesUpdatedActivityItem extends BaseActivityItem {
	type: ActivityType.ServicesUpdated;
	newServiceMask: string;
}

export interface WhitelistUpdatedActivityItem extends BaseActivityItem {
	type: ActivityType.WhitelistUpdated;
	newWhitelist: string;
}

export type ActivityItem = SwapActivityItem
	| AAVESupplyActivityItem
	| AAVEWithdrawActivityItem
	| GMXV2IncreaseActivityItem
	| GMXV2DecreaseActivityItem
	| GMXV2LiquidationActivityItem
	| ReportingActivityItem
	| TrailingStopUpdatedActivityItem
	| OwnerUpdatedActivityItem
	| ManagerUpdatedActivityItem
	| ServicesUpdatedActivityItem
	| WhitelistUpdatedActivityItem;