import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';
import { OneInchState } from '../../interfaces/swap';

const initialState: OneInchState = {
	isLoading: false,
};

// TODO: get rid of this
export const swapSlice = createSlice({
	name: 'swap',
	initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

export const { setIsLoading } = swapSlice.actions;
export const selectIsLoading = (state: RootState): boolean => state.swap.isLoading;
export const swapReducer = swapSlice.reducer;
