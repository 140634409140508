import { Navigate, Outlet } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { useWallet } from '../features/wallet/use-wallet';

export function PrivateRoute(props: { redirectPath?: string }): JSX.Element | null {
	const { isActive } = useWeb3React();
	const { isRestoringWallet } = useWallet();
	if (isRestoringWallet) return null;
	if (!isActive) return <Navigate to={props.redirectPath || '/'} replace />;
	return <Outlet />;
}
