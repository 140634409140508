import { CheckImageContainer, CheckItem as Container, TextGray } from "../../components/modal/common.styled";
import { ReactComponent as CheckImage } from "../../asset/images/check.svg";
import { Trans, useTranslation } from "react-i18next";
import { useOptions } from '../../hooks/use-options';
import React, { ReactNode } from 'react';
import { EnabledServices, Token } from '../../interfaces/whitelists';
import { exists } from '../../common/assertions';
import { REPORT_THRESHOLD } from '../../common/constants';
import { TextBlue } from '../../components/UI/text-blue';
import { shortenAddress } from '../../common/utils';
import { RiskManagement } from '../../interfaces/fund';

export interface FundCheckListProps {
  tokens: Token[];
  HWM?: boolean;
  period?: number;
  indent?: number;
  enabledServices?: EnabledServices;
  isPrivate?: boolean;
  riskManagement?: RiskManagement | null;
  manager?: {
    share: number;
    address: string;
  };
}

export function FundCheckList({ tokens, HWM, period, indent, enabledServices, manager, isPrivate, riskManagement }: FundCheckListProps): JSX.Element {
  const { t } = useTranslation();
  const options = useOptions();
  const periodOption = options.fundPeriod.find(option => option.id === period);
  const indentOption = options.fundIndent.find(option => option.id === indent);
  const allowedServices = ['Swap', enabledServices?.['aave'] && 'AAVE', enabledServices?.['gmx'] && 'GMX'].filter(exists);

  return (
    <>
      {HWM && (
        <CheckItem>
          <Trans i18nKey="CreateFund.Modal.HVMDescription" components={{ TextBlue: <TextBlue capital /> }} />
        </CheckItem>
      )}
      {isPrivate && <CheckItem>{t('CreateFund.Modal.Private')}</CheckItem>}
      {manager && <CheckItem>
        <Trans
          i18nKey="CreateFund.Modal.ManagerInfo"
          components={{ TextBlue: <TextBlue /> }}
          values={{
            account: shortenAddress(manager.address),
            share: manager.share,
          }}
        />
      </CheckItem>}
      {(riskManagement?.globalTrailingStopEnabled || riskManagement?.managerTrailingStopEnabled) && <CheckItem>
        {(!riskManagement?.globalTrailingStopEnabled && riskManagement?.managerTrailingStopEnabled) && <Trans
          i18nKey="Fund.RiskManagement.ChecklistOnManager"
          values={{ manager: riskManagement.managerTrailingStopValue }}
          components={{ TextBlue: <TextBlue />, TextBlueCapital: <TextBlue capital /> }}
        />}
        {(riskManagement?.globalTrailingStopEnabled && !riskManagement?.managerTrailingStopEnabled) && <Trans
          i18nKey="Fund.RiskManagement.ChecklistOnDeposit"
          values={{ deposit: riskManagement.globalTrailingStopValue }}
          components={{ TextBlue: <TextBlue />, TextBlueCapital: <TextBlue capital /> }}
        />}
        {(riskManagement?.globalTrailingStopEnabled && riskManagement?.managerTrailingStopEnabled) && <Trans
          i18nKey="Fund.RiskManagement.ChecklistBoth"
          values={{ manager: riskManagement.managerTrailingStopValue, deposit: riskManagement.globalTrailingStopValue }}
          components={{ TextBlue: <TextBlue />, TextBlueCapital: <TextBlue capital /> }}
        />}
      </CheckItem>}
      {periodOption && indentOption && <CheckItem>
        <Trans
          i18nKey="CreateFund.Modal.PeriodDescription"
          period={periodOption?.label}
          indent={indentOption?.label}
          values={{ period: periodOption?.label, indent: indentOption?.label, threshold: REPORT_THRESHOLD }}
          components={{ TextBlue: <TextBlue />, TextBlueCapital: <TextBlue capital /> }}
        />
      </CheckItem>}
      {tokens.length > 0 && <CheckItem>
        <TextGray>{t('Fund.AvailableTokens')}:&nbsp;</TextGray>
        {tokens.map(token => token.name).join(', ')}
      </CheckItem>}
      {/* TODO: i18n */}
      <CheckItem>
        <TextGray>Allowed services: </TextGray>
        {allowedServices.join(', ')}
      </CheckItem>
    </>
  );
}

export function CheckItem({ children }: { children: ReactNode }): JSX.Element {
  return (
    <Container>
      <CheckImageContainer>
        <CheckImage />
      </CheckImageContainer>
      <div>
        {children}
      </div>
    </Container>
  );
}
