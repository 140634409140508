import { BigNumber } from 'ethers';
import { getCappedPositionImpactUsd } from '../fees';
import { convertToContractTokenPrices, convertToTokenAmount, convertToUsd, getMidPrice } from '../tokens';
import { ContractMarketPrices, Market, MarketInfo } from './types';
import { GMXToken, GMXTokenData, GMXTokensData } from '../../../types';
import { NATIVE_TOKEN_ADDRESS } from '../../../tokens';
import { PRECISION, USD_DECIMALS } from '../../../constants';
import { applyFactor, expandDecimals } from '../../../utils';
import { getByLowercasedKey } from '../positions';

export function getMarketFullName(p: { longToken: GMXToken; shortToken: GMXToken; indexToken: GMXToken; isSpotOnly: boolean }): string {
	const { indexToken, longToken, shortToken, isSpotOnly } = p;

	return `${getMarketIndexName({ indexToken, isSpotOnly })} [${getMarketPoolName({ longToken, shortToken })}]`;
}

export function getMarketIndexName(p: { indexToken: GMXToken; isSpotOnly: boolean }): string {
	const { indexToken, isSpotOnly } = p;

	if (isSpotOnly) {
		return `SWAP-ONLY`;
	}

	return `${indexToken.baseSymbol || indexToken.symbol}/USD`;
}

export function getMarketPoolName(p: { longToken: GMXToken; shortToken: GMXToken }): string {
	const { longToken, shortToken } = p;

	if (longToken.address === shortToken.address) {
		return longToken.symbol;
	}

	return `${longToken.symbol}-${shortToken.symbol}`;
}

export function getTokenPoolType(marketInfo: MarketInfo, tokenAddress: string): 'long' | 'short' | undefined {
	const { longToken, shortToken } = marketInfo;

	if (tokenAddress === longToken.address || (tokenAddress === NATIVE_TOKEN_ADDRESS && longToken.isWrapped)) {
		return 'long';
	}

	if (tokenAddress === shortToken.address || (tokenAddress === NATIVE_TOKEN_ADDRESS && shortToken.isWrapped)) {
		return 'short';
	}

	return undefined;
}

export function getOppositeCollateral(marketInfo: MarketInfo, tokenAddress: string): GMXTokenData | undefined {
	const poolType = getTokenPoolType(marketInfo, tokenAddress);

	if (poolType === 'long') {
		return marketInfo.shortToken;
	}

	if (poolType === 'short') {
		return marketInfo.longToken;
	}

	return undefined;
}

export function isMarketCollateral(marketInfo: MarketInfo, tokenAddress: string): boolean {
	return getTokenPoolType(marketInfo, tokenAddress) !== undefined;
}

export function isMarketAdaptiveFundingActive(marketInfo: MarketInfo): boolean {
	return marketInfo.fundingIncreaseFactorPerSecond.gt(0);
}

export function isMarketIndexToken(marketInfo: MarketInfo, tokenAddress: string): boolean {
	return Boolean(
		tokenAddress === marketInfo.indexToken.address ||
		(tokenAddress === NATIVE_TOKEN_ADDRESS && marketInfo.indexToken.isWrapped)
	);
}

export function getPoolUsdWithoutPnl(
	marketInfo: MarketInfo,
	isLong: boolean,
	priceType: 'minPrice' | 'maxPrice' | 'midPrice',
): BigNumber {
	const poolAmount = isLong ? marketInfo.longPoolAmount : marketInfo.shortPoolAmount;
	const token = isLong ? marketInfo.longToken : marketInfo.shortToken;

	let price: BigNumber;

	if (priceType === 'minPrice') {
		price = token.prices?.minPrice;
	} else if (priceType === 'maxPrice') {
		price = token.prices?.maxPrice;
	} else {
		price = getMidPrice(token.prices);
	}

	return convertToUsd(poolAmount, token.decimals, price)!;
}

export function getMaxOpenInterestUsd(marketInfo: MarketInfo, isLong: boolean): BigNumber {
	return isLong ? marketInfo.maxOpenInterestLong : marketInfo.maxOpenInterestShort;
}

export function getOpenInterestUsd(marketInfo: MarketInfo, isLong: boolean): BigNumber {
	return isLong ? marketInfo.longInterestUsd : marketInfo.shortInterestUsd;
}

export function getReservedUsd(marketInfo: MarketInfo, isLong: boolean): BigNumber {
	const { indexToken } = marketInfo;

	if (isLong) {
		return convertToUsd(marketInfo.longInterestInTokens, marketInfo.indexToken.decimals, indexToken.prices.maxPrice)!;
	} else {
		return marketInfo.shortInterestUsd;
	}
}

export function getMaxReservedUsd(marketInfo: MarketInfo, isLong: boolean): BigNumber {
	const poolUsd = getPoolUsdWithoutPnl(marketInfo, isLong, 'minPrice');

	let reserveFactor = isLong ? marketInfo.reserveFactorLong : marketInfo.reserveFactorShort;

	const openInterestReserveFactor = isLong
		? marketInfo.openInterestReserveFactorLong
		: marketInfo.openInterestReserveFactorShort;

	if (openInterestReserveFactor.lt(reserveFactor)) {
		reserveFactor = openInterestReserveFactor;
	}

	return poolUsd.mul(reserveFactor).div(PRECISION);
}

export function getAvailableUsdLiquidityForPosition(marketInfo: MarketInfo, isLong: boolean): BigNumber {
	if (marketInfo.isSpotOnly) {
		return BigNumber.from(0);
	}

	const maxReservedUsd = getMaxReservedUsd(marketInfo, isLong);
	const reservedUsd = getReservedUsd(marketInfo, isLong);

	const maxOpenInterest = getMaxOpenInterestUsd(marketInfo, isLong);
	const currentOpenInterest = getOpenInterestUsd(marketInfo, isLong);

	const availableLiquidityBasedOnMaxReserve = maxReservedUsd.sub(reservedUsd);
	const availableLiquidityBasedOnMaxOpenInterest = maxOpenInterest.sub(currentOpenInterest);

	const result = availableLiquidityBasedOnMaxReserve.lt(availableLiquidityBasedOnMaxOpenInterest)
		? availableLiquidityBasedOnMaxReserve
		: availableLiquidityBasedOnMaxOpenInterest;

	return result.lt(0) ? BigNumber.from(0) : result;
}

export function getAvailableUsdLiquidityForCollateral(marketInfo: MarketInfo, isLong: boolean): BigNumber {
	const poolUsd = getPoolUsdWithoutPnl(marketInfo, isLong, 'minPrice');

	if (marketInfo.isSpotOnly) {
		return poolUsd;
	}

	const reservedUsd = getReservedUsd(marketInfo, isLong);
	const maxReserveFactor = isLong ? marketInfo.reserveFactorLong : marketInfo.reserveFactorShort;

	if (maxReserveFactor.eq(0)) {
		return BigNumber.from(0);
	}

	const minPoolUsd = reservedUsd.mul(PRECISION).div(maxReserveFactor);

	const liqudiity = poolUsd.sub(minPoolUsd);

	return liqudiity;
}

export function getCappedPoolPnl(p: {
	marketInfo: MarketInfo;
	poolUsd: BigNumber;
	isLong: boolean;
	maximize: boolean;
}): BigNumber {
	const { marketInfo, poolUsd, isLong, maximize } = p;

	let poolPnl: BigNumber;

	if (isLong) {
		poolPnl = maximize ? marketInfo.pnlLongMax : marketInfo.pnlLongMin;
	} else {
		poolPnl = maximize ? marketInfo.pnlShortMax : marketInfo.pnlShortMin;
	}

	if (poolPnl.lt(0)) {
		return poolPnl;
	}

	const maxPnlFactor: BigNumber = isLong
		? marketInfo.maxPnlFactorForTradersLong
		: marketInfo.maxPnlFactorForTradersShort;

	const maxPnl = applyFactor(poolUsd, maxPnlFactor);

	return poolPnl.gt(maxPnl) ? maxPnl : poolPnl;
}

export function getMostLiquidMarketForPosition(
	marketsInfo: MarketInfo[],
	indexTokenAddress: string,
	collateralTokenAddress: string | undefined,
	isLong: boolean,
	isIncrease: boolean,
): MarketInfo | undefined {
	let bestMarket: MarketInfo | undefined;
	let bestLiquidity: BigNumber | undefined;

	for (const marketInfo of marketsInfo) {
		if (marketInfo.isSpotOnly) {
			continue;
		}

		let isCandidate = isMarketIndexToken(marketInfo, indexTokenAddress);

		if (collateralTokenAddress) {
			isCandidate = isMarketCollateral(marketInfo, collateralTokenAddress);
		}

		if (isCandidate) {
			const liquidity = getAvailableUsdLiquidityForPosition(marketInfo, isLong);

			if (liquidity && liquidity.gt(bestLiquidity || 0)) {
				bestMarket = marketInfo;
				bestLiquidity = liquidity;
			}
		}
	}

	return bestMarket;
}

export function getMinPriceImpactMarket(
	marketsInfo: MarketInfo[],
	indexTokenAddress: string,
	isLong: boolean,
	isIncrease: boolean,
	sizeDeltaUsd: BigNumber,
): { bestMarket: MarketInfo | undefined, bestImpactDeltaUsd: BigNumber | undefined } {
	let bestMarket: MarketInfo | undefined;
	// minimize negative impact
	let bestImpactDeltaUsd: BigNumber | undefined;

	for (const marketInfo of marketsInfo) {
		const liquidity = getAvailableUsdLiquidityForPosition(marketInfo, isLong);

		if (isMarketIndexToken(marketInfo, indexTokenAddress) && liquidity.gt(sizeDeltaUsd)) {
			const priceImpactDeltaUsd = getCappedPositionImpactUsd(marketInfo, sizeDeltaUsd, isLong);

			if (!bestImpactDeltaUsd || priceImpactDeltaUsd.gt(bestImpactDeltaUsd)) {
				bestMarket = marketInfo;
				bestImpactDeltaUsd = priceImpactDeltaUsd;
			}
		}
	}

	return {
		bestMarket,
		bestImpactDeltaUsd,
	};
}

export function getMaxPoolAmountForDeposit(marketInfo: MarketInfo, isLong: boolean): BigNumber {
	const maxAmountForDeposit = isLong ? marketInfo.maxLongPoolAmountForDeposit : marketInfo.maxShortPoolAmountForDeposit;
	const maxAmountForSwap = isLong ? marketInfo.maxLongPoolAmount : marketInfo.maxShortPoolAmount;

	return maxAmountForDeposit.lt(maxAmountForSwap) ? maxAmountForDeposit : maxAmountForSwap;
}

export function usdToMarketTokenAmount(marketInfo: MarketInfo, marketToken: GMXTokenData, usdValue: BigNumber): BigNumber {
	const supply = marketToken.totalSupply!;
	const poolValue = marketInfo.poolValueMax!;
	// if the supply and poolValue is zero, use 1 USD as the token price
	if (supply.eq(0) && poolValue.eq(0)) {
		return convertToTokenAmount(usdValue, marketToken.decimals, expandDecimals(1, USD_DECIMALS))!;
	}

	// if the supply is zero and the poolValue is more than zero,
	// then include the poolValue for the amount of tokens minted so that
	// the market token price after mint would be 1 USD
	if (supply.eq(0) && poolValue.gt(0)) {
		return convertToTokenAmount(usdValue.add(poolValue), marketToken.decimals, expandDecimals(1, USD_DECIMALS))!;
	}

	if (poolValue.eq(0)) {
		return BigNumber.from(0);
	}

	return supply.mul(usdValue).div(poolValue);
}

export function marketTokenAmountToUsd(marketInfo: MarketInfo, marketToken: GMXTokenData, amount: BigNumber): BigNumber {
	const supply = marketToken.totalSupply!;
	const poolValue = marketInfo.poolValueMax!;

	const price = supply.eq(0)
		? expandDecimals(1, USD_DECIMALS)
		: poolValue.mul(expandDecimals(1, marketToken.decimals)).div(supply);

	return convertToUsd(amount, marketToken.decimals, price)!;
}

export function getContractMarketPrices(tokensData: GMXTokensData, market: Market): ContractMarketPrices | undefined {
	const indexToken = getByLowercasedKey(tokensData, market.indexTokenAddress);
	const longToken = getByLowercasedKey(tokensData, market.longTokenAddress);
	const shortToken = getByLowercasedKey(tokensData, market.shortTokenAddress);

	if (!indexToken || !longToken || !shortToken) {
		return undefined;
	}

	return {
		indexTokenPrice: convertToContractTokenPrices(indexToken.prices, indexToken.decimals),
		longTokenPrice: convertToContractTokenPrices(longToken.prices, longToken.decimals),
		shortTokenPrice: convertToContractTokenPrices(shortToken.prices, shortToken.decimals),
	};
}
