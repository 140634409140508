import { BigNumber } from 'ethers';
import { GMXTokenPricesData } from '../../../types';
import { useOracleKeeperFetcher } from './useOracleKeeperFetcher';
import { parseContractPrice } from './utils';
import { getToken, getWrappedToken, NATIVE_TOKEN_ADDRESS } from '../../../tokens';
import { useQuery } from '@tanstack/react-query';

type TokenPricesDataResult = {
	pricesData?: GMXTokenPricesData;
	updatedAt?: number;
};

export function useTokenRecentPrices(chainId: number): TokenPricesDataResult {
	const oracleKeeperFetcher = useOracleKeeperFetcher(chainId);

	const { data } = useQuery(
		[chainId, oracleKeeperFetcher.oracleKeeperUrl, 'useTokenRecentPrices'],
		() => oracleKeeperFetcher.fetchTickers().then((priceItems) => {
			const result: GMXTokenPricesData = {};

			priceItems.forEach((priceItem) => {
				let tokenConfig: any;

				try {
					tokenConfig = getToken(chainId, priceItem.tokenAddress);
				} catch (e) {
					// ignore unknown token errors

					return;
				}

				result[tokenConfig.address] = {
					minPrice: parseContractPrice(BigNumber.from(priceItem.minPrice), tokenConfig.decimals),
					maxPrice: parseContractPrice(BigNumber.from(priceItem.maxPrice), tokenConfig.decimals),
				};
			});

			const wrappedToken = getWrappedToken(chainId);

			if (result[wrappedToken.address] && !result[NATIVE_TOKEN_ADDRESS]) {
				result[NATIVE_TOKEN_ADDRESS] = result[wrappedToken.address];
			}

			return {
				pricesData: result,
				updatedAt: Date.now(),
			};
		}), {
		refetchInterval: 10000,
	});

	return {
		pricesData: data?.pricesData,
		updatedAt: data?.updatedAt,
	};
}
