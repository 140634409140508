import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { ensureNotNull } from '../../common/assertions';
import { ModalType } from '../../interfaces/ui';

export interface Modal extends ModalData {
	id: string;
}
export interface ModalData {
	id?: string;
	title?: string;
	maxWidth?: string;
	titleDotColor?: string;
	content?: ReactNode;
	caption?: ReactNode;
	onContinue?: () => Promise<void>;
	type?: ModalType; // TODO: Extend with other common modal types
}

interface ModalContextProps {
	openModal: (data: ModalData) => void;
	closeModal: (id: string) => void;
	closeCurrentModal: () => void;
	currentModal: Modal | null;
}

let modalId = 0;
function getNextId(): string {
	return String(++modalId);
}

const ModalContext = createContext<ModalContextProps | null>(null);

export function ModalProvider({ children }: { children: ReactNode }): JSX.Element {
	const [modalQueue, setModalQueue] = useState<Modal[]>([]);

	const openModal = useCallback((data: ModalData) => {
		setModalQueue((prevQueue) => [...prevQueue, { ...data, id: data.id ?? getNextId() }]);
	}, []);

	const closeModal = useCallback((id: string) => {
		setModalQueue((prevQueue) => prevQueue.filter((modal) => modal.id !== id));
	}, []);

	const closeCurrentModal = useCallback(() => {
		setModalQueue((prevQueue) => {
			const newQueue = [...prevQueue];
			newQueue.pop();
			return newQueue;
		});
	}, []);

	return (
		<ModalContext.Provider value={{ openModal, closeModal, closeCurrentModal, currentModal: modalQueue[0] }}>
			{children}
		</ModalContext.Provider>
	);
}

export function useModalContext(): ModalContextProps {
	return ensureNotNull(useContext(ModalContext));
}
