import EventEmitter from '../abis/EventEmitter.json';
import {
	isDecreaseOrderType,
	isIncreaseOrderType,
	isLiquidationOrderType,
	isMarketOrderType,
} from './lib/orders';
import { getByLowercasedKey, getPositionKey } from './lib/positions';
import { getSwapPathOutputAddresses } from './lib/trade';
import { BigNumber, ethers } from 'ethers';
import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import {
	EventLogData,
	EventTxnParams,
	OrderCreatedEventData,
	OrderStatuses,
	PendingOrderData,
	PendingPositionUpdate,
	PendingPositionsUpdates,
	PositionDecreaseEvent,
	PositionIncreaseEvent,
	SyntheticsEventsContextType,
} from './types/events';
import { getByKey, setByKey, updateByKey } from './lib/positions';
import { formatTokenAmount } from '../../../../common/utils';
import { formatUsd, parseEventLogData } from '../utils';
import { gmxContractAddresses } from '../constants';
import { useWsProvider } from '../../../../hooks/use-ws-provider';
import { useWeb3React } from '@web3-react/core';
import { useMarketsInfo } from './lib/markets';
import { getToken, getWrappedToken } from '../tokens';
import { usePageVisibility } from '../../../../hooks/use-page-visibility';
import { ChainId } from '../../../../common/constants';
import { toast } from 'react-toastify';
import { ensureNotNull } from '../../../../common/assertions';
import { useTradeContext } from '../../trade-provider';
import { OrdersStatusNotificiation } from '../components/order-status-notification';
import { useTokensData } from './lib/tokens';

export const DEPOSIT_CREATED_HASH = ethers.utils.id('DepositCreated');
export const DEPOSIT_EXECUTED_HASH = ethers.utils.id('DepositExecuted');
export const DEPOSIT_CANCELLED_HASH = ethers.utils.id('DepositCancelled');

export const WITHDRAWAL_CREATED_HASH = ethers.utils.id('WithdrawalCreated');
export const WITHDRAWAL_EXECUTED_HASH = ethers.utils.id('WithdrawalExecuted');
export const WITHDRAWAL_CANCELLED_HASH = ethers.utils.id('WithdrawalCancelled');

export const ORDER_CREATED_HASH = ethers.utils.id('OrderCreated');
export const ORDER_EXECUTED_HASH = ethers.utils.id('OrderExecuted');
export const ORDER_CANCELLED_HASH = ethers.utils.id('OrderCancelled');

export const POSITION_INCREASE_HASH = ethers.utils.id('PositionIncrease');
export const POSITION_DECREASE_HASH = ethers.utils.id('PositionDecrease');

export const SyntheticsEventsContext = createContext<SyntheticsEventsContextType | null>(null);

export function useSyntheticsEvents(): SyntheticsEventsContextType {
	return ensureNotNull(useContext(SyntheticsEventsContext));
}

export function SyntheticsEventsProvider({ children }: { children: ReactNode }): JSX.Element {
	const { chainId = ChainId.Arbitrum } = useWeb3React();
	const { tradingContractAddress } = useTradeContext();
	const wsProvider = useWsProvider();

	const isPageVisible = usePageVisibility();

	const { tokensData } = useTokensData(chainId);
	const { marketsInfoData } = useMarketsInfo(chainId);

	const [orderStatuses, setOrderStatuses] = useState<OrderStatuses>({});
	const [pendingPositionsUpdates, setPendingPositionsUpdates] = useState<PendingPositionsUpdates>({});
	const [positionIncreaseEvents, setPositionIncreaseEvents] = useState<PositionIncreaseEvent[]>([]);
	const [positionDecreaseEvents, setPositionDecreaseEvents] = useState<PositionDecreaseEvent[]>([]);

	const eventLogHandlers = useRef<Record<string, (eventData: EventLogData, txnParams: EventTxnParams) => void>>({});

	// use ref to avoid re-subscribing on state changes
	eventLogHandlers.current = {
		OrderCreated: (eventData: EventLogData, txnParams: EventTxnParams) => {
			const data: OrderCreatedEventData = {
				account: eventData.addressItems.items.account,
				receiver: eventData.addressItems.items.receiver,
				callbackContract: eventData.addressItems.items.callbackContract,
				marketAddress: eventData.addressItems.items.market,
				initialCollateralTokenAddress: eventData.addressItems.items.initialCollateralToken,
				swapPath: eventData.addressItems.arrayItems.swapPath,
				sizeDeltaUsd: eventData.uintItems.items.sizeDeltaUsd,
				initialCollateralDeltaAmount: eventData.uintItems.items.initialCollateralDeltaAmount,
				contractTriggerPrice: eventData.uintItems.items.triggerPrice,
				contractAcceptablePrice: eventData.uintItems.items.acceptablePrice,
				executionFee: eventData.uintItems.items.executionFee,
				callbackGasLimit: eventData.uintItems.items.callbackGasLimit,
				minOutputAmount: eventData.uintItems.items.minOutputAmount,
				updatedAtBlock: eventData.uintItems.items.updatedAtBlock,
				orderType: Number(eventData.uintItems.items.orderType),
				isLong: eventData.boolItems.items.isLong,
				shouldUnwrapNativeToken: eventData.boolItems.items.shouldUnwrapNativeToken,
				isFrozen: eventData.boolItems.items.isFrozen,
				key: eventData.bytes32Items.items.key,
			};

			if (data.account.toLowerCase() !== tradingContractAddress.toLowerCase()) {
				return;
			}

			setOrderStatuses((old) =>
				setByKey(old, data.key, {
					key: data.key,
					data,
					createdTxnHash: txnParams.transactionHash,
					createdAt: Date.now(),
				}),
			);
		},

		OrderExecuted: (eventData: EventLogData, txnParams: EventTxnParams) => {
			const key = eventData.bytes32Items.items.key;
			if (getByLowercasedKey(orderStatuses, key)) {
				setOrderStatuses((old) => updateByKey(old, key, { executedTxnHash: txnParams.transactionHash }));
			}
		},

		OrderCancelled: (eventData: EventLogData, txnParams: EventTxnParams) => {
			const key = eventData.bytes32Items.items.key;

			if (getByLowercasedKey(orderStatuses, key)) {
				setOrderStatuses((old) => updateByKey(old, key, { cancelledTxnHash: txnParams.transactionHash }));
			}

			const order = orderStatuses[key]?.data;

			// If pending user order is cancelled, reset the pending position state
			if (order && marketsInfoData) {
				const wrappedToken = getWrappedToken(chainId);

				let pendingPositionKey: string | undefined;

				// For increase orders, we need to check the target collateral token
				if (isIncreaseOrderType(order.orderType)) {
					const { outTokenAddress } = getSwapPathOutputAddresses({
						marketsInfoData: marketsInfoData,
						initialCollateralAddress: order.initialCollateralTokenAddress,
						swapPath: order.swapPath,
						wrappedNativeTokenAddress: wrappedToken.address,
						shouldUnwrapNativeToken: order.shouldUnwrapNativeToken,
					});

					if (outTokenAddress) {
						pendingPositionKey = getPositionKey(order.account, order.marketAddress, outTokenAddress, order.isLong);
					}
				} else if (isDecreaseOrderType(order.orderType)) {
					pendingPositionKey = getPositionKey(
						order.account,
						order.marketAddress,
						order.initialCollateralTokenAddress,
						order.isLong,
					);
				}

				if (pendingPositionKey) {
					setPendingPositionsUpdates((old) => setByKey(old, pendingPositionKey!, undefined));
				}
			}
		},

		PositionIncrease: (eventData: EventLogData, txnParams: EventTxnParams) => {
			const data: PositionIncreaseEvent = {
				positionKey: getPositionKey(
					eventData.addressItems.items.account,
					eventData.addressItems.items.market,
					eventData.addressItems.items.collateralToken,
					eventData.boolItems.items.isLong,
				)!,
				contractPositionKey: eventData.bytes32Items.items.positionKey,
				account: eventData.addressItems.items.account,
				marketAddress: eventData.addressItems.items.market,
				collateralTokenAddress: eventData.addressItems.items.collateralToken,
				sizeInUsd: eventData.uintItems.items.sizeInUsd,
				sizeInTokens: eventData.uintItems.items.sizeInTokens,
				collateralAmount: eventData.uintItems.items.collateralAmount,
				borrowingFactor: eventData.uintItems.items.borrowingFactor,
				executionPrice: eventData.uintItems.items.executionPrice,
				sizeDeltaUsd: eventData.uintItems.items.sizeDeltaUsd,
				sizeDeltaInTokens: eventData.uintItems.items.sizeDeltaInTokens,
				longTokenFundingAmountPerSize: eventData.intItems.items.longTokenFundingAmountPerSize,
				shortTokenFundingAmountPerSize: eventData.intItems.items.shortTokenFundingAmountPerSize,
				collateralDeltaAmount: eventData.intItems.items.collateralDeltaAmount,
				isLong: eventData.boolItems.items.isLong,
				increasedAtBlock: BigNumber.from(txnParams.blockNumber),
				orderType: Number(eventData.uintItems.items.orderType),
				orderKey: eventData.bytes32Items.items.orderKey,
			};
			if (data.account.toLowerCase() !== tradingContractAddress.toLowerCase()) {
				return;
			}

			setPositionIncreaseEvents((old) => [...old, data]);

			// If this is a limit order, or the order status is not received previosly, notify the user
			if (!isMarketOrderType(data.orderType) || !orderStatuses[data.orderKey]) {
				let text = '';

				const marketInfo = getByKey(marketsInfoData, data.marketAddress);
				const indexToken = marketInfo?.indexToken;
				const collateralToken = getToken(chainId, data.collateralTokenAddress);

				if (!marketInfo || !indexToken || !collateralToken) {
					return;
				}

				const longShortText = data.isLong ? `Long` : `Short`;
				const positionText = `${indexToken?.symbol} ${longShortText}`;

				if (data.sizeDeltaUsd.eq(0)) {
					text = `Deposited ${formatTokenAmount(
						data.collateralDeltaAmount.toString(),
						collateralToken.decimals,
					)} into ${positionText}`;
				} else {
					text = `Increased ${positionText}, +${formatUsd(data.sizeDeltaUsd)}`;
				}

				toast(text);
			}
		},

		PositionDecrease: (eventData: EventLogData, txnParams: EventTxnParams) => {
			const data: PositionDecreaseEvent = {
				positionKey: getPositionKey(
					eventData.addressItems.items.account,
					eventData.addressItems.items.market,
					eventData.addressItems.items.collateralToken,
					eventData.boolItems.items.isLong,
				)!,
				account: eventData.addressItems.items.account,
				marketAddress: eventData.addressItems.items.market,
				collateralTokenAddress: eventData.addressItems.items.collateralToken,
				sizeInUsd: eventData.uintItems.items.sizeInUsd,
				sizeInTokens: eventData.uintItems.items.sizeInTokens,
				sizeDeltaUsd: eventData.uintItems.items.sizeDeltaUsd,
				sizeDeltaInTokens: eventData.uintItems.items.sizeDeltaInTokens,
				collateralAmount: eventData.uintItems.items.collateralAmount,
				collateralDeltaAmount: eventData.intItems.items.collateralDeltaAmount,
				borrowingFactor: eventData.uintItems.items.borrowingFactor,
				longTokenFundingAmountPerSize: eventData.intItems.items.longTokenFundingAmountPerSize,
				shortTokenFundingAmountPerSize: eventData.intItems.items.shortTokenFundingAmountPerSize,
				pnlUsd: eventData.intItems.items.pnlUsd,
				isLong: eventData.boolItems.items.isLong,
				contractPositionKey: eventData.bytes32Items.items.positionKey,
				decreasedAtBlock: BigNumber.from(txnParams.blockNumber),
				orderType: Number(eventData.uintItems.items.orderType),
				orderKey: eventData.bytes32Items.items.orderKey,
			};

			if (data.account.toLowerCase() !== tradingContractAddress.toLowerCase()) {
				return;
			}

			setPositionDecreaseEvents((old) => [...old, data]);

			// If this is a trigger or liquidation order, or the order status is not received previosly, notify the user
			if (!isMarketOrderType(data.orderType) || !orderStatuses[data.orderKey]) {
				let text = '';

				const marketInfo = getByKey(marketsInfoData, data.marketAddress);
				const indexToken = marketInfo?.indexToken;
				const collateralToken = getToken(chainId, data.collateralTokenAddress);

				if (!marketInfo || !indexToken || !collateralToken) {
					return;
				}

				const longShortText = data.isLong ? `Long` : `Short`;
				const positionText = `${indexToken?.symbol} ${longShortText}`;

				if (data.sizeDeltaUsd.eq(0)) {
					text = `Withdrew ${formatTokenAmount(
						data.collateralDeltaAmount.toString(),
						collateralToken.decimals,
					)} from ${positionText}`;
				} else {
					const orderTypeLabel = isLiquidationOrderType(data.orderType) ? `Liquidated` : `Decreased`;
					text = `${orderTypeLabel} ${positionText}, -${formatUsd(data.sizeDeltaUsd)}`;
				}

				if (isLiquidationOrderType(data.orderType)) {
					toast.error(text);
				} else {
					toast(text);
				}
			}
		},
	};
	useEffect(
		function subscribe() {
			if (!isPageVisible || !wsProvider || !tradingContractAddress || !gmxContractAddresses[chainId]) {
				return;
			}

			const addressHash = ethers.utils.defaultAbiCoder.encode(['address'], [tradingContractAddress]);

			const eventEmitter = new ethers.Contract(gmxContractAddresses[chainId].EventEmitter, EventEmitter.abi, wsProvider);
			const EVENT_LOG_TOPIC = eventEmitter.interface.getEventTopic('EventLog');
			const EVENT_LOG1_TOPIC = eventEmitter.interface.getEventTopic('EventLog1');
			const EVENT_LOG2_TOPIC = eventEmitter.interface.getEventTopic('EventLog2');

			function handleEventLog(sender: unknown, eventName: string, eventNameHash: string, eventData: string, txnOpts: EventTxnParams): void {
				eventLogHandlers.current[eventName]?.(parseEventLogData(eventData), txnOpts);
			}

			function handleEventLog1(sender: unknown, eventName: string, eventNameHash: string, topic1: string, eventData: string, txnOpts: EventTxnParams): void {
				eventLogHandlers.current[eventName]?.(parseEventLogData(eventData), txnOpts);
			}

			function handleEventLog2(sender: unknown, eventName: string, eventNameHash: string, topic1: string, topic2: string, eventData: string, txnOpts: EventTxnParams): void {
				eventLogHandlers.current[eventName]?.(parseEventLogData(eventData), txnOpts);
			}

			function handleCommonLog(e: any): void {
				const txnOpts: EventTxnParams = {
					transactionHash: e.transactionHash,
					blockNumber: e.blockNumber,
				};

				try {
					const parsed = eventEmitter.interface.parseLog(e);

					if (parsed.name === 'EventLog') {
						handleEventLog(parsed.args[0], parsed.args[1], parsed.args[2], parsed.args[3], txnOpts);
					} else if (parsed.name === 'EventLog1') {
						handleEventLog1(parsed.args[0], parsed.args[1], parsed.args[2], parsed.args[3], parsed.args[4], txnOpts);
					} else if (parsed.name === 'EventLog2') {
						handleEventLog2(
							parsed.args[0],
							parsed.args[1],
							parsed.args[2],
							parsed.args[3],
							parsed.args[4],
							parsed.args[5],
							txnOpts,
						);
					}
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error('error parsing event', e);
				}
			}

			const filters = [
				// DEPOSITS AND WITHDRAWALS
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [EVENT_LOG2_TOPIC, [DEPOSIT_CREATED_HASH, WITHDRAWAL_CREATED_HASH], null, addressHash],
				},
				// NEW CONTRACTS
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [EVENT_LOG2_TOPIC, [DEPOSIT_CREATED_HASH, WITHDRAWAL_CREATED_HASH], null, addressHash],
				},
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [
						EVENT_LOG_TOPIC,
						[DEPOSIT_CANCELLED_HASH, DEPOSIT_EXECUTED_HASH, WITHDRAWAL_CANCELLED_HASH, WITHDRAWAL_EXECUTED_HASH],
					],
				},
				// NEW CONTRACTS
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [
						EVENT_LOG2_TOPIC,
						[DEPOSIT_CANCELLED_HASH, DEPOSIT_EXECUTED_HASH, WITHDRAWAL_CANCELLED_HASH, WITHDRAWAL_EXECUTED_HASH],
						null,
						addressHash,
					],
				},
				// ORDERS
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [EVENT_LOG2_TOPIC, ORDER_CREATED_HASH, null, addressHash],
				},
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [EVENT_LOG1_TOPIC, [ORDER_CANCELLED_HASH, ORDER_EXECUTED_HASH]],
				},
				// NEW CONTRACTS
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [EVENT_LOG2_TOPIC, [ORDER_CANCELLED_HASH, ORDER_EXECUTED_HASH], null, addressHash],
				},
				// POSITIONS
				{
					address: gmxContractAddresses[chainId].EventEmitter,
					topics: [EVENT_LOG1_TOPIC, [POSITION_INCREASE_HASH, POSITION_DECREASE_HASH], addressHash],
				},
			];

			filters.forEach((filter) => {
				wsProvider.on(filter, handleCommonLog);
			});

			return () => {
				filters.forEach((filter) => {
					wsProvider.off(filter, handleCommonLog);
				});
			};
		},
		[chainId, tradingContractAddress, isPageVisible, wsProvider],
	);
	const [trackedToasts, setTrackedToasts] = useState<{ id: number; data: PendingOrderData }[]>([]);

	const setOrderStatusViewed = useCallback(function setOrderStatusViewed(key: string): void {
		setOrderStatuses((old) => updateByKey(old, key, { isViewed: true }));
	}, []);

	const renderToast = useCallback((id: number, data: PendingOrderData) => {
		return <OrdersStatusNotificiation
			chainId={chainId}
			tokensData={tokensData}
			pendingOrderData={data}
			marketsInfoData={marketsInfoData}
			orderStatuses={orderStatuses}
			setOrderStatusViewed={setOrderStatusViewed}
			toastTimestamp={id}
		/>;
	}, [chainId, marketsInfoData, orderStatuses, setOrderStatusViewed, tokensData]);

	useEffect(() => {
		for (const trackedToast of trackedToasts) {
			toast.update(trackedToast.id, { render: renderToast(trackedToast.id, trackedToast.data) })
		}
	}, [orderStatuses, setOrderStatusViewed, renderToast, trackedToasts]);

	const contextState = useMemo(() => {
		return {
			orderStatuses,
			pendingPositionsUpdates,
			positionIncreaseEvents,
			positionDecreaseEvents,
			setPendingOrder: (data: PendingOrderData) => {
				const toastId = Date.now();
				setTrackedToasts((old) => [...old, { id: toastId, data }]);
				toast(renderToast(toastId, data), { autoClose: false, toastId });
			},
			async setPendingPosition(update: PendingPositionUpdate) {
				setPendingPositionsUpdates((old) => setByKey(old, update.positionKey, update));
			},

			setOrderStatusViewed: setOrderStatusViewed,
		};
	}, [orderStatuses, pendingPositionsUpdates, positionDecreaseEvents, positionIncreaseEvents, renderToast, setOrderStatusViewed]);

	return <SyntheticsEventsContext.Provider value={contextState}>{children}</SyntheticsEventsContext.Provider>;
}

