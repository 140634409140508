import { BigNumber } from '@ethersproject/bignumber';

export interface GMXToken {
	name: string;
	symbol: string;
	assetSymbol?: string;
	baseSymbol?: string;
	decimals: number;
	address: string;
	priceDecimals?: number;
	wrappedAddress?: string;
	coingeckoUrl?: string;
	explorerUrl?: string;
	reservesUrl?: string;
	imageUrl?: string;

	isUsdg?: boolean;
	isNative?: boolean;
	isWrapped?: boolean;
	isShortable?: boolean;
	isStable?: boolean;
	isSynthetic?: boolean;
	isTempHidden?: boolean;
	isChartDisabled?: boolean;
	isV1Available?: boolean;
	isPlatformToken?: boolean;
}

export interface GMXTokenInfo extends GMXToken {
	hasMaxAvailableLong?: boolean;
	hasMaxAvailableShort?: boolean;

	usdgAmount?: BigNumber;
	maxUsdgAmount?: BigNumber;

	poolAmount?: BigNumber;
	bufferAmount?: BigNumber;
	managedAmount?: BigNumber;
	managedUsd?: BigNumber;
	availableAmount?: BigNumber;
	availableUsd?: BigNumber;
	guaranteedUsd?: BigNumber;
	redemptionAmount?: BigNumber;
	reservedAmount?: BigNumber;

	balance?: BigNumber;

	weight?: BigNumber;

	maxPrice?: BigNumber;
	maxPrimaryPrice?: BigNumber;

	minPrice?: BigNumber;
	minPrimaryPrice?: BigNumber;

	contractMaxPrice?: BigNumber;
	contractMinPrice?: BigNumber;

	cumulativeFundingRate?: BigNumber;
	fundingRate?: BigNumber;

	globalShortSize?: BigNumber;

	maxAvailableLong?: BigNumber;
	maxAvailableShort?: BigNumber;

	maxGlobalLongSize?: BigNumber;
	maxGlobalShortSize?: BigNumber;

	maxLongCapacity?: BigNumber;
}

export type GMXInfoTokens = {
	[key: string]: GMXTokenInfo;
};

export type GMXTokenData = GMXToken & {
	prices: GMXTokenPrices;
	balance?: BigNumber;
	totalSupply?: BigNumber;
};

export type GMXTokensRatio = {
	ratio: BigNumber;
	largestToken: GMXToken;
	smallestToken: GMXToken;
};

export type GMXTokenBalancesData = {
	[tokenAddress: string]: BigNumber;
};

export type GMXTokenPricesData = {
	[address: string]: GMXTokenPrices;
};

export type GMXTokensAllowanceData = {
	[tokenAddress: string]: BigNumber;
};

export type GMXTokensData = {
	[address: string]: GMXTokenData;
};

export type GMXTokenPrices = {
	minPrice: BigNumber;
	maxPrice: BigNumber;
};

interface GMXBaseOrder {
	collateralToken: string;
	indexToken: string;
	sizeDelta: string;
	isLong: boolean;
	triggerPrice: string;
	triggerAboveThreshold: boolean;
	size?: string;
}

export enum GMXTradeMode {
	Market = 'Market',
	Limit = 'Limit',
	Trigger = 'Trigger',
}

export type GMXTradeType = 'LONG' | 'SHORT';

export interface GMXIncreaseOrder extends GMXBaseOrder {
	purchaseToken: string;
	purchaseTokenAmount: string;
	type: 'INCREASE';
}

export interface GMXDecreaseOrder extends GMXBaseOrder {
	collateralDelta: string;
	type: 'DECREASE',
}

export type GMXOrder = GMXIncreaseOrder | GMXDecreaseOrder;

export type ExtendedGMXOrder<T extends GMXOrder> = T & {
	index: number;
	account: string;
}

export type GMXOrdersData = [string[], string[]] | [];

export interface GMXPositionData {
	key: string;
	tokenAddress: string;
	tokenSymbol: string;
	isLong: boolean;
	leverageStr: string;
	hasPendingChanges: boolean;
	netValue: BigNumber;
	averagePrice: BigNumber;
	markPrice: BigNumber;
	liquidationPrice: BigNumber;
	size: BigNumber;
	collateralAmount: BigNumber;
	collateralAmountStr: string;
	delta: BigNumber;
	deltaPercentage: BigNumber;
	deltaStr: string;
	deltaPercentageStr: string;
	hasProfit: boolean;

	collateralTokenAddress: string;
	indexTokenAddress: string;

	entryFundingRate?: BigNumber;
	cumulativeFundingRate?: BigNumber;
}

export interface GMXPendingChange {
	size?: string; // BigNumber
	collateralSnapshot?: string; // BigNumber
	expectingCollateralChange?: boolean;
}

export interface PositionQuery {
	collateralTokens: string[];
	indexTokens: string[];
	isLong: boolean[];
}

export interface GMXState {
	loading: boolean;
}
