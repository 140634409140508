import { MarketsInfoData } from '../markets';
import { BigNumber } from 'ethers';
import { useCallback, useMemo } from 'react';
import { FindSwapPath } from './types';
import {
	createSwapEstimator,
	findAllPaths,
	getBestSwapPath,
	getMarketsGraph,
	getMaxSwapPathLiquidity,
	getSwapPathStats,
} from './utils';
import { convertTokenAddress, getWrappedToken, NATIVE_TOKEN_ADDRESS } from '../../../tokens';

export type SwapRoutesResult = {
	maxSwapLiquidity: BigNumber;
	maxLiquiditySwapPath?: string[];
	findSwapPath: FindSwapPath;
};

export function useSwapRoutes(p: {
	marketsInfoData?: MarketsInfoData;
	fromTokenAddress?: string;
	toTokenAddress?: string;
	chainId: number;
}): SwapRoutesResult {
	const { fromTokenAddress, toTokenAddress, marketsInfoData, chainId } = p;

	const wrappedToken = getWrappedToken(chainId);

	const isWrap = fromTokenAddress === NATIVE_TOKEN_ADDRESS && toTokenAddress?.toLowerCase() === wrappedToken.address?.toLowerCase();
	const isUnwrap = fromTokenAddress?.toLowerCase() === wrappedToken.address?.toLowerCase() && toTokenAddress === NATIVE_TOKEN_ADDRESS;
	const isSameToken = fromTokenAddress?.toLowerCase() === toTokenAddress?.toLowerCase();

	const wrappedFromAddress = fromTokenAddress ? convertTokenAddress(chainId, fromTokenAddress, 'wrapped') : undefined;
	const wrappedToAddress = toTokenAddress ? convertTokenAddress(chainId, toTokenAddress, 'wrapped') : undefined;

	const { graph, estimator } = useMemo(() => {
		if (!marketsInfoData) {
			return {};
		}

		return {
			graph: getMarketsGraph(Object.values(marketsInfoData)),
			estimator: createSwapEstimator(marketsInfoData),
		};
	}, [marketsInfoData]);

	const allRoutes = useMemo(() => {
		if (!marketsInfoData || !graph || !wrappedFromAddress || !wrappedToAddress || isWrap || isUnwrap || isSameToken) {
			return undefined;
		}

		return findAllPaths(marketsInfoData, graph, wrappedFromAddress.toLowerCase(), wrappedToAddress.toLowerCase())
			?.sort((a, b) => {
				return b.liquidity.sub(a.liquidity).gt(0) ? 1 : -1;
			})
			.slice(0, 5);
	}, [graph, isSameToken, isUnwrap, isWrap, marketsInfoData, wrappedFromAddress, wrappedToAddress]);

	const { maxLiquidity, maxLiquidityPath } = useMemo(() => {
		let maxLiquidity = BigNumber.from(0);
		let maxLiquidityPath: string[] | undefined = undefined;

		if (!allRoutes || !marketsInfoData || !wrappedFromAddress) {
			return { maxLiquidity, maxLiquidityPath };
		}

		for (const route of allRoutes) {
			const liquidity = getMaxSwapPathLiquidity({
				marketsInfoData,
				swapPath: route.path,
				initialCollateralAddress: wrappedFromAddress,
			});

			if (liquidity.gt(maxLiquidity)) {
				maxLiquidity = liquidity;
				maxLiquidityPath = route.path;
			}
		}

		return { maxLiquidity, maxLiquidityPath };
	}, [allRoutes, marketsInfoData, wrappedFromAddress]);

	const findSwapPath = useCallback(
		(usdIn: BigNumber, opts: { byLiquidity?: boolean }) => {
			if (!allRoutes?.length || !estimator || !marketsInfoData || !fromTokenAddress) {
				return undefined;
			}

			let swapPath: string[] | undefined = undefined;

			if (opts.byLiquidity) {
				swapPath = allRoutes[0].path;
			} else {
				swapPath = getBestSwapPath(allRoutes, usdIn, estimator);
			}

			if (!swapPath) {
				return undefined;
			}

			const swapPathStats = getSwapPathStats({
				marketsInfoData,
				swapPath,
				initialCollateralAddress: fromTokenAddress,
				wrappedNativeTokenAddress: wrappedToken.address,
				shouldUnwrapNativeToken: toTokenAddress === NATIVE_TOKEN_ADDRESS,
				shouldApplyPriceImpact: true,
				usdIn,
			});

			if (!swapPathStats) {
				return undefined;
			}

			return swapPathStats;
		},
		[allRoutes, estimator, fromTokenAddress, marketsInfoData, toTokenAddress, wrappedToken.address],
	);

	return {
		maxSwapLiquidity: maxLiquidity,
		maxLiquiditySwapPath: maxLiquidityPath,
		findSwapPath,
	};
}
