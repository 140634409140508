import styled from 'styled-components';
import { AnchorHTMLAttributes, ReactNode } from 'react';

export interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	children?: ReactNode;
}

export function ExternalLink({ children, ...props }: ExternalLinkProps): JSX.Element {
	return (
		<Container {...props} target="_blank" rel="noreferrer noopener">
			{children}
		</Container>
	);
}

const Container = styled.a`
	color: #2a85ff;
	outline: none;
`;
