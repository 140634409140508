import { REACT_APP_API_URL } from '../../common/constants';
import { UserProfile } from '../../interfaces/profile';
import { getAuthHeaders } from '../../common/get-auth-headers';
import { fetchVerify } from '../../common/utils';

interface SaveProfileParams {
	address: string;
	signature: string;
	name: string;
	email: string;
	CV: string;
	image: string | null;
}

export function saveProfile({ address, signature, name, email, CV, image }: SaveProfileParams): Promise<UserProfile> {
	return fetchVerify(`${REACT_APP_API_URL}user`, {
			headers: {
				...getAuthHeaders(address),
				'Content-Type': 'application/json',
			},
			method: 'PUT',
			body: JSON.stringify({
				signature,
				name,
				email,
				CV,
				image,
			}),
		}).then(r => r.json());
}

export function getProfile(address: string): Promise<UserProfile> {
	return fetch(`${REACT_APP_API_URL}user/${address.toLowerCase()}`).then(r => r.json());
}
