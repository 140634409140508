import cryptoJs from 'crypto-js';
import { BigNumber, ethers } from 'ethers';
import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { LocalStorageKey, useLocalStorageSerializeKey } from '../../../hooks/use-local-storage';
import { useWeb3React } from '@web3-react/core';
import { ChainId, contractAddresses } from '../../../common/constants';
import { useGasPrice } from '../../../hooks/use-gas-price';
import { ensureNotNull } from '../../../common/assertions';
import { estimateExecuteIncreaseOrderGasLimit, getExecutionFee, useGasLimits } from '../gmx/v2/lib/fees';
import { applyFactor } from '../gmx/utils';
import { useQuery } from '@tanstack/react-query';
import { SubaccountRegistry__factory } from '../../../contracts/types';
import { useWeb3Providers } from '../../../hooks/use-web3-providers';
import { useTokensDataRequest } from '../gmx/v2/lib/tokens/useTokensDataRequest';
import { useModalContext } from '../../../components/modal/modal-provider';
import { useTranslation } from 'react-i18next';
import { SubaccountModalContent } from './subaccount-modal';
import { useEthBalance } from '../../../hooks/use-eth-balance';
import { AutoNonceSigner } from '../../../web3/signer';

function getSubaccountConfigKey(chainId: number | undefined, account: string | undefined): LocalStorageKey[] | null {
	if (!chainId || !account) return null;
	return [chainId, account, 'one-click-trading-config'];
}

const NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR: Record<number, BigNumber> = {
	[ChainId.Arbitrum]: BigNumber.from(10).pow(29).mul(5),
};

export interface SubaccountSerializedConfig {
	privateKey: string;
	address: string;
}

export type Subaccount = ReturnType<typeof useSubaccount>;
const STRING_FOR_SIGNING = process.env.NODE_ENV === 'production'
	? 'Create a DeFunds subaccount. Only sign this message on a trusted website.'
	: 'Generate a DeFunds subaccount. Only sign this message on a trusted website.';
export type SubaccountNotificationState =
	| 'generating'
	| 'activating'
	| 'activated'
	| 'activationFailed'
	| 'generationFailed'
	| 'deactivating'
	| 'deactivated'
	| 'deactivationFailed'
	| 'none';

export interface SubaccountContextValues {
	activeTx: string | null;
	defaultExecutionFee: BigNumber | null;
	defaultNetworkFee: BigNumber | null;
	isActive: boolean;
	subaccount: {
		address: string;
		privateKey: string;
	} | null;
	notificationState: SubaccountNotificationState;

	clearSubaccount: () => void;
	generateSubaccount: () => Promise<string | null>;
	setActiveTx: (tx: string | null) => void;
	openModal: () => void;
	closeModal: () => void;
	setNotificationState: (state: SubaccountNotificationState) => void;
	refetch: () => void;
}

export const SubaccountContext = createContext<SubaccountContextValues | null>(null);

export function SubaccountContextProvider({ children, chainId }: PropsWithChildren & {
	chainId: ChainId
}): JSX.Element {
	const { t } = useTranslation();
	const { openModal, closeModal } = useModalContext();

	const [notificationState, setNotificationState] = useState<SubaccountNotificationState>('none');

	const { provider, account } = useWeb3React();
	const [config, setConfig] = useLocalStorageSerializeKey<SubaccountSerializedConfig | null>(
		getSubaccountConfigKey(chainId, account),
		null,
	);

	const gasPrice = useGasPrice(chainId);
	const { gasLimits } = useGasLimits(chainId);
	const { tokensData } = useTokensDataRequest(chainId);

	const [defaultExecutionFee, defaultNetworkFee] = useMemo(() => {
		if (!gasLimits || !tokensData || !gasPrice) return [null, null];

		const approxNetworkGasLimit = applyFactor(
			applyFactor(gasLimits.estimatedFeeBaseGasLimit, gasLimits.estimatedFeeMultiplierFactor),
			NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR[chainId],
		)
			// createOrder is smaller than executeOrder
			// L2 Gas
			.add(800_000);
		const networkFee = approxNetworkGasLimit.mul(gasPrice);

		const gasLimit = estimateExecuteIncreaseOrderGasLimit(gasLimits, {
			swapsCount: 1,
		});

		const executionFee =
			getExecutionFee(chainId, gasLimits, tokensData, gasLimit, gasPrice)?.feeTokenAmount ?? BigNumber.from(0);
		return [executionFee, networkFee];
	}, [chainId, gasLimits, gasPrice, tokensData]);

	const generateSubaccount = useCallback(async () => {
		if (!account) throw new Error('Account is not set');
		if (!provider) throw new Error('Empty provider');

		const signature = await provider?.getSigner().signMessage(STRING_FOR_SIGNING);

		if (!signature) return null;

		const pk = ethers.utils.keccak256(signature);
		const subWallet = new ethers.Wallet(pk);

		const encrypted = cryptoJs.AES.encrypt(pk, account);

		setConfig({
			privateKey: encrypted.toString(),
			address: subWallet.address,
		});

		return subWallet.address;
	}, [account, provider, setConfig]);

	const clearSubaccount = useCallback(() => {
		setConfig(null);
	}, [setConfig]);

	const [activeTx, setActiveTx] = useState<string | null>(null);

	const { data: isActive = false, refetch } = useQuery(
		['getSubaccount', config?.address, account],
		async () => {
			if (!config?.address || !provider) return false;
			const contract = SubaccountRegistry__factory.connect(contractAddresses[chainId].subaccountRegistry, provider);
			return contract.accountOwners(config.address).then(r => r.toLowerCase() === account?.toLowerCase());
		},
	);

	const value: SubaccountContextValues = useMemo(() => {
		return {
			openModal: () => openModal({
				id: 'subaccount',
				title: t('Trade.OneClickTrading'),
				content: <SubaccountModalContent />,
				maxWidth: '420px',
			}),
			closeModal: () => closeModal('subaccount'),
			defaultExecutionFee,
			isActive,
			defaultNetworkFee,
			subaccount: config
				? {
					address: config.address,
					privateKey: config.privateKey,
				}
				: null,
			refetch,
			generateSubaccount,
			clearSubaccount,
			notificationState,
			activeTx,
			setActiveTx,
			setNotificationState,
		};
	}, [closeModal, defaultExecutionFee, isActive, defaultNetworkFee, config, refetch, generateSubaccount, clearSubaccount, notificationState, activeTx, openModal, t]);

	return <SubaccountContext.Provider value={value}>{children}</SubaccountContext.Provider>;
}

export function useSubaccountContext(): SubaccountContextValues {
	return ensureNotNull(useContext(SubaccountContext));
}

function useSubaccountPrivateKey(): string | null {
	const { subaccount } = useSubaccountContext();
	const encryptedString = subaccount?.privateKey || null;
	const { account } = useWeb3React();
	return useMemo(() => {
		if (!account || !encryptedString) return null;
		try {
			return cryptoJs.AES.decrypt(encryptedString, account).toString(cryptoJs.enc.Utf8);
		} catch (e) {
			return null;
		}
	}, [account, encryptedString]);
}

export function useIsSubaccountActive(): boolean {
	const pkAvailable = useSubaccountPrivateKey() !== null;
	const { isActive } = useSubaccountContext();
	return isActive && pkAvailable;
}

export function useSubaccountDefaultExecutionFee(): BigNumber {
	const { defaultExecutionFee } = useSubaccountContext();
	return defaultExecutionFee ?? BigNumber.from(0);
}

function useSubaccountDefaultNetworkFee(): BigNumber {
	return useSubaccountContext().defaultNetworkFee ?? BigNumber.from(0);
}

export function useSubaccount(chainId: ChainId, requiredBalance?: BigNumber | null): {
	address: string;
	active: boolean;
	signer: ethers.Wallet;
	insufficientFunds: boolean;
} | null {
	const { subaccount } = useSubaccountContext();
	const active = useIsSubaccountActive();
	const privateKey = useSubaccountPrivateKey();
	const defaultExecutionFee = useSubaccountDefaultExecutionFee();
	const insufficientFunds = useSubaccountInsufficientFunds(requiredBalance ?? defaultExecutionFee);
	const providers = useWeb3Providers();

	return useMemo(() => {
		if (!subaccount || !active || !privateKey || !providers[chainId]) return null;

		const provider = providers[chainId];
		const signer = new AutoNonceSigner(privateKey, provider);

		return {
			address: subaccount.address,
			active,
			signer,
			insufficientFunds,
		};
	}, [insufficientFunds, subaccount, active, privateKey, providers, chainId]);
}

export function useSubaccountInsufficientFunds(requiredBalance: BigNumber | undefined | null): boolean {
	const { subaccount } = useSubaccountContext();
	const { balance: nativeTokenBalance } = useEthBalance(subaccount?.address, 10000); // TODO: refetch after position
	const isSubaccountActive = useIsSubaccountActive();
	const defaultExecutionFee = useSubaccountDefaultExecutionFee();
	const networkFee = useSubaccountDefaultNetworkFee();
	const required = (requiredBalance ?? defaultExecutionFee ?? BigNumber.from(0)).add(networkFee);

	if (!isSubaccountActive) return false;
	if (!nativeTokenBalance) return false;

	return required.gt(nativeTokenBalance);
}

export function useMainAccountInsufficientFunds(requiredBalance: BigNumber | undefined | null): boolean {
	const { account: address } = useWeb3React();
	const isSubaccountActive = useIsSubaccountActive();
	const { balance: nativeTokenBalance, isLoading } = useEthBalance(address, 10000); // TODO: refetch after position
	const networkFee = useSubaccountDefaultNetworkFee();
	const defaultExecutionFee = useSubaccountDefaultExecutionFee();
	const required = (requiredBalance ?? defaultExecutionFee).add(networkFee);
	if (!isSubaccountActive || isLoading) return false;

	return required.gt(nativeTokenBalance);
}
