import Select from './UI/select';
import { ChainId, isMobileWallet, networkParams, networks } from '../common/constants';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { useWallet } from '../features/wallet/use-wallet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NetworkParams } from '../interfaces/web3';
import { toast } from 'react-toastify';

const UNRECOGNIZED_CHAIN_ID_ERROR = -32000;
const UNRECOGNIZED_CHAIN_ID_ERROR_2 = 4902;
const MOBILE_WALLET_PENDING_TIMEOUT = 60_000;

function sanitize(p: NetworkParams): NetworkParams {
	const { wsUrl, networkProviderUrl, ...rest } = p;
	return rest;
}

export function ChainSelect(): JSX.Element {
	const { t } = useTranslation();
	const { chainId, connector, provider } = useWeb3React();
	const { selectedWallet } = useWallet();
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const [pendingChainId, setPendingChainId] = useState<ChainId | null>(null);
	useEffect(() => {
		if (chainId && pendingChainId && chainId === pendingChainId) setPendingChainId(null);
	}, [chainId, pendingChainId]);
	const switchChain = useCallback(async (chainId?: ChainId) => {
		if (!chainId) return;
		try {
			await connector.activate(chainId);
		} catch (switchError: any) {
			console.error('Error while switching chain: ', switchError);
			if (switchError.message.includes("Cannot activate an optional chain")) {
				toast.error(t('Modal.Wallet.WalletNotConnectedToChain', { chain: networkParams[chainId].chainName }));
				setPendingChainId(null);
			} else if (switchError.code === UNRECOGNIZED_CHAIN_ID_ERROR || switchError.code === UNRECOGNIZED_CHAIN_ID_ERROR_2) {
				try {
					await provider?.provider.request?.({
						method: 'wallet_addEthereumChain',
						params: [sanitize(networkParams[chainId])],
					});
				} catch (error) {
					console.log(error);
				}
			}
		}
	}, [connector, provider?.provider, t]);
	const selectedNetwork = networks.find((item) => item.id === (pendingChainId || chainId)) || null;

	useEffect(() => () => {
		timeoutRef.current = null;
	}, []);

	return (
		<Select
			options={networks}
			isLoading={pendingChainId !== null}
			placeholder={t('Navbar.SwitchNetwork')}
			value={selectedNetwork}
			name="network"
			hasError={selectedNetwork === null}
			isMulti={false}
			onChange={(val) => {
				const chainId = Number(val?.id);
				if (selectedWallet && isMobileWallet(selectedWallet)) {
					setPendingChainId(chainId);
					timeoutRef.current = setTimeout(() => {
						if (!timeoutRef.current) return;
						setPendingChainId(null);
						timeoutRef.current = null;
					}, MOBILE_WALLET_PENDING_TIMEOUT);
				}
				switchChain(chainId)?.catch(e => {
					console.error(e);
					setPendingChainId(null);
				});
			}}
		/>
	);
}
