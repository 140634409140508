import { BigNumber, ethers } from 'ethers';
import { GMXTokenData, GMXTokenPrices, GMXTokensAllowanceData, GMXTokensData, GMXTokensRatio } from '../../../types';
import { getIsEquivalentTokens, NATIVE_TOKEN_ADDRESS } from '../../../tokens';
import { GMXInfoTokens, GMXToken, GMXTokenInfo } from '../../../types';
import { PRECISION, USD_DECIMALS } from '../../../constants';
import { adjustForDecimals, expandDecimals, formatAmountFree } from '../../../utils';
import { formatAmount } from '../../../tradingview/api/format-amount';

export function getTokenData(tokensData?: GMXTokensData, address?: string, convertTo?: 'wrapped' | 'native'): GMXTokenData | undefined {
	if (!address || !tokensData?.[address]) {
		return undefined;
	}

	const token = tokensData[address];

	if (convertTo === 'wrapped' && token.isNative && token.wrappedAddress) {
		return tokensData[token.wrappedAddress];
	}

	if (convertTo === 'native' && token.isWrapped) {
		return tokensData[NATIVE_TOKEN_ADDRESS];
	}

	return token;
}

export function getNeedTokenApprove(
	tokenAllowanceData: GMXTokensAllowanceData,
	tokenAddress: string,
	amountToSpend: BigNumber,
): boolean {
	if (tokenAddress === NATIVE_TOKEN_ADDRESS || !tokenAllowanceData[tokenAddress]) {
		return false;
	}

	return amountToSpend.gt(tokenAllowanceData[tokenAddress]);
}

export function convertToTokenAmount(
	usd: BigNumber | undefined,
	tokenDecimals: number | undefined,
	price: BigNumber | undefined,
): BigNumber | undefined {
	if (!usd || typeof tokenDecimals !== 'number' || !price?.gt(0)) {
		return undefined;
	}

	return usd.mul(expandDecimals(1, tokenDecimals)).div(price);
}

export function convertToUsd(
	tokenAmount: BigNumber | undefined,
	tokenDecimals: number | undefined,
	price: BigNumber | undefined,
): BigNumber | undefined {
	if (!tokenAmount || typeof tokenDecimals !== 'number' || !price) {
		return undefined;
	}

	return tokenAmount.mul(price).div(expandDecimals(1, tokenDecimals));
}

export function getTokensRatioByPrice(p: {
	fromToken: GMXTokenData;
	toToken: GMXTokenData;
	fromPrice: BigNumber;
	toPrice: BigNumber;
}): GMXTokensRatio {
	const { fromToken, toToken, fromPrice, toPrice } = p;

	const [largestToken, smallestToken, largestPrice, smallestPrice] = fromPrice.gt(toPrice)
		? [fromToken, toToken, fromPrice, toPrice]
		: [toToken, fromToken, toPrice, fromPrice];

	const ratio = largestPrice.mul(PRECISION).div(smallestPrice);

	return { ratio, largestToken, smallestToken };
}

export function getTokensRatioByAmounts(p: {
	fromToken: GMXToken;
	toToken: GMXToken;
	fromTokenAmount: BigNumber;
	toTokenAmount: BigNumber;
}): GMXTokensRatio {
	const { fromToken, toToken, fromTokenAmount, toTokenAmount } = p;

	const adjustedFromAmount = fromTokenAmount.mul(PRECISION).div(expandDecimals(1, fromToken.decimals));
	const adjustedToAmount = toTokenAmount.mul(PRECISION).div(expandDecimals(1, toToken.decimals));

	const [smallestToken, largestToken, largestAmount, smallestAmount] = adjustedFromAmount.gt(adjustedToAmount)
		? [fromToken, toToken, adjustedFromAmount, adjustedToAmount]
		: [toToken, fromToken, adjustedToAmount, adjustedFromAmount];

	const ratio = smallestAmount.gt(0) ? largestAmount.mul(PRECISION).div(smallestAmount) : BigNumber.from(0);

	return { ratio, largestToken, smallestToken };
}

export function formatTokensRatio(fromToken?: GMXToken, toToken?: GMXToken, ratio?: GMXTokensRatio): string | undefined {
	if (!fromToken || !toToken || !ratio) {
		return undefined;
	}

	const [largest, smallest] =
		ratio.largestToken.address === fromToken.address ? [fromToken, toToken] : [toToken, fromToken];

	return `${formatAmount(ratio.ratio, USD_DECIMALS, 4)} ${smallest.symbol} / ${largest.symbol}`;
}

export function getAmountByRatio(p: {
	fromToken: GMXToken;
	toToken: GMXToken;
	fromTokenAmount: BigNumber;
	ratio: BigNumber;
	shouldInvertRatio?: boolean;
}): BigNumber {
	const { fromToken, toToken, fromTokenAmount, ratio, shouldInvertRatio } = p;

	if (getIsEquivalentTokens(fromToken, toToken) || fromTokenAmount.eq(0)) {
		return p.fromTokenAmount;
	}

	const _ratio = shouldInvertRatio ? PRECISION.mul(PRECISION).div(ratio) : ratio;

	const adjustedDecimalsRatio = adjustForDecimals(_ratio, fromToken.decimals, toToken.decimals);

	return p.fromTokenAmount.mul(adjustedDecimalsRatio).div(PRECISION);
}

export function getMidPrice(prices: GMXTokenPrices): BigNumber {
	return prices.minPrice.add(prices.maxPrice).div(2);
}

export function convertToContractPrice(price: BigNumber, tokenDecimals: number): BigNumber {
	return price.div(expandDecimals(1, tokenDecimals));
}

export function convertToContractTokenPrices(prices: GMXTokenPrices, tokenDecimals: number): {
	min: BigNumber;
	max: BigNumber;
} {
	return {
		min: convertToContractPrice(prices.minPrice, tokenDecimals),
		max: convertToContractPrice(prices.maxPrice, tokenDecimals),
	};
}

export function parseContractPrice(price: BigNumber, tokenDecimals: number): BigNumber {
	return price.mul(expandDecimals(1, tokenDecimals));
}

/**
 * Used to adapt Synthetics tokens to InfoTokens where it's possible
 */
export function adaptToV1InfoTokens(tokensData: GMXTokensData): GMXInfoTokens {
	const infoTokens = Object.keys(tokensData).reduce((acc, address) => {
		const tokenData = getTokenData(tokensData, address)!;

		acc[address] = adaptToV1TokenInfo(tokenData);

		return acc;
	}, {} as GMXInfoTokens);

	return infoTokens;
}

/**
 * Used to adapt Synthetics tokens to InfoTokens where it's possible
 */
export function adaptToV1TokenInfo(tokenData: GMXTokenData): GMXTokenInfo {
	return {
		...tokenData,
		minPrice: tokenData.prices?.minPrice,
		maxPrice: tokenData.prices?.maxPrice,
	};
}

export function getSpread(p: { minPrice: BigNumber; maxPrice: BigNumber }): BigNumber {
	const diff = p.maxPrice.sub(p.minPrice);
	return diff.mul(PRECISION).div(p.maxPrice.add(p.minPrice).div(2));
}


export function formatTokenAmount(
	amount?: BigNumber,
	tokenDecimals?: number,
	symbol?: string,
	opts: {
		showAllSignificant?: boolean;
		displayDecimals?: number;
		fallbackToZero?: boolean;
		useCommas?: boolean;
		minThreshold?: string;
		maxThreshold?: string;
	} = {},
): string | undefined {
	const {
		displayDecimals = 4,
		showAllSignificant = false,
		fallbackToZero = false,
		useCommas = false,
		minThreshold = '0',
		maxThreshold,
	} = opts;

	const symbolStr = symbol ? ` ${symbol}` : '';

	if (!amount || !tokenDecimals) {
		if (fallbackToZero) {
			amount = BigNumber.from(0);
			tokenDecimals = displayDecimals;
		} else {
			return undefined;
		}
	}

	let amountStr: string;

	const sign = amount.lt(0) ? '-' : '';

	if (showAllSignificant) {
		amountStr = formatAmountFree(amount, tokenDecimals, tokenDecimals);
	} else {
		const exceedingInfo = getLimitedDisplay(amount, tokenDecimals, { maxThreshold, minThreshold });
		const symbol = exceedingInfo.symbol ? `${exceedingInfo.symbol} ` : '';
		amountStr = `${symbol}${sign}${formatAmount(exceedingInfo.value, tokenDecimals, displayDecimals, useCommas)}`;
	}

	return `${amountStr}${symbolStr}`;
}


const MAX_EXCEEDING_THRESHOLD = '1000000000';
const MIN_EXCEEDING_THRESHOLD = '0.01';
const TRIGGER_PREFIX_ABOVE = '>';
const TRIGGER_PREFIX_BELOW = '<';

function getLimitedDisplay(
	amount: BigNumber,
	tokenDecimals: number,
	opts: { maxThreshold?: string; minThreshold?: string } = {},
): { symbol: string; value: BigNumber; } {
	const { maxThreshold = MAX_EXCEEDING_THRESHOLD, minThreshold = MIN_EXCEEDING_THRESHOLD } = opts;
	const max = expandDecimals(maxThreshold, tokenDecimals);
	const min = ethers.utils.parseUnits(minThreshold, tokenDecimals);
	const absAmount = amount.abs();

	if (absAmount.eq(0)) {
		return {
			symbol: '',
			value: absAmount,
		};
	}

	const symbol = absAmount.gt(max) ? TRIGGER_PREFIX_ABOVE : absAmount.lt(min) ? TRIGGER_PREFIX_BELOW : '';
	const value = absAmount.gt(max) ? max : absAmount.lt(min) ? min : absAmount;

	return {
		symbol,
		value,
	};
}
