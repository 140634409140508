import { TransactionRequest, TransactionResponse } from '@ethersproject/abstract-provider';
import { Wallet } from 'ethers';

type Deferrable<T> = {
	[ K in keyof T ]: T[K] | Promise<T[K]>;
}

export class AutoNonceSigner extends Wallet {
	nonce: Promise<number> | null = null;

	public getNextNonce(): Promise<number> {
		if (this.nonce === null) {
			this.nonce = this.getTransactionCount('pending');
		} else {
			this.nonce = this.nonce.then(n => n + 1);
		}
		return this.nonce;
	}

	async sendTransaction(transaction: Deferrable<TransactionRequest>): Promise<TransactionResponse> {
		const nextNonce = await this.getNextNonce();
		transaction = { ...transaction, nonce: nextNonce };
		return super.sendTransaction(transaction).finally(() => {
			this.nonce = null;
		});
	}
}
