import { BigNumber, constants, Contract, providers } from 'ethers';
import { Trading__factory } from './types';

export class ERC20BalanceChecker {
	private contract: Contract;

	constructor(private readonly provider: providers.JsonRpcProvider, private readonly account: string) {
		this.contract = Trading__factory.connect(account, provider);
	}

	async balances(tokens: string[]): Promise<Record<string, string>> {
		if (tokens.length === 0) return Promise.resolve({});
		let erc20Tokens = [...tokens];
		let ethBalance = BigNumber.from(0);
		if (tokens.includes(constants.AddressZero)) {
			ethBalance = await this.provider.getBalance(this.account);
			erc20Tokens = erc20Tokens.filter(t => t !== constants.AddressZero);
		}
		const result = await this.contract.getAssetsSizes(erc20Tokens)
			.then((r: BigNumber[]) => r.reduce(
				(acc, balance, i) => ({ ...acc, [tokens[i]]: balance.toString() }),
				{},
			))
			.catch((e: unknown) => {
				console.error(e, tokens);
				return tokens.reduce((acc, addr) => ({ ...acc, [addr]: '0' }), {});
			});
		return {
			...result,
			[constants.AddressZero]: ethBalance,
		};
	}
}
