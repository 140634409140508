import { TransparentInput, TransparentInputContainer } from './transparent-input';
import React, { forwardRef, ReactNode } from 'react';

import styled from 'styled-components';
import { MaxButton } from './max-button';
import { TextSM } from './typography';
import { useTranslation } from 'react-i18next';
import { Customizable } from '../../interfaces/ui';
import { InlineButton } from './inline-button';

export interface InputWidgetProps extends Customizable {
	value: string;
	onChange: (v: string) => void;
	balance?: string;
	children?: ReactNode;
	currency?: ReactNode;
	maxLength?: number;
}

export const InputWidget = forwardRef<HTMLInputElement | null, InputWidgetProps>(
	({ value, onChange, currency, balance, className, children, maxLength = 0 }: InputWidgetProps, ref) => {
		const { t } = useTranslation();
		return (
			<TransparentInputContainer className={className}>
				<Row>
					<TransparentInput
						type="number"
						spellCheck={false}
						autoComplete="off"
						maxLength={maxLength}
						placeholder="0.0"
						value={value}
						onChange={e => onChange(e.target.value)}
						ref={ref}
					/>
					{currency && <Currency>{currency}</Currency>}
				</Row>
				{balance && <Row>
          <Balance>Balance: {balance}</Balance>
          <Max onClick={() => onChange(balance)}>{t('Common.Max')}</Max>
        </Row>}
				{children}
			</TransparentInputContainer>
		);
	}
)

const Max = styled(MaxButton)`
	margin-left: 12px;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
`;

const Balance = styled(TextSM)`
  color: #6f767e;
`;

const Currency = styled(InlineButton)`
	margin-left: auto;
	border-color: transparent;
	background: transparent;
	cursor: default;
	padding-right: 0;
`;
