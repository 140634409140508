import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { ensureNotNull } from '../../common/assertions';
import { Token } from '../../interfaces/whitelists';
import { BigNumber } from 'ethers';
import { useFundDebt } from '../../hooks/use-fund-debt';
import { GetBalanceFunc, RefetchFunc, useBalances } from '../../hooks/use-balances';
import { useFundAllowedTokens } from '../whitelists/use-fund-allowed-tokens';
import { Fund } from '../../interfaces/fund';
import { useUsdtAddress } from '../../hooks/use-usdt-address';
import { ChainId } from '../../common/constants';
import { Signer } from '@ethersproject/abstract-signer';
import { useWeb3React } from '@web3-react/core';
import { useSubaccount } from './subaccount/subaccount-provider';

export interface TradeContextValues {
	tradingContractAddress: string;
	allowedTokensLoading: boolean;
	allowedTokens: Token[];
	fundId: string;
	isFundToken: (t: string) => boolean;
	debt: BigNumber | null;
	getBalance: GetBalanceFunc;
	refetchBalances: RefetchFunc;
	signer: Signer | null;
	chainId: ChainId;
}

export const TradeContext = createContext<TradeContextValues | null>(null);

export function useTradeContext(): TradeContextValues {
	return ensureNotNull(useContext(TradeContext));
}

export interface TradeProviderProps {
	children: ReactNode;
	fund: Fund;
}

export function TradeProvider({ fund, children }: TradeProviderProps): JSX.Element | null {
	const debt = useFundDebt(fund);
	const usdtAddress = useUsdtAddress();
	const allowedTokensRequest = useFundAllowedTokens(fund);
	const allowedTokens = useMemo(
		() => allowedTokensRequest.loading ? [] : allowedTokensRequest.data,
		[allowedTokensRequest],
	);

	const { provider: metamaskProvider } = useWeb3React();
	const subaccount = useSubaccount(fund.chainId);
	const signer = useMemo(() => {
		if (subaccount?.insufficientFunds) {
			return metamaskProvider?.getSigner() || null;
		} else {
			return subaccount?.signer || metamaskProvider?.getSigner() || null;
		}
	}, [metamaskProvider, subaccount?.insufficientFunds, subaccount?.signer])
	const fundTokens = useMemo(
		() => allowedTokens.map(token => token.address.toLowerCase()),
		[allowedTokens],
	);
	const erc20Tokens = useMemo(
		() => usdtAddress ? [
			...allowedTokens.filter(token => !token.isSynthetic).map(t => t.address),
			usdtAddress,
		] : [],
		[allowedTokens, usdtAddress],
	);
	const { getBalance, refetchBalances } = useBalances(fund.tradingAddress, erc20Tokens, debt);
	const isFundToken = useCallback(
		(t: string): boolean => fundTokens.includes(t.toLowerCase()),
		[fundTokens],
	);

	return (
		<TradeContext.Provider value={{
			tradingContractAddress: fund.tradingAddress,
			allowedTokens,
			allowedTokensLoading: allowedTokensRequest.loading,
			fundId: fund.id,
			chainId: fund.chainId,
			isFundToken,
			debt,
			getBalance,
			refetchBalances,
			signer,
		}}>
			{children}
		</TradeContext.Provider>
	);
}

