import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { ReactComponent as LogoImage } from '../../asset/images/logo.svg';
import { ReactComponent as MarketImage } from '../../asset/images/market.svg';
import { ReactComponent as FundImage } from '../../asset/images/fund.svg';
import { ReactComponent as InvestmentImage } from '../../asset/images/investment.svg';
import { ThemeSwitcher } from './theme-switcher';
import { breakpoints } from '../UI/breakpoints';
import styled from 'styled-components';
import { TextMBold } from '../UI/typography';
import { useScreenDimensions } from '../../hooks/use-screen-dimensions';

export function Sidebar(): JSX.Element {
	const { t } = useTranslation();
	const { isActive } = useWeb3React();

	return (
		<Container>
			<Logo>
				<NavLink to="/">
					<StyledLogo />
				</NavLink>
			</Logo>
			<div>
				<MenuItems>
					<MenuItem
						icon={<MarketImage />}
						title={t(`Sidebar.Menu.Market`)}
						to="/market"
					/>
					{isActive && (
						<>
							<MenuItem
								icon={<FundImage />}
								title={t(`Sidebar.Menu.Fund`)}
								to="/fund"
							/>
							<MenuItem
								icon={<InvestmentImage />}
								title={t(`Sidebar.Menu.Investment`)}
								to="/investment"
							/>
						</>
					)}
				</MenuItems>
			</div>
			<ThemeContainer>
				<ThemeSwitcher />
			</ThemeContainer>
		</Container>
	);
}

function MenuItem({ icon, title, to }: { icon: ReactNode; title: ReactNode; to: string }): JSX.Element {
	const [width] = useScreenDimensions();
	return (
		<>
			<NavLink
				to={to}
				style={({ isActive }) => {
					return {
						background: width > breakpoints.tablet && isActive ? '#EFEFEF' : undefined,
						color: isActive ? '#1a1d1f' : '#6f767e',
						borderRadius: '12px',
					};
				}}
			>
				<LinkContainer>
					<MenuLink>
						<Icon>{icon}</Icon>
						<MenuTitle>{title}</MenuTitle>
					</MenuLink>
				</LinkContainer>
			</NavLink>
		</>
	);
}

const StyledLogo = styled(LogoImage)`
	width: 100%;
	height: 40px;
`;

const MenuItems = styled.div`
	display: flex;
	flex-direction: column;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: row;
	  justify-content: space-around;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${({ theme }) => theme.colors.background};
  height: 100%;
  box-shadow: 10px 10px 20px #f2f2f7;
	min-width: 292px;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    padding: 12px 16px;
    min-width: auto;
    box-shadow: inset 0 1px 0 #F4F4F4;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
	height: 54px;
	& > a {
    height: 40px;
	}

  @media screen and (max-width: ${breakpoints.tablet}px) {
	  display: none;
  }
`;

export const LogoSm = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto 40px;
`;

const ThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
	padding-top: 20px;
	border-top: 2px solid #F4F4F4;
	width: 100%;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const MenuLink = styled(TextMBold)`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
	  padding: 0;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    width: 32px;
    height: 32px;
	  margin-bottom: 4px;
  }
`;

const MenuTitle = styled.div`
  margin-left: 12px;

  @media screen and (max-width: ${breakpoints.tablet}px) {
	  font-size: 10px;
    margin-left: 0;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  border-radius: 12px;
	background: inherit;

	&:hover {
    color: #1a1d1f;
	}
`;
