import { useLocalStorage } from 'react-use';
import { Dispatch, SetStateAction } from 'react';

export type LocalStorageKey = string | number | boolean | null | undefined;

export function useLocalStorageSerializeKey<T>(
	key: LocalStorageKey | LocalStorageKey[],
	initialValue: T,
	opts?: {
		raw: boolean;
		serializer: (val: T) => string;
		deserializer: (value: string) => T;
	},
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {
	key = JSON.stringify(key);

	return useLocalStorage<T>(key, initialValue, opts);
}
