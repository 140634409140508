import styled from 'styled-components';
import { Button } from './button';
export type MiniButtonType = 'cancel' | 'warning' | 'success' | 'info';

const mapButtonTypeToColor: Record<MiniButtonType, string> = {
	cancel: '#FF6A55',
	warning: '#ffd300',
	success: '#68cb84',
	info: '#2a85ff',
}

export const MiniButton = styled(Button)<{ buttonAfter?: boolean; buttonType?: MiniButtonType }>`
  padding: 4px 8px;
  color: ${props => mapButtonTypeToColor[props.buttonType || 'info']};
  border: 2px solid ${props => mapButtonTypeToColor[props.buttonType || 'info']};
  background: transparent;
  border-radius: 8px;
  font-size: 12px;
  line-height: 14px;
	cursor: pointer;
  ${props => props.buttonAfter ? 'margin-left: 8px' : 'margin-right: 8px'};
	display: flex;
	align-items: center;
	justify-content: center;

  &:hover {
    background: transparent;
  }
`;
