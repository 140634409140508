import Multicall from '../../../abis/Multicall.json';
import Token from '../../../abis/Token.json';
import { BigNumber } from 'ethers';
import { MulticallRequestConfig, useMulticall } from '../multicall';
import { getV2Tokens, NATIVE_TOKEN_ADDRESS } from '../../../tokens';
import { gmxContractAddresses } from '../../../constants';
import { GMXTokenBalancesData } from '../../../types';

type BalancesDataResult = {
	balancesData?: GMXTokenBalancesData;
};

export function useTokenBalances(chainId: number, account?: string | null): BalancesDataResult {
	const { data } = useMulticall(chainId, 'useTokenBalances', {
		key: account ? [account] : null,
		request: () =>
			getV2Tokens(chainId).reduce((acc: MulticallRequestConfig<any>, token) => {
				// Skip synthetic tokens
				if (token.isSynthetic) return acc;

				const address = token.address;

				if (address === NATIVE_TOKEN_ADDRESS) {
					acc[address] = {
						contractAddress: gmxContractAddresses[chainId].Multicall,
						abi: Multicall.abi,
						calls: {
							balance: {
								methodName: 'getEthBalance',
								params: [account],
							},
						},
					};
				} else {
					acc[address] = {
						contractAddress: address,
						abi: Token.abi,
						calls: {
							balance: {
								methodName: 'balanceOf',
								params: [account],
							},
						},
					};
				}

				return acc;
			}, {}),
		parseResponse: (res: any) =>
			Object.keys(res.data).reduce((tokenBalances: GMXTokenBalancesData, tokenAddress) => {
				tokenBalances[tokenAddress] = BigNumber.from(res.data[tokenAddress].balance.returnValues[0]);

				return tokenBalances;
			}, {} as GMXTokenBalancesData),
	});

	return {
		balancesData: data,
	};
}
