import { BigNumber } from 'ethers';
import { Provider } from '@ethersproject/providers';
import { Signer } from '@ethersproject/abstract-signer';

export function applyBuffer(gasLimit: BigNumber): BigNumber {
	return gasLimit.mul(11000).div(10000);
}

export async function getGasPrice(provider: Provider): Promise<BigNumber> {
	const gasPrice = await provider.getGasPrice();
	return gasPrice?.mul(11000).div(10000);
}

export async function getGasLimits(signer: Signer): Promise<{ maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber; }> {
	const gasPrice = await getGasPrice(signer.provider!);
	return { maxFeePerGas: gasPrice, maxPriorityFeePerGas: gasPrice };
}
