import { TooltipStateful as Component } from "@yandex/ui/Tooltip/desktop/bundle"; // TODO: get rid of yandex UI
import styled from "styled-components";
import { ReactComponent as InfoImage } from "../../asset/images/info.svg";

import { Customizable } from '../../interfaces/ui';
import { MouseEventHandler, ReactNode } from 'react';

const InfoImageContainer = styled.span`
  cursor: pointer;
  user-select: none;
`;

export type TooltipIconState = "warning" | "default";

export interface TooltipProps extends Customizable {
  content: ReactNode;
  handle?: ReactNode;
  iconState?: TooltipIconState;
  iconSize?: number;
  direction?: string[];
  maxWidth?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export function Tooltip({
  content,
  className,
  iconState,
  iconSize,
  direction = ["bottom", "right"],
  handle,
  maxWidth,
  onClick,
}: TooltipProps): JSX.Element {

  return (
    <InfoImageContainer className={className} onClick={onClick}>
      <TooltipComponent
        view="default"
        size="m"
        hasTail
        direction={direction}
        content={content}
        maxWidth={maxWidth}
        trigger="click"
      >
        <span className="info__tooltip">
          {handle || <StyledInfoImage iconState={iconState} iconSize={iconSize} />}
        </span>
      </TooltipComponent>
    </InfoImageContainer>
  );
}

const StyledInfoImage = styled(InfoImage)<{ iconState?: TooltipIconState; iconSize?: number }>`
  color: #9A9FA5;
  ${props => props.iconState === 'warning' ? 'color: #ffd300' : ''};
  ${props => props.iconSize ? `width: ${props.iconSize}px; height: ${props.iconSize}px;` : ''};
`;

const TooltipComponent = styled(Component)<{ maxWidth?: number }>`
  max-width: ${props => props.maxWidth ?? 220}px;
`;

export const TooltipHandle = styled.span`
  border-bottom: 1px dashed;
`;
