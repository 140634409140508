import { getChainName } from '../common/constants';
import { getNativeToken } from '../features/trade/gmx/tokens';
import { Fragment, PropsWithChildren, useState } from 'react';
import { ExternalLink } from '../components/UI/external-link';
import { TextS, TextSMBold } from '../components/UI/typography';
import styled from 'styled-components';

// TODO: i18n
const Trans = Fragment;

export const NOT_ENOUGH_FUNDS = 'NOT_ENOUGH_FUNDS';
export const USER_DENIED = 'USER_DENIED';
export const RPC_ERROR = 'RPC_ERROR';
export const NETWORK_CHANGED = 'NETWORK_CHANGED';

type ErrorPattern = { msg?: string; code?: number };

const TX_ERROR_PATTERNS: { [key: string]: ErrorPattern[] } = {
	[NOT_ENOUGH_FUNDS]: [
		{ msg: 'insufficient funds for gas' },
		{ msg: 'not enough funds for gas' },
		{ msg: 'failed to execute call with revert code InsufficientGasFunds' },
	],
	[USER_DENIED]: [{ msg: 'User denied transaction signature' }, { msg: 'user rejected transaction' }],
	[NETWORK_CHANGED]: [{ msg: 'underlying network changed' }],
	[RPC_ERROR]: [
		// @see https://eips.ethereum.org/EIPS/eip-1474#error-codes
		{ code: -32005 },
		{ msg: 'Non-200 status code' },
		{ msg: 'Request limit exceeded' },
		{ msg: 'Internal JSON-RPC error' },
		{ msg: 'Response has no error or result' },
		{ msg: 'we can\'t execute this request' },
		{ msg: 'couldn\'t connect to the network' },
	],
};

export type TxError = {
	message?: string;
	code?: number;
	data?: any;
	error?: any;
};

export function extractError(ex: TxError): any[] {
	if (!ex) {
		return [];
	}
	let message = ex.data?.message || ex.message;
	let code = ex.code;

	if (ex.error?.body) {
		try {
			const parsed = JSON.parse(ex.error?.body);
			if (parsed?.error?.message) {
				message = parsed.error.message;
			}
			if (parsed?.error?.code) {
				code = parsed.error.code;
			}
		} catch (e) {
			// do nothing
		}
	}

	if (!message && !code) {
		return [];
	}

	for (const [type, patterns] of Object.entries(TX_ERROR_PATTERNS)) {
		for (const pattern of patterns) {
			const matchCode = pattern.code && code === pattern.code;
			const matchMessage = pattern.msg && message && message.includes(pattern.msg);

			if (matchCode || matchMessage) {
				return [message, type, ex.data];
			}
		}
	}

	return [message, null, ex.data];
}

export function getErrorMessage(chainId: number, ex: Error): { failMsg: string | JSX.Element; autoCloseToast: number | null } {
	const [message, type, errorData] = extractError(ex);
	const nativeToken = getNativeToken(chainId);

	let failMsg;
	let autoCloseToast: number | null = 5000;

	switch (type) {
		case NOT_ENOUGH_FUNDS:
			failMsg = (
				<Trans>
					There is not enough {nativeToken.symbol} in your account on {getChainName(chainId)} to send this transaction.
				</Trans>
			);
			break;
		case NETWORK_CHANGED:
			failMsg = (
				<Trans>
					<div>Your wallet is not connected to {getChainName(chainId)}.</div>
				</Trans>
			);
			break;
		case USER_DENIED:
			failMsg = `Transaction was cancelled.`;
			break;
		case RPC_ERROR: {
			autoCloseToast = null;

			const originalError = errorData?.error?.message || errorData?.message || message;

			failMsg = (
				<div>
					<Trans>
						Transaction failed due to RPC error.
						<br />
						<br />
						If error persist, please try changing the RPC url in your wallet settings.{' '}
						A list of RPC URLs and their statuses can be found on <ExternalLink href="https://chainlist.org/">Chainlist</ExternalLink>.
					</Trans>
					<br />
					{originalError && <ExpandableError>{originalError}</ExpandableError>}
				</div>
			);
			break;
		}
		default:
			failMsg = (
				<div>
					<TextSMBold>Transaction failed</TextSMBold>
					<br />
					{message && <ExpandableError>{message}</ExpandableError>}
				</div>
			);
	}

	return { failMsg, autoCloseToast };
}

function ExpandableError(props: PropsWithChildren): JSX.Element {
	const [open, setOpen] = useState(false);
	return (
		<Container>
      <TextSMBold onClick={() => setOpen((old) => !old)}>
        {open ? "Hide error" : "Show error"}
      </TextSMBold>
			{open && <StyledError>{props.children}</StyledError>}
		</Container>
	);
}

const Container = styled.div`
	margin-top: 4px;
`;

const StyledError = styled(TextS)`
	margin-top: 4px;
	display: flex;
	max-width: 100%;
	overflow: auto;
	word-break: break-word;
	max-height: 120px;
`;
