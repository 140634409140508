const translation = {
  hi: "Hello",
  Tooltip: {
    Sf: '% of deposit amount will be charged from investor in USDT at the moment of investment <Link>(learn more)</Link>.',
    Pf: 'Performance fee is your compensation for generating positive returns for your investors. It is deducted from the fund\'s profit only if you achieve positive returns during the reporting period <Link>(learn more)</Link>.',
    ReportingPeriod: 'The duration over which the fund\'s withdrawal and deposit requests are processed and fees are calculated. <Link>(learn more)</Link>.',
    Indent: 'Withdrawal requests made within the indent period are carried over to the next reporting period <Link>(learn more)</Link>.',
    HWM: 'Enabling HWM ensures that performance fee is only charged on new profits above the previous highest fund value. This prevents managers from collecting fees on previously lost capital that has been recovered <Link>(learn more)</Link>.',
    HWMValue: 'Performance fee is paid to manager if fund\'s performance exceeds this value <Link>(learn more)</Link>.',
    AvailableTokens: 'Every fund has its own list of allowed ERC20 tokens, that manager is able to operate with <Link>(learn more)</Link>.',
    Invested: 'Amount of invested USDT to this fund during all the time',
    Withdrawn: 'Amount of withdrawn USDT to this fund during all the time',
    Pnl: 'How much you have earned from this fund during all the time',
    CommissionsPaid: 'How much commissions you have paid to this fund during all the time',
    ProfileCV: 'Couple of words about you',
    SlippageTolerance: 'Acceptable price impact in percents',
    FundsReceived: 'Amount of funds that is going to be deposited to this fund at the moment of the report <Link>(learn more)</Link>.',
    Withdrawals: 'Amount of funds that is going to be withdrawn from this fund at the moment of the report <Link>(learn more)</Link>.',
    MustBePaid: 'Amount of funds you need to have on fund\'s balance in advance before the report, in order to prevent unwanted auto-closing of your positions <Link>(learn more)</Link>.',
    DirectTransfers: 'The existence of direct transfers bypassing the standard investment mechanism may indicate manipulation of profitability indicators.',
    TokenDescription: 'Size of your share in this fund',
  },
  Sidebar: {
    Menu: {
      Market: "DeFunds Market",
      Fund: "My DeFunds",
      Investment: "My Investments",
    },
    Theme: {
      Light: "Light",
      Dark: "Dark",
      Soon: "Soon",
    },
  },
  Navbar: {
    ConnectWallet: "Connect Wallet",
    SwitchNetwork: "Switch network",
    UnsupportedNetwork: "Unsupported network",
    ApproveInWallet: "Approve in wallet...",
  },
  Modal: {
    Success: {
      Title: "Success!",
      Caption: "DeFund was successfully created",
      Button: {
        SeeMyFunds: "See my funds",
      },
    },
    Wallet: {
      Title: "Select a Wallet",
      Caption: "Please select a wallet to connect to DeFunds",
      InstallMetamask:
        "You'll need to install MetaMask to continue. Once you have it installed, go ahead and refresh the page.",
      Button: {
        Back: "Back to Wallets",
        OpenMetaMask: "Open MetaMask",
      },
      WalletNotConnectedToChain: 'Your wallet is not connected to {{chain}}',
      UnsupportedChain: "Unsupported network is selected in your wallet. Supported networks: {{supportedChainNames}}."
    },
  },
  Investment: {
    Indicators: {
      Title: "Summary Investments indicators",
      CurrentInvestments: "Current investments",
      Invested: "invested",
      Withdrawn: "withdrawn",
      ProfitOrLoss: "Profit or loss",
      Profitability: "Profitability",
    },
    Chart: {
      Title: "Chart of yield",
    },
    Summary: {
      Title: "Return summary",
      MaxDrawdown: "Max drawdown",
      Current: "Current",
      Max: "Max",
      AllTimeIncome: "All time income",
    },
    Empty: {
      Title: "You don’t have any investments yet",
      Caption: "Go to the Market and choose the DeFund to invest",
      Button: "To DeFunds Market",
    },
    MyDeFunds: {
      Title: "My Investments",
      Active: "Active",
      Closed: "Closed",
      EmptyTitle: "No DeFunds found",
    }
  },
  Profile: {
    Title: "Profile information",
    NameLabel: "Display name",
    NamePlaceholder: "Enter name",
    EmailLabel: "Email",
    EmailPlaceholder: "Enter email",
    Notifications: "Notifications",
    DescriptionPlaceholder: "Enter description",
    ReportingPeriod: "Reporting period",
    FundConditionsChange: "Fund conditions change",
    CV: "CV",
    Name: "Name",
    Address: "Address",
    Email: "Email",
  },
  Swap: {
    SettingsModal: {
      Title: "Transaction settings",
      Auto: "Auto",
    },
    TokenListModal: {
      Title: "Select token",
      SearchPlaceholder: 'Search token',
    },
    Button: {
      ChooseTokens: "Choose tokens",
      ChooseAmount: "Choose amount",
      InsufficientBalance: "Insufficient balance",
      Swap: "Swap",
      SelectToken: "Select token",
    },
    Balance: "Balance",
    ExchangeRate: "Exchange Rate",
    MinimumReceived: "Minimum Received",
    PriceImpact: "Price impact",
    SlippageTolerance: "Slippage Tolerance",
    NetworkFee: "Network fee",
    Error: {
      Title: "Error from 0x",
      Description: "Previous exchange rate probably has expired",
      TryOptions: "Try one of the following options",
      Option1: "Increase slippage tolerance in settings window and swap again",
      Option2: "Change swap amount",
      Option3: "Try to swap with updated rate",
      NoEth: 'Not enough {{baseCurrency}} to cover transaction fee'
    },
    SuccessTitle: "You have successfully changed <TextBlue>{{from}}</TextBlue> for <TextBlue>{{to}}</TextBlue>",
  },
  Trade: {
    OneClickTrading: "One Click Trading",
  },
  Market: {
    ListWidget: {
      SearchPlaceholder: "Search for DeFund",
    },
    Empty: {
      Title: "No DeFunds found",
    },
    Filter: {
      Title: "Showing {{count}} of {{total}} funds",
      Risk: "Risk",
      High: "High",
      Medium: "Medium",
      Low: "Low",
      ValidityPeriod: "Validity period",
      All: "All",
      Rating: "Rating",
      Options: "Options",
      ExtendedRM: "Extended RM",
      KYCManager: "KYC Manager",
    },
  },
  Fund: {
    Activity: "Activity",
    NoActivity: "No operations yet",
    AUM: "AUM:",
    FundsReceived: "Funds received",
    Withdrawal: "Withdrawal",
    Withdrawn: "Withdrawn",
    ReportPeriod: "Report period:",
    NextReport: "Next report",
    IsPrivate: "Private fund",
    ManagerSettings: "Manager settings",
    ManagerAddress: "Manager address",
    ManagerShare: "Manager share",
    Income: "Income",
    NoDescription: "No description",
    Yield: "Yield",
    PendingPf: "Pending fee",
    RiskManagement: {
      Title: 'Risk management',
      StopOnDeposit: 'Stop on deposit',
      StopOnDepositDesc: 'Triggers when the fund reaches the maximum drawdown. After triggering, trading operations become unavailable, and the fund operates only for withdrawals. Has higher priority than stop on manager. Value is in range from 5% to 100%.',
      StopOnManager: 'Stop on manager',
      StopOnManagerDesc: 'Triggers when the fund reaches a drawdown, calculated from the moment the manager is appointed. After triggering, the fund\'s owner becomes the manager. Has lower priority than stop on deposit, and can\'t be less than stop on deposit. Value is in range from 5% to 100%.',
      ChecklistOnDeposit: 'Fund has <TextBlue>{{deposit}}%</TextBlue> stop on deposit',
      ChecklistOnManager: 'Fund has <TextBlue>{{manager}}%</TextBlue> stop on manager',
      ChecklistBoth: 'Fund has <TextBlue>{{deposit}}%</TextBlue> stop on deposit, and <TextBlue>{{manager}}%</TextBlue> stop on manager',
    },
    CommissionEarned: "Commissions earned",
    CommissionPaid: "Commissions paid",
    UnclaimedCommissions: "Unclaimed fees",
    Claim: "Claim",
    TotalInvestors: "Total investors:",
    AnnualYield: "Annual yield:",
    Investors: "Investors",
    InvestmentDate: "Investment date",
    InvestmentIndicators: "Investment indicators",
    SubscriptionFee: "Subscription fee",
    ManagementFee: "Management fee",
    PerformanceFee: "Performance fee",
    ExecutionFee: "Execution fee",
    ExecutionFeeDescription: "This fee covers report transaction costs and is collected in USDT from the fund's balance at the time of the report <Link>(learn more)</Link>.",
    PerReport: "per report",
    Performance: "Performance",
    InvestmentConditions: "Investment conditions",
    Description: 'Description',
    Commissions: "Commissions",
    WhiteList: "White list",
    Indent: "Indent",
    HWMLevel: "HWM level",
    Top50CapExtended: "TOP50 cap extended",
    Top50CapToBTC: "TOP50 cap to BTC",
    Top50CapToStables: "TOP50 cap to USDT/USDC/DAI",
    AvailableTokens: "Available tokens",
    MyAssets: "My assets in DeFund",
    PendingDeposit: "Pending deposit",
    PendingWithdrawal: "Pending withdrawal",
    MustBeRepaid: "Must be paid",
    EditFund: "Edit DeFund",
    UpdateTime: "Update after",
    BackToFund: "Back to DeFund",
    Trading: "Trading",
    RequestUpdate: "Request update",
    PendingUpdateRequest: "Pending update",
    RequestUpdateManager: "Request update manager",
    CancelUpdateManager: "Cancel update manager",
    TransferOwnership: "Transfer ownership",
    RequestUpdateDescription: "Your update is going to be processed for {{days}} days. After this time you will be able to execute transaction that will finalize the update. Your investors will be notified about the update, and will be able to withdraw their funds before the update is executed.",
    LowAumTooltipText: 'For cost-saving purposes, we do not generate reports for funds with AUM below {{threshold}} USDT. To continue working with the fund, you need to invest an additional <TextBlue>{{needToInvestAmount}} USDT</TextBlue>.',
    Empty: {
      Title: "You haven’t created any DeFunds yet",
      Caption: "Create a DeFund and start trading",
      Button: "Create DeFund",
    },
    Period: {
      Title: "Reporting period",
      Hourly: "Hourly",
      Monthly: "Monthly",
      Weekly: "Weekly",
      Quarterly: "Quarterly",
    },
    HWM: {
      Title: "HWM",
      Enabled: "Enabled",
      Disabled: "Disabled",
    },
    MaxDD: "Max DD",
    Invested: "Invested",
    InvestModal: {
      Title: "You invest in the DeFund with the following parameters",
      AmountLabel: "Enter the amount you want to invest",
      Available: "Available",
      PendingInfo: "Your tokens will be deposited at the beginning of the reporting period",
    },
    WithdrawModal: {
      Title: "Withdraw",
      SuccessText: "You will receive USDT by the time of the report",
      RedemptionLabel: "This is an approximate value, final amount will be calculated and transferred by the time of the report",
      AmountLabel: "Enter the amount you want to withdraw",
      Tokens: "tokens",
      Available: "Available",
      IndentDescription: "You are going to submit your withdraw application during indent - it will be processed in the next reporting period.",
      AmountToReceive: "Amount to receive"
    },
    Button: {
      Details: "Details",
      Trade: "Trade",
      Invest: "Invest",
      Withdraw: "Withdraw",
      BackToMarket: "Back to DeFunds Market",
    },
  },
  CreateFund: {
    Title: "Create DeFund",
    MainInfo: "Main info",
    DAO: {
      Button: "How to create DeFund for DAO",
      Title: "Create DeFund for DAO",
      Description: "Create a simple DeFund or a DAO DeFund through <Link>XDAO</Link> with extended capabilities",
    },
    Name: "Name",
    NamePlaceholder: "Enter name",
    Description: "Description",
    DescriptionPlaceholder: "Enter description",
    WhiteList: "Token whitelist",
    CreateDescription: `You're about to create a new DeFund in {{network}} chain and will have to
confirm a transaction with your currently connected wallet. You will be charged symbolic {{cost}} USDT for creation,
and some {{currency}} for gas. The final fee will be determined by your wallet.`,
    ConfirmCreateFundLabel: "I confirm transaction with my current wallet and agree to pay {{cost}} USDT fee for creating fund. The final fee amount will be determined by my wallet.",
    ConfirmButton: "Confirm",
    Modal: {
      Title: "You create a DeFund with the following parameters",
      Trader: "Trader",
      Service: "Service",
      Investor: "Investor",
      Private: "Fund is closed for external investors",
      PeriodDescription: "Summing up and calculating fees occur <TextBlue>{{period}}</TextBlue> with <TextBlue>{{indent}} indent</TextBlue> if the AUM exceeds <TextBlueCapital>{{threshold}} USDT</TextBlueCapital>",
      HVMDescription: "Take <TextBlue>HWM</TextBlue> into account when calculating commissions",
      ManagerInfo: "Account <TextBlue>{{account}}</TextBlue> is the manager of this fund, who takes <TextBlue>{{share}}%</TextBlue> of fees",
    },
    IamManager: "I trade by myself",
  },
  MyFunds: {
    Total: {
      Title: "Total DeFunds indicators",
      ThisMonth: "this month",
      Commission: "Commission",
      Investors: "Investors",
    },
    Managed: {
      Title: "Managed DeFunds",
      CreateButton: "Create DeFund",
      Active: "Active",
      Closed: "Closed",
    },
  },
  Member: {
    Button: {
      BackToMarket: "Back to DeFunds Market",
    },
  },
  Common: {
    Loading: "Loading",
    Warning: "Warning",
    Edit: "Edit",
    Max: "MAX",
    Image: "Image",
    Processing: "Processing",
    Error: "Error",
    ConfirmTransactionLabel: "I confirm transaction with my current wallet and agree to pay for it. The exact fee amount will be determined by my wallet.",
    Button: {
      Create: "Create",
      Cancel: "Cancel",
      Approve: "Approve",
      Reset: "Reset",
      Apply: "Apply",
      UploadPicture: "Upload image",
      Save: "Save",
      Close: "Close",
      Open: "Open",
      Continue: "Continue",
      Update: "Update",
    },
    Week: "{{count}} week",
    Minute_one: "{{count}} minute",
    Minute_other: "{{count}} minutes",
    Hour_one: "{{count}} hour",
    Hour_other: "{{count}} hours",
    Day_one: "{{count}} day",
    Day_other: "{{count}} days",
    Months: "{{count}} months",
    Clock: {
      Day: "{{count}}d",
      Hour: "{{count}}h",
      Minute: "{{count}}m",
    },
    NoData: "No data yet",
    TimeRange: {
      AllTime: "All time",
      ThisMonth: "This month",
      ThisQuarter: "This quarter",
      ThisYear: "This year",
    },
    ImagePicker: {
      IncorrectFormat: "Only JPG or PNG image formats are allowed",
      SelectSingleFile: "Please select a single file",
      FileIsTooBig: "Max allowed file size is {{size}} MB",
    },
    Anonymous: "Anonymous",
    AuditDisclaimer: "THE CONTRACT IS NOT AUDITED. USE IT AT YOUR OWN RISK.",
  },
  Settings: {
    Title: 'Settings',
    FundOwner: 'Fund owner',
    FundManager: 'Fund manager',
    ManagerShare: 'Manager share',
    ChangeManager: 'Change manager',
    CurrentManager: 'Current manager',
    TradeLock: 'Trade lock',
    LockTrade: 'Lock trade',
    LockUntil: 'Lock until',
    CancelTradeLock: 'Cancel trade lock for {{address}}',
    NewManager: 'Manager change',
    NewShare: 'Share change',
    CurrentTradelockInfo: 'Manager <TextBlue>{{address}}</TextBlue> is not available to trade until <TextBlue>{{date}}</TextBlue>',
    BlockCurrentManager: 'Block trading for the current manager <TextBlue>{{address}}</TextBlue>',
    UpdateManagerWarning: 'The change of manager\'s address and their share will take effect at the next reporting period',
    TransferOwnership: 'Transfer ownership',
    TransferOwnershipWarning: 'Are you sure you want to transfer ownership of the fund <TextBlue>{{fund}}</TextBlue>? You will lose access to fund management; this action cannot be undone. All commissions you earn in this fund will be withdrawn to your address when ownership is transferred.',
    AdminSettings: 'Admin settings',
  },
};

export default translation;
