import styled from 'styled-components';

export const TransparentInput = styled.input`
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  background: #fff0;
  border: none;
  outline: none;
  text-decoration: none !important;
`;

export const TransparentInputContainer = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 20px;
`;
