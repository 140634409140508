import ReactDOM from 'react-dom/client';
import './polyfills';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureRootTheme } from '@yandex/ui/Theme';
import { theme } from '@yandex/ui/Theme/presets/default';
import { Buffer } from 'buffer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './common/i18n/i18n';
import { App } from './app';
import { store } from './store/store';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'rc-slider/assets/index.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import './index.scss';
import './asset/styles/select.scss';
import './asset/styles/Toastify.scss';
import './asset/styles/draft-wysiwyg.scss';
import { AdminContextProvider } from './features/admin/provider';
import { Web3WSProviderContextProvider } from './hooks/use-ws-provider';
import { Toast } from './components/toast';
import { Web3Provider } from './web3/provider';
import { ApolloProvider } from '@apollo/client';
import { defundsSubpgraphClient } from './common/subgraph';

window.Buffer = window.Buffer || Buffer;

configureRootTheme({ theme });

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
	<ApolloProvider client={defundsSubpgraphClient}>
		<Provider store={store}>
			<AdminContextProvider>
				<Web3Provider>
					<Web3WSProviderContextProvider>
						<QueryClientProvider client={queryClient}>
							<HashRouter>
								<Toast />
								<App />
							</HashRouter>
						</QueryClientProvider>
					</Web3WSProviderContextProvider>
				</Web3Provider>
			</AdminContextProvider>
		</Provider>
	</ApolloProvider>,
);
