import { BigNumber, ethers, utils } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Commissions, DraftFund, Fund, FundState } from '../interfaces/fund';
import { Web3Provider } from '@ethersproject/providers';
import { contractAddresses, BASE_DECIMALS, ChainId, ADDRESS_ZERO } from '../common/constants';
import { Erc20 } from './erc20';
import {
	Erc20__factory,
	Feeder,
	Feeder__factory,
	Fees,
	Fees__factory,
	FundFactory,
	FundFactory__factory,
	Interaction,
	Interaction__factory,
	TradeParamsUpgrader,
	TradeParamsUpgrader__factory,
	Trading__factory,
} from './types';
import { TradeParamsUpdateRequest } from '../interfaces/whitelists';

interface CreateParams {
	id: string;
	fund: DraftFund;
	investPeriod: number;
	indent: number;
	whitelistMask: string;
	serviceMask: string;
	manager: string;
	managerShare: string;
	isPrivate: boolean;
}

export interface FundTokenBalance {
	token: string;
	balance: string;
	allowance: string;
}

export interface DefundsContracts {
	interaction: Interaction;
	fees: Fees;
	fundFactory: FundFactory;
	tradeParamsUpgrader: TradeParamsUpgrader;
	feeder: Feeder;
}

export class DefundsEthClient {
	public readonly contracts: DefundsContracts;

	constructor(readonly provider: Web3Provider, readonly chainId: ChainId, readonly account: string) {
		this.contracts = {
			fundFactory: FundFactory__factory.connect(contractAddresses[this.chainId].fundFactory, this.provider.getSigner()),
			interaction: Interaction__factory.connect(contractAddresses[this.chainId].interaction, this.provider.getSigner()),
			fees: Fees__factory.connect(contractAddresses[this.chainId].fees, this.provider.getSigner()),
			tradeParamsUpgrader: TradeParamsUpgrader__factory.connect(contractAddresses[this.chainId].tradeParamsUpgrader, this.provider.getSigner()),
			feeder: Feeder__factory.connect(contractAddresses[this.chainId].feeder, this.provider.getSigner()),
		};
	}

	getCreateFundFee(): Promise<string> {
		return this.contracts.fundFactory.createFundFee().then((r: BigNumber) => r.toString());
	}

	async create({
		id,
		fund,
		indent,
		investPeriod,
		whitelistMask,
		serviceMask,
		manager,
		managerShare,
		isPrivate,
  }: CreateParams): Promise<TransactionResponse> {
		const contract = this.contracts.fundFactory;
		const { subscriptionFee, managementFee, performanceFee, hwm } = fund;
		return contract.newFund({
			id,
			flags: {
				hwm,
				isPrivate,
				managerStopEnabled: fund.riskManagement.managerTrailingStopEnabled,
				globalStopEnabled: fund.riskManagement.globalTrailingStopEnabled,
			},
			numbers: {
				subscriptionFee: subscriptionFee ? utils.parseUnits(String(subscriptionFee / 100)) : 0,
				managementFee: managementFee ? utils.parseUnits(String(managementFee / 100)) : 0,
				performanceFee: performanceFee ? utils.parseUnits(String(performanceFee / 100)) : 0,
				investPeriod,
				indent,
				serviceMask,
				globalStopValue: BigNumber.from(ethers.utils.parseEther(fund.riskManagement.globalTrailingStopValue.toString())).div(100),
				managerStopValue: BigNumber.from(ethers.utils.parseEther(fund.riskManagement.managerTrailingStopValue.toString())).div(100),
				managerShare,
			},
			whitelistMask,
			manager,
		});
	};

	async tokenBalance({ account, fundId }: { fundId: string; account: string }): Promise<FundTokenBalance> {
		const token = await this.contracts.interaction.tokenForFund(fundId);
		const tokenContract = Erc20__factory.connect(token, this.provider.getSigner());

		const [balance, allowance, decimals] = await Promise.all([
			tokenContract.balanceOf(account),
			tokenContract.allowance(account, contractAddresses[this.chainId].interaction),
			Erc20.getDecimals(this.provider.getSigner(), token),
		]);

		return {
			token,
			balance: utils.formatUnits(balance, decimals),
			allowance: utils.formatUnits(allowance, decimals),
		};
	}

	async allowance({ account, token, spender }: { account: string; token: string; spender?: string }): Promise<string> {
		const contract = Erc20__factory.connect(token, this.provider.getSigner());
		const [allowance, decimals] = await Promise.all([
			contract.allowance(
				account,
				spender ?? contractAddresses[this.chainId].interaction,
			),
			Erc20.getDecimals(this.provider.getSigner(), token),
		]);

		return utils.formatUnits(allowance, decimals);
	};

	async approve({
    token,
    amount,
    spender,
  }: { token: string; amount: string; spender?: string }): Promise<TransactionResponse> {
		const contract = Erc20__factory.connect(token, this.provider.getSigner());
		const decimals = await Erc20.getDecimals(this.provider.getSigner(), token);
		return contract.approve(spender ?? contractAddresses[this.chainId].interaction, utils.parseUnits(amount, decimals));
	};

	async stake({ fundId, amount }: { fundId: string; amount: string }): Promise<TransactionResponse> {
		const decimals = await Erc20.getDecimals(this.provider.getSigner(), contractAddresses[this.chainId].token);
		const units = utils.parseUnits(amount, decimals);
		return this.contracts.interaction.stake(fundId, units);
	};

	unstake({ fundId, amount }: { fundId: string; amount: string }): Promise<TransactionResponse> {
		return this.contracts.interaction.unstake(fundId, utils.parseUnits(amount, BASE_DECIMALS));
	};

	async serviceFees(): Promise<Commissions> {
		const [sf, pf, mf] = await this.contracts.fees.serviceFees();

		return {
			sf: Number(utils.formatEther(sf)) * 100,
			pf: Number(utils.formatEther(pf)) * 100,
			mf: Number(utils.formatEther(mf)) * 100,
		};
	};

	getPendingApplications(fundId: string, account: string): Promise<[string, string, string]> {
		return this.contracts.interaction.pendingDepositAndWithdrawals(fundId, account)
			.then((r: [BigNumber, BigNumber, BigNumber]) => r.map(i => i.toString()) as [string, string, string]);
	}

	getFundInfo(fundId: string): Promise<[string, BigNumber, BigNumber, boolean]> {
		return this.contracts.interaction.fundInfo(fundId);
	}

	// TODO: use getter for multiple funds
	async getPendingTvl(fund: Fund): Promise<[BigNumber, BigNumber, BigNumber, BigNumber, BigNumber, BigNumber]> {
		const gasPrice = await this.provider.getGasPrice();
		return this.contracts.interaction.pendingTvl([fund.id], [fund.aum], gasPrice)
			.then(tvls => [
				tvls[0].deposit,
				tvls[0].withdraw,
				tvls[0].pf,
				tvls[0].mustBePaid,
				tvls[0].totalFees,
				tvls[0].stakers,
			]);
	}

	async getPendingParamsUpdateRequest(fund: Fund): Promise<TradeParamsUpdateRequest | null> {
		try {
			const requestId = await this.contracts.tradeParamsUpgrader.lastTxs(fund.tradingAddress);
			if (requestId.toString() === '0') return null;
			const tx = await this.contracts.tradeParamsUpgrader.transactions(requestId);
			if (tx.message === '0x' && tx.destination === ADDRESS_ZERO) {
				return null;
			}
			const unprocessedWithdrawals = await this.contracts.feeder.fundTotalWithdrawals(fund.id);
			const [newWhitelistMask, newServicesMask] = new utils.Interface(
				['function setTradingScope(bytes,uint256)'],
			).decodeFunctionData(
				'setTradingScope',
				tx.message,
			);
			const date = new Date(tx.date.toNumber() * 1000);
			return {
				id: requestId.toString(),
				newServicesMask: newServicesMask.toHexString(),
				newWhitelistMask: newWhitelistMask,
				date: date.toDateString(),
				readyToUpdate: unprocessedWithdrawals.eq(0) && date.getTime() < new Date().getTime(),
			};
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	estimatedWithdraw(fund: Fund, amount: string): Promise<BigNumber> {
		if (amount === '0') return Promise.resolve(BigNumber.from(0));
		return this.contracts.interaction.estimatedWithdrawAmount(fund.id, fund.realAum, amount);
	}

	gatheredCommissions(fund: Fund): Promise<BigNumber> {
		return this.contracts.interaction.totalFees(fund.id);
	}

	cancelDeposit(fundId: string): Promise<TransactionResponse> {
		return this.contracts.interaction.cancelDeposit(fundId);
	}

	cancelWithdraw(fundId: string): Promise<TransactionResponse> {
		return this.contracts.interaction.cancelWithdraw(fundId);
	}

	async closeFund(fundId: string): Promise<TransactionResponse> {
		const [trade] = await this.contracts.interaction.fundInfo(fundId);
		return Trading__factory.connect(trade, this.provider.getSigner()).setState(FundState.Closed);
	}

	async openFund(fundId: string): Promise<TransactionResponse> {
		const [trade] = await this.contracts.interaction.fundInfo(fundId);
		return Trading__factory.connect(trade, this.provider.getSigner()).setState(FundState.Opened);
	}

	unclaimedServiceFees(): Promise<BigNumber> {
		return this.contracts.fees.gatheredServiceFees();
	}

	withdrawServiceFees(): Promise<TransactionResponse> {
		return this.unclaimedServiceFees()
			.then((unclaimedFees: BigNumber) => this.contracts.fees.withdraw(this.account, unclaimedFees));
	}
}
