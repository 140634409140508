import { ethers } from 'ethers';
import { ChainId, networkParams } from '../common/constants';
import { useWeb3React } from '@web3-react/core';
import { createContext, ReactNode, useContext, useMemo } from 'react';

const Web3WSProviderContext = createContext<ethers.providers.WebSocketProvider | null>(null);

export function Web3WSProviderContextProvider({ children }: { children: ReactNode }): JSX.Element {
	const { chainId } = useWeb3React();
	const wsUrl = chainId ? networkParams[chainId as ChainId]?.wsUrl : null;
	const provider = useMemo(
		() => wsUrl ? new ethers.providers.WebSocketProvider(wsUrl, chainId) : null,
		[chainId, wsUrl]
	);
	return <Web3WSProviderContext.Provider value={provider}>{children}</Web3WSProviderContext.Provider>;

}

export function useWsProvider(): ethers.providers.WebSocketProvider | null {
	return useContext(Web3WSProviderContext);
}
