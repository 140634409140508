import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AaveState, PoolData } from './interfaces';
import { RootState } from '../../../store/store';
import { AaveEthClient } from './eth-client';
import { CONFIRMATION_COUNT } from '../../../common/constants';
import { Web3Provider } from '@ethersproject/providers';
import { VARIABLE_APY_RATE_BORROW_MODE } from './constants';
import { BigNumber, utils } from 'ethers';
import { selectDecimals } from '../../../store/slices/decimals';
import { ModalType } from '../../../interfaces/ui';
import { Trading__factory } from '../../../contracts/types';
import { ModalData } from '../../../components/modal/modal-provider';
import { Signer } from '@ethersproject/abstract-signer';
import { applyBuffer, getGasLimits } from '../../../web3/gas-utils';

const initialState: AaveState = {
	data: {},
	loading: false,
};

export const aaveSlice = createSlice({
	name: 'aave',
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<{ chainId: number; address: string; data: Partial<PoolData> }>) => {
			const { chainId, address, data } = action.payload;
			if (!state.data[chainId]) state.data[chainId] = {};
			state.data[chainId][address] = {
				...(state.data[chainId][address] || {}),
				...data,
			};
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
	},
});

export const aaveReducer = aaveSlice.reducer;

export const updateAaveData = createAsyncThunk<unknown, { ethClient: AaveEthClient }, { state: RootState }>(
	'aave/updateAaveData',
	async (
		{ ethClient },
		{ dispatch },
	) => {
		const [reserves, userReserves, reserveIncentiveData, userIncentiveData, balances] = await Promise.all([
			ethClient.getReservesData(),
			ethClient.getUserReservesData(),
			ethClient.getReservesIncentivesDataHumanized(),
			ethClient.getUserReservesIncentivesDataHumanized(),
			ethClient.getUserWalletBalancesForLendingPoolProvider(),
		]);
		dispatch(aaveSlice.actions.updateData({
			data: {
				reserves: reserves.reservesData,
				baseCurrencyData: reserves.baseCurrencyData,
				reserveIncentiveData,
				userIncentiveData,
				balances,
				...userReserves,
			},
			chainId: ethClient.chainId,
			address: ethClient.account,
		}));
	},
);

export const repay = createAsyncThunk<unknown,
	{ ethClient: AaveEthClient; provider: Web3Provider; assetAddress: string; amount: string; },
	{ state: RootState }>(
	'aave/repay',
	async (
		{ ethClient, assetAddress, amount, provider },
		{ dispatch, getState },
	) => {
		try {
			// dispatch(aaveSlice.actions.setLoading(true));
			// const tradingContract = Trading__factory.connect(ethClient.account, provider.getSigner());
			// const decimals = selectDecimals(getState(), { token: assetAddress, chainId: ethClient.chainId });
			// if (!decimals) throw new Error('No fetched decimals for token: ' + assetAddress);
			// const tx = await tradingContract.aaveRepay(assetAddress, utils.parseUnits(amount, decimals), VARIABLE_APY_RATE_BORROW_MODE);
			// await tx.wait(CONFIRMATION_COUNT);
			// dispatch(closeModal(ModalType.AaveRepay));
			// dispatch(openModal({ type: ModalType.Success }));
			// dispatch(updateAaveData({ ethClient }));
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(aaveSlice.actions.setLoading(false));
		}
	},
);

export const borrow = createAsyncThunk<unknown,
	{ ethClient: AaveEthClient; provider: Web3Provider; assetAddress: string; amount: string; },
	{ state: RootState }>(
	'aave/borrow',
	async (
		{ ethClient, assetAddress, amount, provider },
		{ dispatch, getState },
	) => {
		try {
			// dispatch(aaveSlice.actions.setLoading(true));
			// const tradingContract = Trading__factory.connect(ethClient.account, provider.getSigner());
			// const decimals = selectDecimals(getState(), { token: assetAddress, chainId: ethClient.chainId });
			// if (!decimals) throw new Error('No fetched decimals for token: ' + assetAddress);
			// const tx = await tradingContract.aaveBorrow(assetAddress, utils.parseUnits(amount, decimals), VARIABLE_APY_RATE_BORROW_MODE);
			// await tx.wait(CONFIRMATION_COUNT);
			// dispatch(closeModal(ModalType.AaveBorrow));
			// dispatch(openModal({ type: ModalType.Success }));
			// dispatch(updateAaveData({ ethClient }));
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(aaveSlice.actions.setLoading(false));
		}
	},
);

export const supply = createAsyncThunk<unknown,
	{
		ethClient: AaveEthClient;
		signer: Signer;
		assetAddress: string;
		amount: string;
		closeModal: (id: string) => void;
		openModal: ({ type }: ModalData) => void;
	},
	{ state: RootState }>(
	'aave/supply',
	async (
		{ ethClient, assetAddress, amount, signer, closeModal, openModal },
		{ dispatch, getState },
	) => {
		try {
			dispatch(aaveSlice.actions.setLoading(true));
			const tradingContract = Trading__factory.connect(ethClient.account, signer);
			const decimals = selectDecimals(getState(), { token: assetAddress, chainId: ethClient.chainId });
			if (!decimals) throw new Error('No fetched decimals for token: ' + assetAddress);
			const amountUnits = utils.parseUnits(amount, decimals);

			const overrides = await getGasLimits(signer);
			const params: [string, BigNumber] = [assetAddress, amountUnits];
			const gasLimit = await tradingContract.estimateGas.aaveSupply(...params, overrides);
			const tx = await tradingContract.aaveSupply(...params, {
				...overrides,
				gasLimit: applyBuffer(gasLimit),
			});
			await tx.wait(CONFIRMATION_COUNT);

			closeModal(ModalType.AaveSupply);
			openModal({ type: ModalType.Success });
			dispatch(updateAaveData({ ethClient }));
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(aaveSlice.actions.setLoading(false));
		}
	},
);

export const withdraw = createAsyncThunk<unknown,
	{ ethClient: AaveEthClient; signer: Signer; assetAddress: string; amount: string;
		closeModal: (id: string) => void; openModal: ({ type }: ModalData) => void; },
	{ state: RootState }>(
	'aave/withdraw',
	async (
		{ ethClient, assetAddress, amount, signer, closeModal, openModal },
		{ dispatch, getState },
	) => {
		try {
			dispatch(aaveSlice.actions.setLoading(true));
			const tradingContract = Trading__factory.connect(ethClient.account, signer);
			const decimals = selectDecimals(getState(), { token: assetAddress, chainId: ethClient.chainId });
			if (!decimals) throw new Error('No fetched decimals for token: ' + assetAddress);

			const overrides = await getGasLimits(signer);
			const params: [string, BigNumber] = [assetAddress, utils.parseUnits(amount, decimals)];
			const gasLimit = await tradingContract.estimateGas.aaveWithdraw(...params, overrides);
			const tx = await tradingContract.aaveWithdraw(...params, {
				...overrides,
				gasLimit: applyBuffer(gasLimit),
			});
			await tx.wait(CONFIRMATION_COUNT);

			closeModal(ModalType.AaveWithdraw);
			openModal({ type: ModalType.Success });
			dispatch(updateAaveData({ ethClient }));
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(aaveSlice.actions.setLoading(false));
		}
	},
);
