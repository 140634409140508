import { BigNumberish } from '@ethersproject/bignumber';
import { BigNumber, ethers, utils } from 'ethers';
import { getTokens } from './tokens';
import { formatAmount } from './tradingview/api/format-amount';
import { BASIS_POINTS_DIVISOR, PRECISION, USD_DECIMALS } from './constants';
import { EventLogData } from './v2/types/events';
import { GMXTokenInfo } from './types';
import { TokenListItem } from '../../../interfaces/whitelists';
import { ChainId } from '../../../common/constants';

export function expandDecimals(n: BigNumberish, decimals: number): BigNumber {
	return BigNumber.from(n).mul(BigNumber.from(10).pow(decimals));
}

export function getStableTokens(chainId?: number): string[] {
	return getTokens(chainId).filter((t) => t.isStable)
		.map((t) => t.symbol)
}

export function isStableToken(chainId: number, token: string): boolean {
	return getStableTokens(chainId).includes(token);
}


export function mapTokenListItem(token: GMXTokenInfo): TokenListItem {
	return { address: token.address, name: token.symbol };
}
export const timezoneOffset = -new Date().getTimezoneOffset() * 60;
export function adjustForDecimals(amount: BigNumber, divDecimals: number, mulDecimals: number): BigNumber {
	return amount.mul(expandDecimals(1, mulDecimals)).div(expandDecimals(1, divDecimals));
}

// TODO: we should format ethers/tokens the same way everywhere (for example by using formatEther)
export const parseValue = (value: string, tokenDecimals: number): BigNumber | undefined => {
	const pValue = parseFloat(value);

	if (isNaN(pValue)) {
		return undefined;
	}

	value = limitDecimals(value, tokenDecimals);
	const amount = utils.parseUnits(value, tokenDecimals);
	return BigNumber.from(amount);
};

const limitDecimals = (amount: string, maxDecimals?: number): string => {
	let amountStr = amount.toString();
	if (maxDecimals === undefined) {
		return amountStr;
	}
	if (maxDecimals === 0) {
		return amountStr.split(".")[0];
	}
	const dotIndex = amountStr.indexOf(".");
	if (dotIndex !== -1) {
		let decimals = amountStr.length - dotIndex - 1;
		if (decimals > maxDecimals) {
			amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
		}
	}
	return amountStr;
};

export function getFeeStr(fee: BigNumber | null): string {
	return (!fee || !fee.gt(0)) ? '0' : `$${formatAmount(fee, USD_DECIMALS, 2, true)}`;
}


export function getBasisPoints(numerator: BigNumber, denominator: BigNumber, shouldRoundUp = false): BigNumber {
	const result = numerator.mul(BASIS_POINTS_DIVISOR).div(denominator);

	if (shouldRoundUp) {
		const remainder = numerator.mul(BASIS_POINTS_DIVISOR).mod(denominator);
		if (!remainder.isZero()) {
			return result.isNegative() ? result.sub(1) : result.add(1);
		}
	}

	return result;
}

export function applyFactor(value: BigNumber, factor: BigNumber): BigNumber {
	return value.mul(factor).div(PRECISION);
}

export function formatUsd(
	usd?: BigNumber,
	opts: { fallbackToZero?: boolean; displayDecimals?: number; maxThreshold?: string; minThreshold?: string } = {}
): string | undefined {
	const { fallbackToZero = false, displayDecimals = 2 } = opts;

	if (!usd) {
		if (fallbackToZero) {
			usd = BigNumber.from(0);
		} else {
			return undefined;
		}
	}

	const exceedingInfo = getLimitedDisplay(usd, USD_DECIMALS, opts);
	const sign = usd.lt(0) ? "-" : "";
	const displayUsd = formatAmount(exceedingInfo.value, USD_DECIMALS, displayDecimals, false);
	return `${exceedingInfo.symbol}${exceedingInfo.symbol ? " " : ""}${sign}$${displayUsd}`;
}

export function formatDeltaUsd(
	deltaUsd?: BigNumber,
	percentage?: BigNumber,
	opts: { fallbackToZero?: boolean; showPlusForZero?: boolean } = {}
): string | undefined {
	if (!deltaUsd) {
		if (opts.fallbackToZero) {
			return `${formatUsd(BigNumber.from(0))} (${formatAmount(BigNumber.from(0), 2, 2)}%)`;
		}

		return undefined;
	}

	let sign = "";
	if (!deltaUsd.eq(0)) {
		sign = deltaUsd?.gt(0) ? "+" : "-";
	} else if (opts.showPlusForZero) {
		sign = "+";
	}
	const exceedingInfo = getLimitedDisplay(deltaUsd, USD_DECIMALS);
	const percentageStr = percentage ? ` (${sign}${formatPercentage(percentage.abs())})` : "";
	const deltaUsdStr = formatAmount(exceedingInfo.value, USD_DECIMALS, 2, true);

	return `${exceedingInfo.symbol} ${sign}$${deltaUsdStr}${percentageStr}`;
}

const MAX_EXCEEDING_THRESHOLD = "1000000000";
const MIN_EXCEEDING_THRESHOLD = "0.01";
const TRIGGER_PREFIX_ABOVE = ">";
const TRIGGER_PREFIX_BELOW = "<";
function getLimitedDisplay(
	amount: BigNumber,
	tokenDecimals: number,
	opts: { maxThreshold?: string; minThreshold?: string } = {}
): { symbol: string; value: BigNumber; } {
	const { maxThreshold = MAX_EXCEEDING_THRESHOLD, minThreshold = MIN_EXCEEDING_THRESHOLD } = opts;
	const max = expandDecimals(maxThreshold, tokenDecimals);
	const min = ethers.utils.parseUnits(minThreshold, tokenDecimals);
	const absAmount = amount.abs();

	if (absAmount.eq(0)) {
		return {
			symbol: "",
			value: absAmount,
		};
	}

	const symbol = absAmount.gt(max) ? TRIGGER_PREFIX_ABOVE : absAmount.lt(min) ? TRIGGER_PREFIX_BELOW : "";
	const value = absAmount.gt(max) ? max : absAmount.lt(min) ? min : absAmount;

	return {
		symbol,
		value,
	};
}
export function parseEventLogData(eventData: any): EventLogData {
	const ret: any = {};
	for (const typeKey of [
		"addressItems",
		"uintItems",
		"intItems",
		"boolItems",
		"bytes32Items",
		"bytesItems",
		"stringItems",
	]) {
		ret[typeKey] = {};

		for (const listKey of ["items", "arrayItems"]) {
			ret[typeKey][listKey] = {};

			for (const item of eventData[typeKey][listKey]) {
				ret[typeKey][listKey][item.key] = item.value;
			}
		}
	}

	return ret as EventLogData;
}

export function formatPercentage(percentage?: BigNumber, opts: { fallbackToZero?: boolean; signed?: boolean } = {}): string | undefined {
	const { fallbackToZero = false, signed = false } = opts;

	if (!percentage) {
		if (fallbackToZero) {
			return `${formatAmount(BigNumber.from(0), 2, 2)}%`;
		}

		return undefined;
	}

	let sign = "";

	if (signed && !percentage.eq(0)) {
		sign = percentage?.gt(0) ? "+" : "-";
	}

	return `${sign}${formatAmount(percentage.abs(), 2, 2)}%`;
}

export function roundUpDivision(a: BigNumber, b: BigNumber): BigNumber {
	return a.add(b).sub(1).div(b);
}

export function roundUpMagnitudeDivision(a: BigNumber, b: BigNumber): BigNumber {
	if (a.lt(0)) {
		return a.sub(b).add(1).div(b);
	}

	return a.add(b).sub(1).div(b);
}

export function applySlippageToPrice(allowedSlippage: number, price: BigNumber, isIncrease: boolean, isLong: boolean): BigNumber {
	const shouldIncreasePrice = getShouldUseMaxPrice(isIncrease, isLong);

	const slippageBasisPoints = shouldIncreasePrice
		? BASIS_POINTS_DIVISOR + allowedSlippage
		: BASIS_POINTS_DIVISOR - allowedSlippage;

	return price.mul(Math.round(slippageBasisPoints)).div(BASIS_POINTS_DIVISOR);
}

export function getShouldUseMaxPrice(isIncrease: boolean, isLong: boolean): boolean {
	return isIncrease ? isLong : !isLong;
}

export function convertToContractPrice(price: BigNumber, tokenDecimals: number): BigNumber {
	return price.div(expandDecimals(1, tokenDecimals));
}


export const formatAmountFree = (amount: BigNumberish, tokenDecimals: number, displayDecimals?: number): string => {
	if (!amount) {
		return "...";
	}
	let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
	amountStr = limitDecimals(amountStr, displayDecimals);
	return trimZeroDecimals(amountStr);
};

export const trimZeroDecimals = (amount: string): string => {
	if (parseFloat(amount) === parseInt(amount)) {
		return parseInt(amount).toString();
	}
	return amount;
};

export function isSupportedNetwork(chainId: number): boolean {
	return chainId === ChainId.Arbitrum;
}
