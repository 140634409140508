import { TextGray } from "../../components/modal/common.styled";
import Loading from "../../components/UI/loading";
import { add } from "mathjs";
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { flexGap } from "../../common/flex-gap";
import { Commissions } from '../../interfaces/fund';
import { ChainId, DRIP_GAS_USAGE } from '../../common/constants';
import { useWeb3React } from '@web3-react/core';
import { formatEther } from '../../common/utils';
import { Tooltip } from '../../components/UI/tooltip';
import { ExternalLink } from '../../components/UI/external-link';
import React from 'react';
import { useBaseCurrencyPrice } from '../../hooks/use-base-currency-price';
import { useServiceFees } from '../../hooks/use-service-fees';

const TableContainer = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 8px;
  overflow: hidden;
`;

const TableHead = styled.div`
  background: #ffffff;
  padding: 12px;
  min-height: 52px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #6f767e;
  ${flexGap({ row: "12px", column: "8px" })}
`;

const TableCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(25% - 8px);
  padding-left: 8px;
  padding-right: 8px;
  &:first-child {
    text-align: initial;
    justify-content: flex-start;
  }
`;

const EfTableCol = styled(TableCol)`
  padding: 0;
  width: calc(33%);
`;

const TableBody = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
`;

const TableRow = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: 52px;
  display: flex;
  align-items: center;
  ${flexGap({ row: "12px", column: "8px" })}
  &:nth-child(odd) {
    background: rgba(244, 244, 244, 0.5);
  }
`;

export function FeesTable({ fees, chainId }: { fees?: Commissions | null; chainId: ChainId }): JSX.Element | null {
  const { t } = useTranslation();
  const { provider } = useWeb3React();
  const baseCurrencyPrice = useBaseCurrencyPrice(chainId);
  const serviceFees = useServiceFees(chainId);

  const { data: gasPrice } = useQuery(
    ['gasPrice', chainId],
    () => provider?.getGasPrice(),
    { refetchInterval: 10000 }
  );

  const ef = gasPrice && baseCurrencyPrice
    ? formatEther(gasPrice.mul(DRIP_GAS_USAGE).mul(baseCurrencyPrice), 18, 2)
    : null;

  return (
    <TableContainer>
      <TableHead>
        <TableCol />
        <TableCol>{t('CreateFund.Modal.Trader')}</TableCol>
        <TableCol>{t('CreateFund.Modal.Service')}</TableCol>
        <TableCol>{t('CreateFund.Modal.Investor')}</TableCol>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCol>
            <div>
              {t('Fund.SubscriptionFee')}<TextGray> (SF)</TextGray>
            </div>
          </TableCol>
          <TableCol>{fees?.sf}%</TableCol>
          {serviceFees?.sf !== undefined ? (
            <TableCol>{serviceFees?.sf}%</TableCol>
          ) : (
            <TableCol>
              <Loading variant="secondary" />
            </TableCol>
          )}
          {serviceFees?.sf !== undefined && fees?.sf !== undefined ? (
            <TableCol>
              {add(fees.sf, serviceFees.sf)}%
            </TableCol>
          ) : (
            <TableCol>
              <Loading variant="secondary" />
            </TableCol>
          )}
        </TableRow>
        {/*<TableRow>
          <TableCol>
            <div>
              {t('Fund.ManagementFee')}<TextGray> (MF)</TextGray>
            </div>
          </TableCol>
          <TableCol>{fees?.mf}%</TableCol>
          {serviceFees?.mf !== undefined ? (
            <TableCol>{serviceFees?.mf}%</TableCol>
          ) : (
            <TableCol>
              <Loading color="#000" />
            </TableCol>
          )}
          {serviceFees?.mf !== undefined && fees?.mf !== undefined ? (
            <TableCol>
              {add(fees.mf, serviceFees?.mf)}%
            </TableCol>
          ) : (
            <TableCol>
              <Loading color="#000" />
            </TableCol>
          )}
        </TableRow>*/}
        <TableRow>
          <TableCol>
            <div>
              {t('Fund.PerformanceFee')}<TextGray> (PF)</TextGray>
            </div>
          </TableCol>
          <TableCol>{fees?.pf}%</TableCol>
          {serviceFees?.pf !== undefined ? (
            <TableCol>{serviceFees?.pf}%</TableCol>
          ) : (
            <TableCol>
              <Loading variant="secondary" />
            </TableCol>
          )}
          {serviceFees?.pf !== undefined && fees?.pf !== undefined ? (
            <TableCol>
              {add(fees.pf, serviceFees?.pf)}%
            </TableCol>
          ) : (
            <TableCol>
              <Loading variant="secondary" />
            </TableCol>
          )}
        </TableRow>
        <TableRow>
          <TableCol>
            <div>
              {t('Fund.ExecutionFee')}<TextGray> (EF)</TextGray>
            </div>
          </TableCol>
          <TableCol />
          <EfTableCol>
            {chainId && ef && <>
              ~{ef} USDT {t('Fund.PerReport')}
              <Tooltip
                content={<Trans
                  i18nKey="Fund.ExecutionFeeDescription"
                  components={{ Link: <ExternalLink href="https://defunds.gitbook.io/defunds/fees#execution-fee" /> }}
                />}
              />
            </>}
            {!(chainId && ef) && <Loading variant="secondary" />}
          </EfTableCol>
          <TableCol />
        </TableRow>
      </TableBody>
    </TableContainer>
  );
}
