import { EnabledServices, Token } from '../../interfaces/whitelists';
import { boolArrayToHex, hexToBoolArray } from '../../common/utils';
import { DEFAULT_ENABLED_SERVICES } from '../../common/constants';

export function allowedTokensToWhitelistMask(allTokens: Token[], allowedTokens: string[]): string {
	const indexes = allTokens.map(t => t.index).sort((a, b) => b - a);
	const maxIndex = indexes[0];

	const boolArr: boolean[] = [];
	for (let i = 0; i <= maxIndex; i++) {
		const token = allTokens.find(t => t.index === i);
		boolArr.push(token !== undefined && allowedTokens.includes(token.address.toLowerCase()));
	}

	return boolArrayToHex(boolArr);
}

export function whitelistMaskToAllowedTokens(allTokens: Token[], mask: string): Token[] {
	const indices = hexToBoolArray(mask);
	const result = [];
	for (let i = 0; i < indices.length; i++) {
		if (indices[i]) {
			const token = allTokens.find(t => t.index === i);
			if (token) {
				result.push(token);
			}
		}
	}
	return result;
}

export function maskToEnabledServices(mask: string): EnabledServices {
	const bits = hexToBoolArray(mask);
	return { ...DEFAULT_ENABLED_SERVICES, gmx: bits[0], aave: bits[1] };
}

export function enabledServicesToMask(enabledServices: EnabledServices): string {
	return boolArrayToHex([enabledServices.gmx, enabledServices.aave]);
}
