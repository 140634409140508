import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

const SpinnerContainer = styled.div<{ size: number; }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${(val) => `${val.size}px`};
	height: ${(val) => `${val.size}px`};
	margin-right: 6px;
`;

const Spinner = styled.div<{ size: number; }>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: ${(val) => `${val.size}px`};
	height: ${(val) => `${val.size}px`};
`;

const SpinnerElement0 = styled.div<{ size: number; color: string; }>`
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: ${(val) => `${val.size - 8}px`};
	height: ${(val) => `${val.size - 8}px`};
	border: ${(val) => `2px solid ${val.color}`};
	border-radius: 50%;
	animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: ${(val) => `${val.color} transparent transparent transparent`};
`;

const SpinnerElement1 = styled(SpinnerElement0)`
	animation-delay: -0.45s;
`;

const SpinnerElement2 = styled(SpinnerElement0)`
	animation-delay: -0.3s;
`;

const SpinnerElement3 = styled(SpinnerElement0)`
	animation-delay: -0.15s;
`;

export type LoadingVariant = 'default' | 'primary' | 'secondary';

export interface LoadingProps {
	variant?: LoadingVariant;
	size?: number;
	className?: string;
}

const colors: Record<LoadingVariant, string> = {
	default: '#fff',
	primary: '#2a85ff',
	secondary: '#000',
};

function Loading({ variant = 'default', size = 24, className }: LoadingProps): JSX.Element | null {
	const color = colors[variant];

	return (
		<SpinnerContainer size={size} className={className}>
			<Spinner size={size}>
				<SpinnerElement0 size={size} color={color} />
				<SpinnerElement1 size={size} color={color} />
				<SpinnerElement2 size={size} color={color} />
				<SpinnerElement3 size={size} color={color} />
			</Spinner>
		</SpinnerContainer>
	);
}

export default Loading;
