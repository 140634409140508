import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en';
import ru from './ru';

const resources = {
	en: {
		translation: en,
	},
	ru: {
		translation: ru,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'en',
		supportedLngs: ['en', 'ru'],
		fallbackLng: 'en',
		debug: true,
		//ns: ["common", "index", "errors"],
		react: {
			useSuspense: false,
		},
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: [
				'querystring',
				'cookie',
				'localStorage',
				'sessionStorage',
				'navigator',
			],
			lookupQuerystring: 'lng',
			lookupCookie: 'i18next',
			lookupLocalStorage: 'i18nextLng',
			lookupSessionStorage: 'i18nextLng',
			lookupFromPathIndex: 0,
			lookupFromSubdomainIndex: 0,
			caches: ['localStorage', 'cookie'],
			cookieOptions: { path: '/' },
		},
	});

export default i18n;
