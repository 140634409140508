import { ChartData } from '../interfaces/fund';
import { TimePeriod } from '../interfaces/ui';
import { BigNumber, ethers, utils } from 'ethers';
import { ChangeEvent } from 'react';
import { TOKEN_PRECISION, USDT_PRECISION } from './constants';
import { BigNumberish } from '@ethersproject/bignumber';
import { formatUsd } from '../features/trade/gmx/utils';
import { formatAmount } from '../features/trade/gmx/tradingview/api/format-amount';

export function toHex(num: string | number): string {
	const val = Number(num);
	return '0x' + val.toString(16);
}

export function capitalizeFirstLetter(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

function padTo2Digits(num: number): string {
	return num.toString().padStart(2, '0');
}

export function formatDate(date: Date): string {
	return [
		padTo2Digits(date.getDate()),
		padTo2Digits(date.getMonth() + 1),
		date.getFullYear(),
	].join('.');
}

export function isEmptyChart(chartData: ChartData[]): boolean {
	return chartData.length < 3 || chartData.every(item => item.rate === '0' || item.rate === '0.00');
}

export function sleep(ms: number): Promise<void> {
	return new Promise<void>((resolve) => {
		setTimeout(() => resolve(), ms);
	});
}

export function getQueryString(params: Record<string, string | number | undefined | null | (string)[]>): string {
	const query = new URLSearchParams();
	Object.entries(params).forEach(([key, value]) => {
		if (value !== null && value !== undefined) {
			if (Array.isArray(value)) {
				if (value.length > 0) query.append(key, value.join(','));
			} else {
				query.append(key, String(value));
			}
		}
	});
	return query.toString() ? `?${query.toString()}` : '';
}

export function getUnixTimeRange(period: TimePeriod): [number, number] {
	const mapPeriodToFrom: Record<TimePeriod, number> = {
		[TimePeriod.AllTime]: 0,
		[TimePeriod.Year]: new Date().setFullYear(new Date().getFullYear() - 1),
		[TimePeriod.Quarter]: new Date().setMonth(new Date().getMonth() - 3),
		[TimePeriod.Month]: new Date().setMonth(new Date().getMonth() - 1),
	};

	return [mapPeriodToFrom[period], Date.now()];
}

export function trimEndZeros(str: string): string {
	return str.replace(/0+$/, '').replace(/\.$/, '');
}

export function formatEther(
	value: BigNumberish,
	decimals: number,
	precision = USDT_PRECISION,
	roundingMode: RoundingMode = 'round',
): string {
	const formattedValue = Number(utils.formatUnits(value, decimals).replace(',', '.'));
	let round = Math.round;
	switch (roundingMode) {
		case 'ceil':
			round = Math.ceil;
			break;
		case 'floor':
			round = Math.floor;
			break;
		case 'round':
			round = Math.round;
			break;
		default:
			break;
	}
	const roundedValue = round(formattedValue * 10 ** precision) / 10 ** precision;
	return String(roundedValue) || '0';
}

export function formatInputValue(e: ChangeEvent<HTMLInputElement>): string | null {
	return e.target.value.replace(',', '.') || null;
}

type RoundingMode = 'floor' | 'round' | 'ceil'

export function formatBalance(balance: string | number, precision = USDT_PRECISION, roundingMode: RoundingMode = 'floor'): string {
	if (Number(balance) < 0) return '0';
	const multiplier = Math.pow(10, precision);
	let round = Math.round;
	switch (roundingMode) {
		case 'ceil':
			round = Math.ceil;
			break;
		case 'floor':
			round = Math.floor;
			break;
		case 'round':
			round = Math.round;
			break;
		default:
			break;
	}
	const rounded = (round(Number(balance) * multiplier) / multiplier).toFixed(precision);
	return trimEndZeros(rounded.replace(',', '.'));
}

export function getBalancePrecision(decimals: number, tokenPrecision = TOKEN_PRECISION): number {
	return decimals > 6 ? tokenPrecision : USDT_PRECISION;
}

export function formatTokenAmount(value: string | number, decimals: number): string {
	return decimals > 7 ? formatBalance(value, 7) : formatBalance(value, 2);
}


export function formatTokenAmountWithUsd(
	tokenAmount?: BigNumber,
	usdAmount?: BigNumber,
	tokenSymbol?: string,
	tokenDecimals?: number,
): string | undefined {
	if (!tokenAmount || !usdAmount || !tokenSymbol || !tokenDecimals) {
		return;
	}

	const tokenStr = formatAmount(tokenAmount.toString(), tokenDecimals);
	const usdStr = formatUsd(usdAmount);

	return `${tokenStr} ${tokenSymbol} (${usdStr})`;
}

export function etherToPercents(eth: string): string {
	return (Number(eth) * 100 - 100).toFixed(2);
}

export function percentsToEth(percents: number): string {
	return ethers.utils.parseEther(String(percents / 100)).toString();
}

export function deleteKeyFromObject<T>(obj: T, key: keyof T): Omit<T, typeof key> {
	const { [key]: deletedKey, ...rest } = obj;
	return rest;
}

export function shortenAddress(address: string): string {
	if (address.length < 11) {
		return address;
	} else {
		return `${address.slice(0, 6)}...${address.slice(-4)}`;
	}
}

export function processApiResponse(r: Response): any {
	if (r.status !== 200) {
		return r.json().then((data) => {
			throw new Error(data.code);
		});
	}
	return r.json();
}

export function boolArrayToHex(boolArray: boolean[]): string {
	let result = '';

	for (let i = 0; i < boolArray.length; i++) {
		result += boolArray[i] ? '1' : '0';
	}
	let hex = parseInt(result.split('').reverse().join(''), 2).toString(16);

	if (hex.length % 2 !== 0) {
		hex = '0' + hex;
	}

	return `0x${hex}`;
}

export function hexToBoolArray(mask: string): boolean[] {
	let result: string[] = [];
	for (let i = 2; i < mask.length; i++) {
		result.push(parseInt(mask[i], 16).toString(2).padStart(4, '0'));
	}
	return result.join('')
		.split('')
		.reverse()
		.map(b => b === '1');
}

export function shortenAddressOrEns(address: string, length: number): string {
	if (!length) {
		return '';
	}
	if (!address) {
		return address;
	}
	if (address.length < 10) {
		return address;
	}
	let left = address.includes('.') ? address.split('.')[1].length : Math.floor((length - 3) / 2) + 1;
	return address.substring(0, left) + '...' + address.substring(address.length - (length - (left + 3)), address.length);
}

export async function fetchVerify(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
	const response = await fetch(input, init)
	if (!response.ok) {
		const body = await response.text()
		throw new Error(`${response.status}: ${body}`)
	}
	return response
}

export function formatEtherPercent(value: BigNumber): string {
	return `${ethers.utils.formatEther(value.mul(100))}%`;
}
