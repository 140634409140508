import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectWallet, setWallet } from '../../store/slices/app';
import { Wallet } from '../../interfaces/web3';
import { WALLET_LOCALSTORAGE_KEY } from '../../common/constants';
import { selectIsUnsupportedChainId, selectWalletIsRestoringModal, setIsRestoring } from '../../store/slices/wallet';
import { availableWallets } from './wallets';

export interface UseWalletResult {
	connect: (wallet: Wallet, eagerly?: boolean) => Promise<Wallet>,
	disconnect: () => void;
	selectedWallet: Wallet | null;
	isRestoringWallet: boolean;
	isUnsupportedChainId: boolean;
}

export function useWallet(): UseWalletResult {
	const dispatch = useDispatch();
	const isRestoringWallet = useSelector(selectWalletIsRestoringModal);
	const isUnsupportedChainId = useSelector(selectIsUnsupportedChainId);
	const selectedWalletKey = useSelector(selectWallet);
	const selectedWallet = availableWallets.find(w => w.key === selectedWalletKey) || null;
	const { connector } = useWeb3React();

	const connect = useCallback((wallet: Wallet, eagerly?: boolean) => ((eagerly && wallet.connector.connectEagerly)
			? wallet.connector.connectEagerly()
			: wallet.connector.activate())
			.then(() => {
				localStorage.setItem(WALLET_LOCALSTORAGE_KEY, wallet.key);
				dispatch(setWallet(wallet.key));
				setTimeout(() => dispatch(setIsRestoring(false)));
				return wallet;
			})
			.catch((e) => {
				dispatch(setIsRestoring(false));
				throw e;
			}),
		[dispatch],
	);

	const disconnect = useCallback(() => {
		localStorage.removeItem(WALLET_LOCALSTORAGE_KEY);
		dispatch(setWallet(null));
		if (connector?.deactivate) {
			connector?.deactivate();
		} else {
			connector?.resetState();
		}
	}, [connector, dispatch]);

	return { connect, disconnect, selectedWallet, isRestoringWallet, isUnsupportedChainId };
}