import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { Turnstile } from '@marsidev/react-turnstile';
import { hooks as metaMaskHooks, metaMask } from './metamask';
import { hooks as walletConnectHooks, walletConnectV2 } from './wallet-connect';
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { providers } from 'ethers';
import { ChainId, REACT_APP_API_URL, REACT_APP_TURNSTILE_SITE_KEY, networkParams } from '../common/constants';

const connectors: [MetaMask | WalletConnect, Web3ReactHooks][] = [
	[metaMask, metaMaskHooks],
	[walletConnectV2, walletConnectHooks],
];

export type ProvidersContextValue = Record<ChainId, providers.JsonRpcProvider>;

export const ProvidersContext = createContext<ProvidersContextValue | null>(null);

export function Web3Provider({ children }: { children: ReactNode }): JSX.Element {
	const [authorizedHeaders, setAuthorizedHeaders] = useState<undefined | { [key: string]: string | number }>()

	const providersValue: ProvidersContextValue = useMemo(() => Object.keys(networkParams).reduce((acc, chainId) => {
		const authorized = authorizedHeaders !== undefined
		return {
			...acc,
			[chainId]: new providers.JsonRpcProvider({
				headers: authorizedHeaders,
				url: authorized
					? networkParams[chainId].networkProviderUrl || networkParams[chainId].rpcUrls[0]
					: networkParams[chainId].rpcUrls[0]
			}),
		}
	}, {} as ProvidersContextValue), [authorizedHeaders]);

	const validateToken = useCallback(async (token: string) => {
		try {
			const result = await fetch(`${REACT_APP_API_URL}verify?token=${token}`)
			if (!result.ok) return
			const { serverToken } = await result.json()
			setAuthorizedHeaders({
				'x-server-token': serverToken
			})
		} catch (e) {
			console.error(e)
		}
	}, [setAuthorizedHeaders])

	return (
		<ProvidersContext.Provider value={providersValue}>
			<Turnstile options={{ size: 'invisible' }} siteKey={REACT_APP_TURNSTILE_SITE_KEY} onSuccess={validateToken}/>
			<Web3ReactProvider connectors={connectors}>
				{children}
			</Web3ReactProvider>
		</ProvidersContext.Provider>
	);
}
