import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { WalletState } from '../../interfaces/web3';
import { WALLET_LOCALSTORAGE_KEY } from '../../common/constants';

const initialState: WalletState = {
	isRestoring: !!localStorage.getItem(WALLET_LOCALSTORAGE_KEY),
	isUnsupportedChainId: false,
};

export const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setIsRestoring: (state, action: PayloadAction<boolean>) => {
			state.isRestoring = action.payload;
		},
		setIsUnsupportedChainId: (state, action: PayloadAction<boolean>) => {
			state.isUnsupportedChainId = action.payload;
		},
	},
});

export const { setIsRestoring, setIsUnsupportedChainId } = walletSlice.actions;

export const selectWalletIsRestoringModal = (state: RootState): boolean => state.wallet.isRestoring;
export const selectIsUnsupportedChainId = (state: RootState): boolean => state.wallet.isUnsupportedChainId;

export const walletReducer = walletSlice.reducer;
