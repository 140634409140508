import { getSwapFee } from '../../fees';
import {
	MarketInfo,
	MarketsInfoData,
	getAvailableUsdLiquidityForCollateral,
	getOppositeCollateral,
	getTokenPoolType,
} from '../../markets';
import { BigNumber, ethers } from 'ethers';
import { applySwapImpactWithCap, getPriceImpactForSwap } from '../../fees';
import { SwapPathStats, SwapStats } from '../types';
import { NATIVE_TOKEN_ADDRESS } from '../../../../tokens';
import { getByKey, getByLowercasedKey } from '../../positions';
import { convertToTokenAmount, convertToUsd } from '../../tokens';

export function getSwapPathOutputAddresses(p: {
	marketsInfoData: MarketsInfoData;
	initialCollateralAddress: string;
	swapPath: string[];
	wrappedNativeTokenAddress: string;
	shouldUnwrapNativeToken: boolean;
}): { outTokenAddress: string | undefined; outMarketAddress: string | undefined } {
	const { marketsInfoData, initialCollateralAddress, swapPath, wrappedNativeTokenAddress, shouldUnwrapNativeToken } = p;

	if (swapPath.length === 0) {
		return {
			outTokenAddress:
				shouldUnwrapNativeToken && initialCollateralAddress === wrappedNativeTokenAddress
					? NATIVE_TOKEN_ADDRESS
					: initialCollateralAddress,

			outMarketAddress: undefined,
		};
	}

	const [firstMarketAddress, ...marketAddresses] = swapPath;

	let outMarket = getByKey(marketsInfoData, firstMarketAddress);

	if (!outMarket) {
		return {
			outTokenAddress: undefined,
			outMarketAddress: undefined,
		};
	}

	let outTokenType = getTokenPoolType(outMarket, initialCollateralAddress);
	let outToken = outTokenType === 'long' ? outMarket.shortToken : outMarket.longToken;

	for (const marketAddress of marketAddresses) {
		outMarket = getByKey(marketsInfoData, marketAddress);

		if (!outMarket) {
			return {
				outTokenAddress: undefined,
				outMarketAddress: undefined,
			};
		}

		outTokenType = outMarket.longTokenAddress.toLowerCase() === outToken.address.toLowerCase() ? 'short' : 'long';
		outToken = outTokenType === 'long' ? outMarket.longToken : outMarket.shortToken;
	}

	const outTokenAddress =
		shouldUnwrapNativeToken && outToken.address === wrappedNativeTokenAddress ? NATIVE_TOKEN_ADDRESS : outToken.address;

	return {
		outTokenAddress,
		outMarketAddress: outMarket.marketTokenAddress,
	};
}

export function getMaxSwapPathLiquidity(p: {
	marketsInfoData: MarketsInfoData;
	swapPath: string[];
	initialCollateralAddress: string;
}): BigNumber {
	const { marketsInfoData, swapPath, initialCollateralAddress } = p;

	if (swapPath.length === 0) {
		return BigNumber.from(0);
	}

	let minMarketLiquidity = ethers.constants.MaxUint256;
	let tokenInAddress = initialCollateralAddress;

	for (const marketAddress of swapPath) {
		const marketInfo = getByLowercasedKey(marketsInfoData, marketAddress);

		if (!marketInfo) {
			return BigNumber.from(0);
		}

		const tokenOut = getOppositeCollateral(marketInfo, tokenInAddress);

		if (!tokenOut) {
			return BigNumber.from(0);
		}

		const isTokenOutLong = getTokenPoolType(marketInfo, tokenOut.address) === 'long';
		const liquidity = getAvailableUsdLiquidityForCollateral(marketInfo, isTokenOutLong);

		if (liquidity.lt(minMarketLiquidity)) {
			minMarketLiquidity = liquidity;
		}

		tokenInAddress = tokenOut.address;
	}

	if (minMarketLiquidity.eq(ethers.constants.MaxUint256)) {
		return BigNumber.from(0);
	}

	return minMarketLiquidity;
}

export function getSwapPathStats(p: {
	marketsInfoData: MarketsInfoData;
	swapPath: string[];
	initialCollateralAddress: string;
	wrappedNativeTokenAddress: string;
	usdIn: BigNumber;
	shouldUnwrapNativeToken: boolean;
	shouldApplyPriceImpact: boolean;
}): SwapPathStats | undefined {
	const {
		marketsInfoData,
		swapPath,
		initialCollateralAddress,
		usdIn,
		shouldUnwrapNativeToken,
		shouldApplyPriceImpact,
		wrappedNativeTokenAddress,
	} = p;

	if (swapPath.length === 0) {
		return undefined;
	}

	const swapSteps: SwapStats[] = [];

	let usdOut = usdIn;

	let tokenInAddress = initialCollateralAddress;
	let tokenOutAddress = initialCollateralAddress;

	let totalSwapPriceImpactDeltaUsd = BigNumber.from(0);
	let totalSwapFeeUsd = BigNumber.from(0);

	for (let i = 0; i < swapPath.length; i++) {
		const marketAddress = swapPath[i];
		const marketInfo = marketsInfoData[marketAddress];

		tokenOutAddress = getOppositeCollateral(marketInfo, tokenInAddress)!.address;

		if (i === swapPath.length - 1 && shouldUnwrapNativeToken && tokenOutAddress === wrappedNativeTokenAddress) {
			tokenOutAddress = NATIVE_TOKEN_ADDRESS;
		}

		const swapStep = getSwapStats({
			marketInfo,
			tokenInAddress,
			tokenOutAddress,
			usdIn: usdOut,
			shouldApplyPriceImpact,
		});

		tokenInAddress = swapStep.tokenOutAddress;
		usdOut = swapStep.usdOut;

		totalSwapPriceImpactDeltaUsd = totalSwapPriceImpactDeltaUsd.add(swapStep.priceImpactDeltaUsd);
		totalSwapFeeUsd = totalSwapFeeUsd.add(swapStep.swapFeeUsd);

		swapSteps.push(swapStep);
	}

	const lastStep = swapSteps[swapSteps.length - 1];
	const targetMarketAddress = lastStep.marketAddress;
	const amountOut = lastStep.amountOut;

	const totalFeesDeltaUsd = BigNumber.from(0).sub(totalSwapFeeUsd).add(totalSwapPriceImpactDeltaUsd);

	return {
		swapPath,
		tokenInAddress,
		tokenOutAddress,
		targetMarketAddress,
		swapSteps,
		usdOut,
		amountOut,
		totalSwapFeeUsd,
		totalSwapPriceImpactDeltaUsd,
		totalFeesDeltaUsd,
	};
}

export function getSwapStats(p: {
	marketInfo: MarketInfo;
	tokenInAddress: string;
	tokenOutAddress: string;
	usdIn: BigNumber;
	shouldApplyPriceImpact: boolean;
}): SwapStats {
	const { marketInfo, tokenInAddress, tokenOutAddress, usdIn, shouldApplyPriceImpact } = p;

	const isWrap = tokenInAddress === NATIVE_TOKEN_ADDRESS;
	const isUnwrap = tokenOutAddress === NATIVE_TOKEN_ADDRESS;

	const tokenIn =
		getTokenPoolType(marketInfo, tokenInAddress) === 'long' ? marketInfo.longToken : marketInfo.shortToken;

	const tokenOut =
		getTokenPoolType(marketInfo, tokenOutAddress) === 'long' ? marketInfo.longToken : marketInfo.shortToken;

	const priceIn = tokenIn.prices.minPrice;
	const priceOut = tokenOut.prices.maxPrice;

	const amountIn = convertToTokenAmount(usdIn, tokenIn.decimals, priceIn)!;

	let priceImpactDeltaUsd: BigNumber;

	try {
		priceImpactDeltaUsd = getPriceImpactForSwap(marketInfo, tokenIn, tokenOut, usdIn, usdIn.mul(-1));
	} catch (e) {
		return {
			swapFeeUsd: BigNumber.from(0),
			swapFeeAmount: BigNumber.from(0),
			isWrap,
			isUnwrap,
			marketAddress: marketInfo.marketTokenAddress,
			tokenInAddress,
			tokenOutAddress,
			priceImpactDeltaUsd: BigNumber.from(0),
			amountIn,
			amountInAfterFees: amountIn,
			usdIn,
			amountOut: BigNumber.from(0),
			usdOut: BigNumber.from(0),
			isOutLiquidity: true,
		};
	}

	const swapFeeAmount = getSwapFee(marketInfo, amountIn, priceImpactDeltaUsd.gt(0));
	const swapFeeUsd = getSwapFee(marketInfo, usdIn, priceImpactDeltaUsd.gt(0));

	const amountInAfterFees = amountIn.sub(swapFeeAmount);
	const usdInAfterFees = usdIn.sub(swapFeeUsd);

	let usdOut = usdInAfterFees;
	let amountOut = convertToTokenAmount(usdOut, tokenOut.decimals, priceOut)!;

	let cappedImpactDeltaUsd: BigNumber;

	if (priceImpactDeltaUsd.gt(0)) {
		const positiveImpactAmount = applySwapImpactWithCap(marketInfo, tokenOut, priceImpactDeltaUsd);
		cappedImpactDeltaUsd = convertToUsd(positiveImpactAmount, tokenOut.decimals, priceOut)!;
	} else {
		const negativeImpactAmount = applySwapImpactWithCap(marketInfo, tokenIn, priceImpactDeltaUsd);
		cappedImpactDeltaUsd = convertToUsd(negativeImpactAmount, tokenIn.decimals, priceIn)!;
	}

	if (shouldApplyPriceImpact) {
		usdOut = usdOut.add(cappedImpactDeltaUsd);
	}

	if (usdOut.lt(0)) {
		usdOut = BigNumber.from(0);
	}

	amountOut = convertToTokenAmount(usdOut, tokenOut.decimals, priceOut)!;

	const liquidity = getAvailableUsdLiquidityForCollateral(
		marketInfo,
		getTokenPoolType(marketInfo, tokenOutAddress) === 'long',
	);

	const isOutLiquidity = liquidity.lt(usdOut);

	return {
		swapFeeUsd,
		swapFeeAmount,
		isWrap,
		isUnwrap,
		marketAddress: marketInfo.marketTokenAddress,
		tokenInAddress,
		tokenOutAddress,
		priceImpactDeltaUsd: cappedImpactDeltaUsd,
		amountIn,
		amountInAfterFees,
		usdIn,
		amountOut,
		usdOut,
		isOutLiquidity,
	};
}
