import { useQuery } from '@tanstack/react-query';
import * as API from '../features/whitelists/api';
import { Token } from '../interfaces/whitelists';
import { useMemo } from 'react';

export interface UseTokensResult {
	tokens: Token[];
	refetchTokens: () => void;
}

export function useTokens(chainId?: number): UseTokensResult {
	const {
		data: tokens = [],
		refetch: refetchTokens,
	} = useQuery(['tokens', chainId], () => chainId ? API.getTokens(chainId) : Promise.reject());
	return useMemo(() => ({
		tokens,
		refetchTokens,
	}), [tokens, refetchTokens]);
}
