import { Trading, Trading__factory } from '../contracts/types';
import { useEffect, useMemo } from 'react';
import { useWeb3Providers } from './use-web3-providers';
import { Fund } from '../interfaces/fund';
import { ChainId } from '../common/constants';

export function useReadonlyTradingContract(fund?: Fund | null): Trading | null {
	const providers = useWeb3Providers();
	const contract = useMemo(() => {
		if (!fund) return null;
		return Trading__factory.connect(fund.tradingAddress, providers[fund.chainId as ChainId]);
	}, [fund, providers]);
	useEffect(() => () => {
		contract?.removeAllListeners();
	}, [contract]);
	return contract;
}
