import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from './slices/app';
import { swapReducer } from '../features/token-swap-form/slice';
import { decimalsReducer } from './slices/decimals';
import { whitelistsReducer } from '../features/whitelists/slice';
import { fundsReducer } from '../features/fund/slice';
import { profileReducer } from './slices/profile';
import { walletReducer } from './slices/wallet';
import { aaveReducer } from '../features/trade/aave/slice';
import { gmxReducer } from '../features/trade/gmx/slice';

const reducer = combineReducers({
	app: appReducer,
	swap: swapReducer,
	whitelist: whitelistsReducer,
	funds: fundsReducer,
	profile: profileReducer,
	wallet: walletReducer,
	decimals: decimalsReducer,
	aave: aaveReducer,
	gmx: gmxReducer,
});

export const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
