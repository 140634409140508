import React from 'react';
import SelectDropdown, { Props } from 'react-select';
import { SelectOption } from '../../interfaces/ui';

export interface SelectProps<IsMulti extends boolean = boolean> extends Props<SelectOption, IsMulti> {
	name?: string;
	hasError?: boolean;
}

export default function Select<IsMulti extends boolean = boolean>({
  value,
  options,
  placeholder,
  name,
	onChange,
  isSearchable = false,
  hasError = false,
  isDisabled = false,
	menuIsOpen,
  isMulti,
	className,
	styles,
	...rest
}: SelectProps<IsMulti>): JSX.Element {
	return (
		<SelectDropdown<SelectOption, IsMulti>
			value={value !== null && options ? value : null}
			getOptionValue={(e) => String(e.id)}
			onChange={onChange}
			id={`id-${name}`}
			// TODO: check if its needed
			instanceId={`id-${name}`}
			inputId={`id-${name}`}
			menuIsOpen={menuIsOpen}
			options={options}
			placeholder={placeholder}
			className={className}
			classNamePrefix="select"
			isSearchable={isSearchable}
			isDisabled={isDisabled}
			isMulti={isMulti}
			styles={{
				dropdownIndicator: (provided, state) => ({
					...provided,
					transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
					transition: 'transform .2s ease',
				}),
				control: (provided) => ({
					...provided,
					minHeight: 54,
					borderColor: hasError ? '#FF6A55 !important' : undefined,
				}),
				...styles,
			}}
			{...rest}
		/>
	);
};
