import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Signer } from '@ethersproject/abstract-signer';
import { RootState } from '../../../store/store';
import { BigNumber, constants, ethers, type PayableOverrides } from 'ethers';
import {
	GMXState,
} from './types';
import { UI_FEE_RECEIVER_ACCOUNT } from './constants';
import { TFunction } from 'react-i18next';
import { ChainId, CONFIRMATION_COUNT, contractAddresses } from '../../../common/constants';
import { selectFund } from '../../fund/slice';
import { Trading__factory } from '../../../contracts/types';
import { DecreasePositionSwapType, isMarketOrderType, OrderType } from './v2/lib/orders';
import { applySlippageToPrice, convertToContractPrice } from './utils';
import { convertTokenAddress, NATIVE_TOKEN_ADDRESS } from './tokens';
import { applySlippageToMinOut } from './v2/lib/trade';
import { getPositionKey } from './v2/lib/positions';
import { PendingOrderData, PendingPositionUpdate } from './v2/types/events';
import { RefetchFunc } from '../../../hooks/use-balances';
import { getErrorMessage } from '../../../web3/tx-errors';
import { helperToast, showCallContractToast } from '../../../common/toast';
import { applyBuffer, getGasLimits } from '../../../web3/gas-utils';

const initialState: GMXState = {
	loading: false,
};

// TODO: remove redux
export const gmxSlice = createSlice({
	name: 'gmx',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
	},
});

export const gmxReducer = gmxSlice.reducer;

export const selectLoading = (state: RootState): boolean => state.gmx.loading;

export const increaseV2Position = createAsyncThunk<unknown,
	{
		fundId: string;
		account: string;
		signer: Signer;
		fromTokenAddress: string;
		indexTokenAddress: string;
		collateralTokenAddress: string;
		usdDelta: string;
		isLong: boolean;
		collateralAmount: BigNumber;
		executionFee: BigNumber;
		acceptablePrice: BigNumber;
		sizeDeltaInTokens: BigNumber;
		triggerPrice: BigNumber;
		marketAddress: string;
		orderType: OrderType;
		swapPath?: string[];
		t: TFunction;
		setPendingOrder: (p: PendingOrderData) => void;
		setPendingPosition: (update: PendingPositionUpdate) => void;
		closeModal: () => void;
		refetchBalances: RefetchFunc;
		refetchPositions: RefetchFunc;
		refetchOrders: RefetchFunc;
	},
	{ state: RootState }>(
	'gmx/increaseV2Position',
	async (
		{
			fundId,
			account,
			collateralTokenAddress,
			fromTokenAddress,
			collateralAmount,
			isLong,
			usdDelta,
			signer,
			executionFee,
			t,
			sizeDeltaInTokens,
			marketAddress,
			swapPath = [],
			acceptablePrice,
			orderType,
			setPendingOrder,
			setPendingPosition,
			triggerPrice,
			closeModal,
			refetchBalances,
			refetchPositions,
			refetchOrders,
		},
		{ dispatch, getState },
	) => {
		try {
			dispatch(gmxSlice.actions.setLoading(true));

			if (isMarketOrderType(orderType)) {
				const positionKey = getPositionKey(account, marketAddress, collateralTokenAddress, isLong);
				const txnCreatedAt = Date.now();
				const txnCreatedAtBlock = await signer?.provider?.getBlockNumber();
				setPendingPosition({
					isIncrease: true,
					positionKey,
					collateralDeltaAmount: collateralAmount,
					sizeDeltaUsd: BigNumber.from(usdDelta),
					sizeDeltaInTokens: sizeDeltaInTokens,
					updatedAt: txnCreatedAt,
					updatedAtBlock: BigNumber.from(txnCreatedAtBlock),
				});
			}

			setPendingOrder({
				account: account,
				marketAddress: marketAddress,
				initialCollateralTokenAddress: fromTokenAddress,
				initialCollateralDeltaAmount: collateralAmount,
				swapPath: swapPath,
				sizeDeltaUsd: BigNumber.from(usdDelta),
				minOutputAmount: BigNumber.from(0),
				isLong: isLong,
				orderType: orderType,
				shouldUnwrapNativeToken: false,
			});
			closeModal();

			const tradingContract = Trading__factory.connect(account, signer);
			const params = {
				addresses: {
					receiver: account,
					initialCollateralToken: fromTokenAddress,
					callbackContract: constants.AddressZero,
					market: marketAddress,
					swapPath: swapPath,
					uiFeeReceiver: constants.AddressZero,
				},
				numbers: {
					sizeDeltaUsd: usdDelta,
					initialCollateralDeltaAmount: collateralAmount,
					triggerPrice: triggerPrice,
					acceptablePrice: acceptablePrice,
					executionFee: executionFee,
					callbackGasLimit: 0,
					minOutputAmount: 0,
				},
				orderType: orderType,
				decreasePositionSwapType: DecreasePositionSwapType.NoSwap,
				isLong: isLong,
				shouldUnwrapNativeToken: false, // TODO: support ETH
				referralCode: constants.HashZero, // TODO: handle this properly
			};
			const overrides: PayableOverrides = await getGasLimits(signer);
			overrides.value = executionFee;
			const gasLimit = await tradingContract.estimateGas.gmxV2CreateOrder(params, overrides);
			await tradingContract.gmxV2CreateOrder(params, {
				...overrides,
				gasLimit: applyBuffer(gasLimit),
			});
			refetchBalances();
			refetchPositions();
			refetchOrders();
		} catch (e) {
			console.error(e)
			const fund = selectFund(getState(), { fundId });
			if (fund) {
				const { failMsg, autoCloseToast } = getErrorMessage(fund.chainId, e as Error);
				helperToast.error(failMsg, { autoClose: autoCloseToast || undefined });
			}
		} finally {
			dispatch(gmxSlice.actions.setLoading(false));
		}
	},
);

export const decreaseV2Position = createAsyncThunk<unknown,
	{
		fundId: string;
		account: string;
		signer: Signer;
		receiveTokenAddress: string;
		decreasePositionSwapType: number;
		sizeDeltaUsd: BigNumber;
		collateralDeltaUsd: BigNumber;
		closeModal: () => void;
		isLong: boolean;
		allowedSlippage: number;
		executionFee: BigNumber;
		acceptablePrice: BigNumber;
		minOutputUsd: BigNumber;
		triggerPrice?: BigNumber;
		sizeDeltaInTokens: BigNumber;
		setPendingOrder: (p: PendingOrderData) => void;
		setPendingPosition: (update: PendingPositionUpdate) => void;
		marketAddress: string;
		orderType: OrderType;
		swapPath?: string[];
		t: TFunction;
		refetchBalances: RefetchFunc;
		refetchPositions: RefetchFunc;
		indexTokenDecimals: number;
		collateralTokenAddress: string;
		positionKey?: string;
	},
	{ state: RootState }>(
	'gmx/decreaseV2Position',
	async (
		{
			account,
			fundId,
			signer,
			executionFee,
			t,
			setPendingPosition,
			setPendingOrder,
			marketAddress,
			orderType,
			swapPath = [],
			collateralTokenAddress,
			receiveTokenAddress,
			sizeDeltaUsd,
			collateralDeltaUsd,
			allowedSlippage,
			acceptablePrice,
			minOutputUsd,
			isLong,
			decreasePositionSwapType,
			sizeDeltaInTokens,
			triggerPrice,
			closeModal,
			refetchBalances,
			refetchPositions,
			indexTokenDecimals,
			positionKey,
		},
		{ dispatch, getState },
	) => {
		try {
			dispatch(gmxSlice.actions.setLoading(true));

			const tradingContract = Trading__factory.connect(account, signer);
			const fund = selectFund(getState(), { fundId });
			if (!fund || !contractAddresses[fund.chainId as ChainId].token) {
				console.warn('could not find fund with id', fundId)
				return;
			}

			const isNativeReceive = receiveTokenAddress === NATIVE_TOKEN_ADDRESS;
			const initialCollateralTokenAddress = convertTokenAddress(fund.chainId, collateralTokenAddress, 'wrapped');
			const shouldApplySlippage = isMarketOrderType(orderType);

			const price = shouldApplySlippage
				? applySlippageToPrice(allowedSlippage, acceptablePrice, false, isLong)
				: acceptablePrice;

			const minOutputAmount = shouldApplySlippage
				? applySlippageToMinOut(allowedSlippage, minOutputUsd)
				: minOutputUsd;

			if (isMarketOrderType(orderType) && positionKey) {
				const txnCreatedAt = Date.now();
				const txnCreatedAtBlock = await signer?.provider?.getBlockNumber();
				setPendingPosition({
					isIncrease: false,
					positionKey: positionKey,
					collateralDeltaAmount: collateralDeltaUsd,
					sizeDeltaUsd: sizeDeltaUsd,
					sizeDeltaInTokens: sizeDeltaInTokens,
					updatedAt: txnCreatedAt,
					updatedAtBlock: BigNumber.from(txnCreatedAtBlock),
				});
			}
			setPendingOrder({
				account: account,
				marketAddress: marketAddress,
				initialCollateralTokenAddress,
				initialCollateralDeltaAmount: collateralDeltaUsd,
				swapPath: swapPath,
				sizeDeltaUsd: sizeDeltaUsd,
				minOutputAmount: minOutputAmount,
				isLong: isLong,
				orderType: orderType,
				shouldUnwrapNativeToken: isNativeReceive,
			});

			closeModal();

			const params = {
				addresses: {
					receiver: account,
					initialCollateralToken: initialCollateralTokenAddress,
					callbackContract: constants.AddressZero,
					market: marketAddress,
					swapPath,
					uiFeeReceiver: UI_FEE_RECEIVER_ACCOUNT ?? ethers.constants.AddressZero,
				},
				numbers: {
					sizeDeltaUsd,
					initialCollateralDeltaAmount: collateralDeltaUsd,
					triggerPrice: convertToContractPrice(triggerPrice || BigNumber.from(0), indexTokenDecimals),
					acceptablePrice: convertToContractPrice(price, indexTokenDecimals),
					executionFee,
					callbackGasLimit: BigNumber.from(0),
					minOutputAmount,
				},
				orderType: orderType,
				decreasePositionSwapType: decreasePositionSwapType,
				isLong: isLong,
				shouldUnwrapNativeToken: isNativeReceive,
				referralCode: constants.HashZero,
			};
			const overrides: PayableOverrides = await getGasLimits(signer);
			overrides.value = executionFee;
			const gasLimit = await tradingContract.estimateGas.gmxV2CreateOrder(params, overrides);
			const tx = await tradingContract.gmxV2CreateOrder(params, { ...overrides, gasLimit: applyBuffer(gasLimit) });
			await tx.wait(CONFIRMATION_COUNT);
			refetchBalances();
			refetchPositions();
		} catch (e) {
			console.error(e);
			const fund = selectFund(getState(), { fundId });
			if (fund) {
				const { failMsg, autoCloseToast } = getErrorMessage(fund.chainId, e as Error);
				helperToast.error(failMsg, { autoClose: autoCloseToast || undefined });
			}
		} finally {
			dispatch(gmxSlice.actions.setLoading(false));
		}
	},
);

export const cancelV2Order = createAsyncThunk<unknown,
	{
		account: string;
		signer: Signer;
		key: string;
		chainId: number;
		refetchOrders: () => void;
		t: TFunction;
	},
	{ state: RootState }>(
	'gmx/cancelV2Order',
	async (
		{
			account,
			signer,
			t,
			refetchOrders,
			chainId,
			key,
		},
		{ dispatch },
	) => {
		try {
			dispatch(gmxSlice.actions.setLoading(true));
			const tradingContract = Trading__factory.connect(account, signer);
			const toastId = showCallContractToast({ chainId, title: 'Cancel order' });
			const overrides: PayableOverrides = await getGasLimits(signer);
			const gasLimit = await tradingContract.estimateGas.gmxV2CancelOrder(key, overrides);
			const tx = await tradingContract.gmxV2CancelOrder(key, { ...overrides, gasLimit: applyBuffer(gasLimit) });
			await tx.wait(CONFIRMATION_COUNT);
			showCallContractToast({ chainId, hash: tx.hash, toastId, title: 'Cancelling order' });
			refetchOrders();
		} catch (e) {
			console.error(e)
			const { failMsg, autoCloseToast } = getErrorMessage(chainId, e as Error);
			helperToast.error(failMsg, { autoClose: autoCloseToast || undefined });
		} finally {
			dispatch(gmxSlice.actions.setLoading(false));
		}
	},
);
