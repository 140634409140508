import { Wallet } from '../../interfaces/web3';
import { metaMask } from '../../web3/metamask';
import { walletConnectV2 } from '../../web3/wallet-connect';
import { getInjection } from './utils';

export const availableWallets: Wallet[] = [
	{
		key: 'injected' as const,
		getTitle: () => getInjection().name,
		urlToInstall: 'https://metamask.io/',
		qr: false,
		connector: metaMask,
		mobile: false,
	},
	{
		key: 'walletConnect' as const,
		getTitle: () => 'WalletConnect',
		qr: true,
		connector: walletConnectV2,
		mobile: true,
	},
	/*{
		key: "coinbase" as const,
		title: "Coinbase Wallet",
		provider: new WalletLinkConnector({
			url: `https://mainnet.infura.io/v3/${REACT_APP_INFURA_KEY}`,
			appName: "qhill",
			supportedChainIds,
		})
	},*/
];
