import styled, { css } from 'styled-components';

export const InlineButton = styled.div<{ isDisabled?: boolean }>`
  border: 2px solid #efefef;
  font-family: 'Inter',serif;
  color: #6F767E;
  transition: all 300ms;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.01em;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
	user-select: none;
  border-radius: 12px;
  cursor: pointer;
  background-color: #f8f8f8;

	${props => props.isDisabled && css`
		cursor: not-allowed;
		opacity: .3;
	`}
	${props => !props.isDisabled && css`
	  &:hover {
	    background-color: #fff;
	  }
	`}
`;
