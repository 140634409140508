import { constants, utils } from 'ethers';
import { BASE_DECIMALS } from '../common/constants';
import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider';
import { Erc20__factory, Erc20 as ContractType } from './types';

export class Erc20 {
	private contract: ContractType;
	private static decimalsMap: Record<string, number> = {};

	constructor(private token: string, private provider: JsonRpcSigner) {
		this.contract = Erc20__factory.connect(token.toLowerCase(), provider);
	}

	async balanceOf(account: string): Promise<string> {
		const balance = await this.contract.balanceOf(account);
		// TODO: use just decimals()
		const decimals = await Erc20.getDecimals(this.provider, this.token);
		return utils.formatUnits(balance, decimals);
	}

	decimals(): Promise<number> {
		return this.contract.decimals();
	}

	symbol(): Promise<string> {
		return this.contract.symbol();
	}

	static getDecimals(provider: JsonRpcSigner, tokenAddress: string): Promise<number> {
		if (tokenAddress === constants.AddressZero) return Promise.resolve(BASE_DECIMALS);
		if (this.decimalsMap[tokenAddress.toLowerCase()]) return Promise.resolve(this.decimalsMap[tokenAddress.toLowerCase()]);
		const contract = Erc20__factory.connect(tokenAddress, provider);
		return contract.decimals()
			.then((decimals: number) => {
				this.decimalsMap[tokenAddress.toLowerCase()] = decimals;
				return decimals;
			});
	}
}
