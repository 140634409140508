import { useEffect } from 'react';
import { getProfile } from '../../store/slices/profile';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from '../../hooks/redux';

export function useLoadProfile(): void {
	const { account } = useWeb3React();
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (!account) return;
		dispatch(getProfile({ account }));
	}, [account, dispatch]);
}
