import styled from 'styled-components';

export const MaxButton = styled.div`
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 600;
  color: #006cff;
  cursor: pointer;
  text-transform: uppercase;
  background: #f5f9ff;
  border: 0;
  border-radius: 5px;
  transition: all 0.2s ease 0s;
  &:hover {
    background: #006cff;
    color: #fff;
  }
`;
