export const breakpoints = {
	mobileXXXS: 280,
	mobileXXS: 320,
	mobileXS: 360,
	mobileS: 425,
	mobileM: 460,
	tablet: 768,
	laptopS: 1024,
	laptopM: 1200,
	laptopL: 1320,
	laptopXL: 1560,
	laptopXXL: 1660,
};
