import { ReactNode } from 'react';
import { TransactionStatusType } from './order-status-notification';
import { getExplorerUrl } from '../../../../common/constants';
import Loading from '../../../../components/UI/loading';
import { ExternalLink } from '../../../../components/UI/external-link';
import { Row } from '../../../../components/UI/row';
import { TextS } from '../../../../components/UI/typography';
import styled from 'styled-components';

export type TransactionStatusProps = {
	status: TransactionStatusType;
	text: ReactNode;
	chainId: number;
	txnHash?: string;
};

export function TransactionStatus({ status, text, chainId, txnHash }: TransactionStatusProps): JSX.Element {
	const txnLink = `${getExplorerUrl(chainId)}tx/${txnHash}`;

	return (
		<>
			<StyledRow>
				<TextS>{text}</TextS>
				{status === 'loading' && <StyledLoading variant="primary" size={20} />}
				{status !== 'loading' && txnHash && <StyledLink href={txnLink}>(view)</StyledLink>}
			</StyledRow>
		</>
	);
}

const StyledLoading = styled(Loading)`
	margin-left: 4px;
`;

const StyledLink = styled(ExternalLink)`
	display: flex;
	margin-left: 4px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.01em;
`;

const StyledRow = styled(Row)`
	margin-top: 6px;
`;
