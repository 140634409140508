import styled, { css } from 'styled-components';
import { flexGap } from '../../common/flex-gap';
import { ReactComponent as BackImage } from '../../asset/images/back.svg';
import React, { ReactNode } from 'react';
import { Customizable } from '../../interfaces/ui';
import { useNavigate } from 'react-router-dom';
import { breakpoints } from './breakpoints';

export interface ButtonProps {
	disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 20px;
  border-radius: 12px;
  background: #2a85ff;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ffffff;
  transition: all 300ms;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #3a8cf9;
  }
  ${props => props.disabled && css`
    opacity: .6;
  `};
`;

export const TransparentButton = styled(Button)`
  border: 2px solid #efefef;
  background: #fff;
  color: #1a1d1f;

  &:hover {
    background: #fff;
  }

  ${props => props.disabled && css`
    opacity: .4;
  `};
`;

const BackButtonComponent = styled(Button)`
  padding: 8px 16px;
  ${flexGap({ row: "8px", column: "8px" })}
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  background: #fcfcfc;
  border: 2px solid #efefef;
  border-radius: 8px;
  &:hover {
    background: #fcfcfc;
  }

	@media screen and (max-width: ${breakpoints.mobileXS}px) {
    font-size: 12px;
    line-height: 24px;
	}
`;

export function BackButton({ children, className, to }: { children: ReactNode; to?: string; } & Customizable): JSX.Element {
	const navigate = useNavigate();
	const onClick = (): void => {
		if (to) navigate(to);
		else navigate(-1);
	}
	return (
		<BackButtonComponent onClick={onClick} className={className}>
			<BackImage />
			<span>{children}</span>
		</BackButtonComponent>
	);
}
