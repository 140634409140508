import { MarketInfo, MarketsInfoData } from '../markets';
import { getMidPrice } from '../tokens';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { adaptToV1InfoTokens, convertToUsd } from '../tokens';
import { GMXInfoTokens, GMXToken, GMXTokenData, GMXTokensData } from '../../../types';
import { getByKey } from '../positions';
import { getTokensMap, NATIVE_TOKEN_ADDRESS } from '../../../tokens';
import { useTradeContext } from '../../../../trade-provider';

export type AvailableTokenOptions = {
	tokensMap: { [address: string]: GMXToken };
	infoTokens: GMXInfoTokens;
	swapTokens: GMXTokenData[];
	indexTokens: GMXTokenData[];
	sortedIndexTokensWithPoolValue: string[];
	sortedLongAndShortTokens: string[];
	sortedAllMarkets: MarketInfo[];
};

export function useAvailableTokenOptions(
	chainId: number,
	p: {
		marketsInfoData?: MarketsInfoData;
		tokensData?: GMXTokensData;
	},
): AvailableTokenOptions {
	const { allowedTokens } = useTradeContext();
	const fundTokens = useMemo(
		() => allowedTokens.map(token => token.address.toLowerCase()),
		[allowedTokens],
	);
	const { marketsInfoData, tokensData } = p;

	return useMemo(() => {
		const marketsInfo = Object.values(marketsInfoData || {})
			.filter((market) => !market.isDisabled)
			.sort((a, b) => {
				return a.indexToken.symbol.localeCompare(b.indexToken.symbol);
			});
		const allMarkets = new Set<MarketInfo>();
		const tokensMap = getTokensMap(chainId);
		const nativeToken = getByKey(tokensData, NATIVE_TOKEN_ADDRESS);

		const indexTokens = new Set<GMXTokenData>();
		const indexTokensWithPoolValue: { [address: string]: BigNumber } = {};

		const collaterals = new Set<GMXTokenData>();

		const longTokensWithPoolValue: { [address: string]: BigNumber } = {};
		const shortTokensWithPoolValue: { [address: string]: BigNumber } = {};

		for (const marketInfo of marketsInfo) {
			const longToken = marketInfo.longToken;
			const shortToken = marketInfo.shortToken;
			const indexToken = marketInfo.indexToken;

			if (marketInfo.isDisabled || !longToken || !shortToken || !indexToken) {
				continue;
			}

			if ((longToken.isWrapped || shortToken.isWrapped) && nativeToken) {
				collaterals.add(nativeToken);
			}

			collaterals.add(longToken);
			collaterals.add(shortToken);

			const longPoolAmountUsd = convertToUsd(
				marketInfo.longPoolAmount,
				marketInfo.longToken.decimals,
				getMidPrice(marketInfo.longToken.prices),
			)!;

			const shortPoolAmountUsd = convertToUsd(
				marketInfo.shortPoolAmount,
				marketInfo.shortToken.decimals,
				getMidPrice(marketInfo.shortToken.prices),
			)!;

			longTokensWithPoolValue[longToken.address] = (
				longTokensWithPoolValue[longToken.address] || BigNumber.from(0)
			).add(longPoolAmountUsd);

			shortTokensWithPoolValue[shortToken.address] = (
				shortTokensWithPoolValue[shortToken.address] || BigNumber.from(0)
			).add(shortPoolAmountUsd);

			if (!marketInfo.isSpotOnly) {
				indexTokens.add(indexToken);
				allMarkets.add(marketInfo);
				indexTokensWithPoolValue[indexToken.address] = (
					indexTokensWithPoolValue[indexToken.address] || BigNumber.from(0)
				).add(marketInfo.poolValueMax);
			}
		}

		const sortedIndexTokensWithPoolValue = Object.keys(indexTokensWithPoolValue).sort((a, b) => {
			return indexTokensWithPoolValue[b].gt(indexTokensWithPoolValue[a]) ? 1 : -1;
		});

		const sortedAllMarkets = Array.from(allMarkets).sort((a, b) => {
			return (
				sortedIndexTokensWithPoolValue.indexOf(a.indexToken.address) -
				sortedIndexTokensWithPoolValue.indexOf(b.indexToken.address)
			);
		});

		const sortedLongTokens = Object.keys(longTokensWithPoolValue).sort((a, b) => {
			return longTokensWithPoolValue[b].gt(longTokensWithPoolValue[a]) ? 1 : -1;
		});

		const sortedShortTokens = Object.keys(shortTokensWithPoolValue).sort((a, b) => {
			return shortTokensWithPoolValue[b].gt(shortTokensWithPoolValue[a]) ? 1 : -1;
		});

		const sortedLongAndShortTokens = sortedLongTokens.concat(sortedShortTokens);
		const isFundToken = (t: GMXTokenData): boolean => fundTokens.includes(t.address.toLowerCase());
		const isFundTokenAddress = (addr: string): boolean => fundTokens.includes(addr.toLowerCase());

		return {
			tokensMap,
			swapTokens: Array.from(collaterals).filter(isFundToken),
			indexTokens: Array.from(indexTokens).filter(isFundToken),
			infoTokens: adaptToV1InfoTokens(tokensData || {}),
			sortedIndexTokensWithPoolValue,
			sortedLongAndShortTokens: Array.from(new Set(sortedLongAndShortTokens)),
			sortedAllMarkets: sortedAllMarkets.filter(m => isFundTokenAddress(m.indexTokenAddress)),
		};
	}, [chainId, fundTokens, marketsInfoData, tokensData]);
}
