export function ensureNotNull<T>(value: T | null): T {
	if (value === null) throw new Error('Value is null');
	return value;
}

export function exists<T>(value: T | null | undefined): value is T {
	return Boolean(value);
}

export function mustNeverExist(x: never): never {
	throw new Error(`Must never exist: ${x}`);
}
