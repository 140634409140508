import styled from 'styled-components';
import { flexGap } from '../../common/flex-gap';
import { ReactComponent as WalletConnectImage } from '../../asset/images/Wallet/walletconnect.svg';
import { ReactComponent as TrustWalletImage } from "../../asset/images/Wallet/trust.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  padding: 24px;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.05),
  0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 16px;
  width: 100%;
  max-width: 520px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
`;
export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #efefef;
  margin-top: 24px;
  margin-bottom: 24px;
`;
export const Caption = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #6f767e;
  margin-bottom: 24px;
`;
export const WalletContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${flexGap({ row: '8px', column: '24px' })}
`;
export const WalletItem = styled.div<{ mobile?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1 0 calc(50% - 24px);
  padding: 16px;
  min-height: 64px;
  background: #f4f4f4;
  border: 2px solid #efefef;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  user-select: none;
  cursor: pointer;
`;

export const WalletImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 100px;
  margin-right: 12px;
`;

export const StyledWalletConnectImage = styled(WalletConnectImage)`
  width: 32px;
  height: 32px;
`;

export const StyledTrustWalletImage = styled(TrustWalletImage)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
