import styled from 'styled-components';
import Blockies from 'react-blockies';
import { REACT_APP_API_URL } from '../common/constants';
import { Customizable } from '../interfaces/ui';

export interface AvatarProps extends Customizable {
	placeholderSeed: string;
	onClick?: () => void;
	size?: number;
	url?: string | null;
	placeholderSize?: number;
	placeholderScale?: number;
	lastUpdated?: number | null;
}

export function Avatar({
	onClick,
	url,
	size,
	placeholderSeed,
	className,
	placeholderScale = 4,
	placeholderSize = 8,
	lastUpdated,
}: AvatarProps): JSX.Element {
	const sizeStyle = size ? { width: size, height: size } : {};
	return (
		<Container
			className={className}
			onClick={onClick}
			style={{ backgroundImage: url ? `url(${REACT_APP_API_URL}file/${url}_256.jpg${lastUpdated ? `?lastUpdated=${lastUpdated}` : ''})` : undefined, ...sizeStyle }}
		>
			{!url && <Blockies seed={placeholderSeed} size={placeholderSize} scale={placeholderScale} />}
		</Container>
	);
}

const Container = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
	flex: 0 0 auto;
`;
