import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@yandex/ui/Modal/desktop/bundle';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';

import {
	selectCreateLoading,
	selectCreateSuccess,
	selectCreateModalOpen,
	setCreateModalOpen,
	create,
	selectDraftFund,
} from '../slice';
import {
	Container,
	Head,
	CloseContainer,
	Divider,
	CreateButton,
	CancelButton,
	UnlockButton,
} from './create-modal.styled';
import {
	CheckContainer,
	ButtonsContainer,
	UnlockImageContainer,
} from '../../../components/modal/common.styled';
import { Success } from '../../../components/modal/success';
import Loading from '../../../components/UI/loading';

import { ReactComponent as CloseImage } from '../../../asset/images/close.svg';
import { useNavigate } from 'react-router-dom';
import { FundCheckList } from '../fund-check-list';
import { FeesTable } from '../fees-table';
import { useAppDispatch } from '../../../hooks/redux';
import { Title } from '../../../components/title/title';
import { Token } from '../../../interfaces/whitelists';
import { useDefundsEthClient } from '../../../hooks/use-defunds-eth-client';
import { compare } from 'mathjs';
import { approve, selectApproveLoading } from '../../../store/slices/app';
import { ReactComponent as UnlockImage } from '../../../asset/images/unlock.svg';
import { useQuery } from '@tanstack/react-query';
import { ChainId, contractAddresses } from '../../../common/constants';
import { useUsdtAddress } from '../../../hooks/use-usdt-address';
import { useUSDTDecimals } from '../../../hooks/use-usdt-decimals';
import { utils } from 'ethers';
import { Erc20 } from '../../../contracts/erc20';
import { CheckboxRow } from '../../../components/checkbox-row';
import { useTokens } from '../../../hooks/use-tokens';

export function CreateModal(): JSX.Element {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const { account, chainId, provider } = useWeb3React();
	const { tokens: allTokens } = useTokens(chainId);
	const ethClient = useDefundsEthClient();
	const usdtAddress = useUsdtAddress();
	const usdtDecimals = useUSDTDecimals();

	const fund = useSelector(selectDraftFund);
	const [confirmed, setConfirmed] = useState(false);
	const createLoading = useSelector(selectCreateLoading);
	const createSuccess = useSelector(selectCreateSuccess);
	const createModalOpen = useSelector(selectCreateModalOpen);
	const approveLoading = useSelector(selectApproveLoading);

	const onClose = (): void => {
		navigate('/fund');
		dispatch(setCreateModalOpen(false));
	};

	const tokens = useMemo<Token[]>(
		() => allTokens.filter(token => fund.tokenIds.includes(token.id)),
		[allTokens, fund.tokenIds],
	);

	const { data: allowance } = useQuery(
		['allowance', account, usdtAddress, chainId],
		() =>
			(account && usdtAddress && chainId && contractAddresses[chainId as ChainId]) ? ethClient?.allowance({
				account,
				token: usdtAddress,
				spender: contractAddresses[chainId as ChainId].fees,
			}) : undefined,
		{ refetchInterval: 5000 },
	);

	const { data: createFundFee } = useQuery(
		['createFundFee', chainId],
		() => (chainId) ? ethClient?.getCreateFundFee() : undefined,
	);

	const { data: balance } = useQuery(
		['balance', account, usdtAddress],
		() => (account && usdtAddress && provider) ? new Erc20(usdtAddress, provider.getSigner()).balanceOf(account) : undefined,
		{ refetchInterval: 5000 },
	);

	const formattedCreateFundFee = createFundFee && usdtDecimals ? utils.formatUnits(createFundFee, usdtDecimals) : undefined;
	const isInsufficientBalance = compare(formattedCreateFundFee || 0, balance || 0) === 1;
	const isInsufficientAllowance = compare(formattedCreateFundFee || 0, allowance || 0) === 1;

	return (
		<Modal
			theme="normal"
			// onClose={() => dispatch(setCreateModalOpen(false))}
			visible={createModalOpen}
			zIndexGroupLevel={20}
		>
			<Container>
				{createSuccess ? (
					<Success onClose={onClose} caption={t('Modal.Success.Caption')}
					         buttonText={t('Modal.Success.Button.SeeMyFunds')} />
				) : (
					<React.Fragment>
						<Head>
							<Title title={t('CreateFund.Modal.Title')} />
							<CloseContainer onClick={() => dispatch(setCreateModalOpen(false))}>
								<CloseImage />
							</CloseContainer>
						</Head>

						{chainId && <FeesTable
							chainId={chainId}
							fees={{ sf: fund.subscriptionFee, pf: fund.performanceFee, mf: fund.managementFee }}
						/>}

						<CheckContainer>
							<FundCheckList
								HWM={fund.hwm}
								indent={fund.indent}
								period={fund.reportingPeriod}
								enabledServices={fund.enabledServices}
								tokens={tokens}
								manager={fund.managerAddress ? { address: fund.managerAddress, share: fund.managerShare } : undefined}
								isPrivate={fund.isPrivate}
								riskManagement={fund.riskManagement}
							/>
						</CheckContainer>

						<CheckboxRow
							onChange={setConfirmed}
							value={confirmed}
							title={t('CreateFund.ConfirmCreateFundLabel', { cost: Number(formattedCreateFundFee) })}
						/>

						{!createLoading
							&& allowance
							&& formattedCreateFundFee
							&& compare(formattedCreateFundFee, allowance) === 1
							&& usdtAddress
							&& chainId
							&& !isInsufficientBalance
							&& (
							<UnlockButton
								disabled={approveLoading}
								onClick={() => ethClient && dispatch(approve({
									ethClient,
									token: usdtAddress,
									amount: formattedCreateFundFee,
									spender: contractAddresses[chainId as ChainId].fees,
								}))}
							>
								{approveLoading ? (
									<>
										<Loading />
										{t('Common.Loading')}
									</>
								) : (
									<>
										<UnlockImageContainer>
											<UnlockImage />
										</UnlockImageContainer>
										<div>{t('Common.Button.Approve')} {Number(formattedCreateFundFee)} USDT</div>
									</>
								)}
							</UnlockButton>
						)}

						<Divider />

						<ButtonsContainer>
							<CancelButton
								disabled={createLoading}
								onClick={() => {
									if (!createLoading) {
										dispatch(setCreateModalOpen(false));
									}
								}}
							>
								{t('Common.Button.Cancel')}
							</CancelButton>
							<CreateButton
								disabled={!confirmed || createLoading || isInsufficientAllowance || isInsufficientBalance}
								onClick={() => {
									if (!account || !ethClient) return;
									dispatch(create({ ethClient, account, tokens }));
								}}
							>
								{createLoading && (
									<>
										<Loading />
										{t('Common.Loading')}
									</>
								)}
								{!createLoading && <>
									{!isInsufficientBalance && t('Common.Button.Create')}
									{isInsufficientBalance && 'Not enough USDT'}
								</>}
							</CreateButton>
						</ButtonsContainer>
					</React.Fragment>
				)}
			</Container>
		</Modal>
	);
}
