import { ChartData, Fund, FundState, ManagerSummary } from '../../interfaces/fund';
import { ChainId, mapChainIdToDecimals, REACT_APP_API_URL } from '../../common/constants';
import {
	etherToPercents,
	fetchVerify,
	formatEther,
	getQueryString,
	getUnixTimeRange,
	processApiResponse,
} from '../../common/utils';
import { getAuthHeaders } from '../../common/get-auth-headers';
import { TimePeriod } from '../../interfaces/ui';
import { BigNumber, ethers, utils } from 'ethers';
import { ActivityItem, ActivityType, GMXBaseActivityItem } from '../../interfaces/activity';

export const PAGE_SIZE = 8;

export function getMarketFunds(params: {
	chainId: number | undefined;
	sortBy?: string;
	account?: string | null;
	page?: number;
	searchTerm?: string;
}): Promise<Fund[]> {
	const { chainId, account, sortBy = 'aum', searchTerm = '', page } = params;
	return fetchVerify(
		`${REACT_APP_API_URL}market_funds${getQueryString({ sortBy, chainId, page, pageSize: PAGE_SIZE, searchTerm })}`,
		{ headers: getAuthHeaders(account) },
	)
		.then(r => r.json())
		.then(funds => funds.map(mapFund));
}

export interface GetFundsParams {
	manager?: string | null;
	staker?: string | null;
	sortBy?: string;
	account: string;
	chainId: number;
}

export function getFunds({
	manager,
	staker,
	isVisible,
	account,
	chainId,
	sortBy = 'aum',
}: GetFundsParams & { isVisible: '1' | '0' }): Promise<Fund[]> {
	return fetchVerify(
		`${REACT_APP_API_URL}fund${getQueryString({ isVisible, manager, staker, sortBy, chainId })}`,
		{ headers: getAuthHeaders(account) },
	)
		.then(r => r.json())
		.then(funds => funds.map(mapFund));
}

export function getFundsByVisibility(params: GetFundsParams): Promise<[Fund[], Fund[]]> {
	return Promise.all([
		getFunds({ ...params, isVisible: '1' }),
		getFunds({ ...params, isVisible: '0' }),
	]);
}

interface CreateFundParams {
	name: string;
	description: string;
	manager: string;
	chainId: number;
	isPrivate: boolean;
	image: string | null;
}

export function createFund(params: CreateFundParams): Promise<Fund> {
	return fetchVerify(`${REACT_APP_API_URL}fund`, {
		headers: {
			...getAuthHeaders(params.manager),
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(params),
	}).then(processApiResponse).then(mapFund);
}

function mapFundState(state: string): FundState {
	switch (state) {
		case 'CLOSED':
			return FundState.Closed;
		case 'OPENED':
			return FundState.Opened;
		case 'BLOCKED':
			return FundState.Blocked;
	}
	return FundState.Opened;
}

function mapFund(obj: Omit<Fund, 'riskManagement' | 'state'> & {
	sf: number;
	mf: number;
	pf: number;
	annualYield: string;
	maxDD: string;
	ddBlockingEnabled: boolean;
	ddBlockingThreshold: number;
	ddManagerResetEnabled: boolean;
	ddManagerResetThreshold: number;
	state: string;
}): Fund {
	const usdtDecimals = mapChainIdToDecimals[obj.chainId as ChainId];
	return {
		...obj,
		state: mapFundState(obj.state),
		managerShare: obj.managerShare ? String(Number(ethers.utils.formatEther(obj.managerShare)) * 100) : '100',
		// TODO: holders must be in the object even its 0
		holders: obj.holders || 0,
		chartData: obj.chartData.reduce<ChartData[]>((acc, curr) => {
			// TODO: there should be no duplicates from server
			if (!acc.map(i => i.dt).includes(curr.dt)) acc.push(curr);
			return acc;
		}, []).map((item: ChartData) => {
			return {
				dt: item.dt,
				rate: etherToPercents(formatEther(item.rate, usdtDecimals, 4)),
			};
		}),
		subscriptionFee: obj.sf * 100,
		managementFee: obj.mf * 100,
		performanceFee: obj.pf * 100,
		annualYieldInPercent: Number(etherToPercents(formatEther(obj.chartData[obj.chartData.length - 1].rate, usdtDecimals, 4))),
		maxDrawdownInPercent: (Number(utils.formatEther(obj.maxDD)) * 100).toFixed(2),
		investmentInfo: obj.investmentInfo ? {
			...obj.investmentInfo,
			invested: obj.investmentInfo.invested,
			pnl: obj.investmentInfo.pnl,
			commissionPaid: obj.investmentInfo.commissionPaid,
			myAssets: obj.investmentInfo.myAssets,
		} : null,
	};
}

export function getFund(id: string, account?: string | null): Promise<Fund> {
	return fetchVerify(
		`${REACT_APP_API_URL}fund/${id}`,
		{ headers: getAuthHeaders(account) },
	)
		.then(r => r.json())
		.then(mapFund);
}

export function updateFund(params: {
	id: string;
	image: string | null;
	description: string;
	signature: string;
	account: string;
}): Promise<Fund> {
	const { id, account, ...rest } = params;
	return fetchVerify(`${REACT_APP_API_URL}fund/${id}`, {
		headers: {
			...getAuthHeaders(account),
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify({ ...rest }),
	}).then(r => r.json()).then(mapFund);
}

export async function getManagerSummary(chainId: ChainId, account: string, period: TimePeriod = TimePeriod.AllTime): Promise<ManagerSummary> {
	const [periodStart, periodEnd] = getUnixTimeRange(period);
	return fetchVerify(
		`${REACT_APP_API_URL}manager/summary${getQueryString({ periodStart, periodEnd, chainId })}`,
		{ headers: getAuthHeaders(account) },
	).then(r => r.json());
}

export function dripFund(fundId: string, password: string): Promise<void> {
	return fetch(`${REACT_APP_API_URL}admin/drip/${fundId}`, {
		headers: { 'X-ADMIN-PASS': password },
		method: 'PUT',
	}).then(r => r.json());
}

export async function getActivity(fundId: string, page: number, types: ActivityType[] = []): Promise<ActivityItem[]> {
	const limit = PAGE_SIZE;
	const offset = page * PAGE_SIZE;
	const query = getQueryString({ limit, offset, types });
	return fetchVerify(`${REACT_APP_API_URL}activity/${fundId}${query}`).then(r => r.json())
		.then(r => r.map(mapActivityItem));
}

type StringifyBigNumber<T> = {
	[K in keyof T]: T[K] extends BigNumber ? string : T[K];
};
type ApiActivityItem = StringifyBigNumber<ActivityItem>;

function mapActivityItem(item: ApiActivityItem): ActivityItem {
	switch (item.type) {
		case ActivityType.Swap:
			return {
				...item,
				fromAmount: BigNumber.from(item.fromAmount),
				toAmount: BigNumber.from(item.toAmount),
			};
		case ActivityType.AaveSupply:
		case ActivityType.AaveWithdraw:
			return {
				...item,
				amount: BigNumber.from(item.amount),
			};
		case ActivityType.GmxV2Increase:
			return {
				...mapBaseGMXActivityItem(item),
				type: ActivityType.GmxV2Increase,
			};
		case ActivityType.GmxV2Decrease:
			return {
				...mapBaseGMXActivityItem(item),
				type: ActivityType.GmxV2Decrease,
				basePnlUsd: BigNumber.from(item.basePnlUsd),
				uncappedBasePnlUsd: BigNumber.from(item.uncappedBasePnlUsd),
			};
		case ActivityType.GmxV2Liquidation:
			return {
				...item,
				prevSize: BigNumber.from(item.prevSize),
			};
		case ActivityType.Reporting:
			return {
				...item,
				deposits: BigNumber.from((item).deposits),
				withdrawals: BigNumber.from((item).withdrawals),
				pf: BigNumber.from((item).pf),
				prevAum: BigNumber.from((item).prevAum),
				aum: BigNumber.from((item).aum),
				executionFee: BigNumber.from((item).executionFee),
			};
		case ActivityType.ServicesUpdated:
		case ActivityType.WhitelistUpdated:
		case ActivityType.ManagerChanged:
		case ActivityType.OwnershipTransferred:
			return item;
		case ActivityType.TrailingStopUpdated:
			return {
				...item,
				managerValue: BigNumber.from(item.managerValue),
				globalValue: BigNumber.from(item.globalValue),
			};
		default:
			throw new Error('Unsupported activity type');
	}
}

function mapBaseGMXActivityItem(item: StringifyBigNumber<GMXBaseActivityItem>): GMXBaseActivityItem {
	return {
		...item,
		executionPrice: BigNumber.from(item.executionPrice),
		executionFee: BigNumber.from(item.executionFee),
		sizeDelta: BigNumber.from(item.sizeDelta),
		collateralDelta: BigNumber.from(item.collateralDelta),
		prevSize: BigNumber.from(item.prevSize),
		prevCollateral: BigNumber.from(item.prevCollateral),
	};
}
