import styled from 'styled-components';
import { ReactNode } from 'react';
import { Customizable } from '../../interfaces/ui';
import { breakpoints } from '../UI/breakpoints';

export interface TitleProps extends Customizable {
	title: ReactNode;
	dotColor?: string;
}

export function Title({ title, dotColor, className }: TitleProps): JSX.Element {
	return (
		<Container className={className}>
			<Dot color={dotColor} />
			<Content>{title}</Content>
		</Container>
	);
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div<{ color?: string }>`
  margin-right: 16px;
  width: 16px;
  height: 32px;
  background: ${props => props.color || '#2a85ff'};
  border-radius: 4px;

	@media screen and (max-width: ${breakpoints.tablet}px) {
		display: none;
	}
`;

const Content = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #1a1d1f;
`;
