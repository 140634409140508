import { useQuery } from '@tanstack/react-query';
import { useReadonlyTradingContract } from '../../hooks/use-readonly-trading-contract';
import { whitelistMaskToAllowedTokens } from './parser';
import { Token } from '../../interfaces/whitelists';
import { useMemo } from 'react';
import { getUSDTToken } from '../../common/constants';
import { Fund } from '../../interfaces/fund';
import { Loadable } from '../../interfaces/ui';
import { useTokens } from '../../hooks/use-tokens';

export function useFundAllowedTokens(fund?: Fund | null): Loadable<Token[]> {
	const tradingContract = useReadonlyTradingContract(fund);

	const { tokens } = useTokens(fund?.chainId);
	const { data: mask, isLoading } = useQuery(
		['whitelistMask', fund?.id],
		() => tradingContract?.whitelistMask()
	);
	const data = useMemo(() => {
		if (!mask || !fund) return [];
		return [getUSDTToken(fund.chainId), ...whitelistMaskToAllowedTokens(tokens, mask)];
	}, [fund, mask, tokens]);
	return useMemo(() => ({
		loading: isLoading,
		data,
	}), [data, isLoading]);
}
