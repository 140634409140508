import DataStore from '../../../../abis/DataStore.json';
import { useMulticall } from '../../multicall';
import { uiFeeFactorKey } from '../../dataStore';
import { BigNumber } from 'ethers';
import { gmxContractAddresses, UI_FEE_RECEIVER_ACCOUNT } from '../../../../constants';

export default function useUiFeeFactor(chainId: number, account = UI_FEE_RECEIVER_ACCOUNT): BigNumber {
	const { data: uiFeeFactorForAccount } = useMulticall(chainId, 'uiFeeFactorForAccount', {
		key: account ? [account] : null,
		refreshInterval: 60000,
		request: () => ({
			dataStore: {
				contractAddress: gmxContractAddresses[chainId].DataStore,
				abi: DataStore.abi,
				calls: {
					keys: {
						methodName: 'getUint',
						params: [uiFeeFactorKey(account!)],
					},
				},
			},
		}),
		parseResponse: (res) => {
			return BigNumber.from(res.data.dataStore.keys.returnValues[0]);
		},
	});

	return uiFeeFactorForAccount || BigNumber.from(0);
}
