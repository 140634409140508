import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Erc20 } from '../../contracts/erc20';
import { Web3Provider } from '@ethersproject/providers';

// chainId => token address => decimals
const initialState: Record<string, Record<string, number>> = {};

export const decimalsSlice = createSlice({
	name: 'decimals',
	initialState,
	reducers: {
		setDecimals: (state, action: PayloadAction<{ tokenAddress: string; chainId: number; value: number }>) => {
			const { chainId, tokenAddress, value } = action.payload;
			if (!state[chainId]) {
				state[chainId] = {};
			}
			state[chainId][tokenAddress] = value;
		},
	},
});

export const updateDecimals = createAsyncThunk<void, { provider: Web3Provider; tokenAddress: string; chainId: number }, { state: RootState }>(
	'decimals/updateDecimals',
	async (
		{ provider, tokenAddress, chainId },
		{ dispatch },
	) => {
		const decimals = await Erc20.getDecimals(provider.getSigner(), tokenAddress);
		dispatch(setDecimals({ chainId, tokenAddress, value: decimals }));
	},
);

export const {
	setDecimals,
} = decimalsSlice.actions;

export const selectDecimals = (state: RootState, {
	token,
	chainId,
}: { token?: string | null; chainId: number | null }): number | null => (token && chainId) ? state.decimals[chainId]?.[token] || null : null;

export const decimalsReducer = decimalsSlice.reducer;
