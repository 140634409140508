import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';
import * as API from './api';
import { WhitelistsState, WhiteList } from '../../interfaces/whitelists';
import { ChainId } from '../../common/constants';

const initialState: WhitelistsState = {
	whiteLists: {
		[ChainId.BNBChain]: [],
		[ChainId.Optimism]: [],
		[ChainId.Arbitrum]: [],
	},
};

export const whitelistsSlice = createSlice({
	name: 'whitelists',
	initialState,
	reducers: {
		setWhiteLists: (state, action: PayloadAction<{ chainId: ChainId, whiteLists: WhiteList[] }>) => {
			state.whiteLists[action.payload.chainId] = action.payload.whiteLists;
		},
	},
});

export const getWhiteLists = createAsyncThunk<
	void,
	{ chainId: ChainId },
	{ state: RootState }
>(
	'whitelists/getWhiteLists',
	async (
		{ chainId },
		{ dispatch },
	) => {
		const whiteLists = await API.getWhiteLists(chainId);
		dispatch(setWhiteLists({ chainId, whiteLists }));
	},
);

export const {
	setWhiteLists,
} = whitelistsSlice.actions;

const selectState = (state: RootState): WhitelistsState => state.whitelist;

function getChainId(_: RootState, { chainId }: { chainId?: ChainId | null }): ChainId | null | undefined {
	return chainId;
}

export const selectWhiteLists = createSelector(
	selectState,
	getChainId,
	(state, chainId) => chainId ? (state.whiteLists[chainId] || []) : [],
);

export const whitelistsReducer = whitelistsSlice.reducer;
