import styled from "styled-components";
import { Button, TransparentButton } from '../../../components/UI/button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  padding: 24px;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 16px;
  width: 100%;
  max-width: 800px;
`;
export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
`;
export const CloseContainer = styled.div`
  cursor: pointer;
`;
export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #efefef;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const CancelButton = styled(TransparentButton)`
	width: 50%;
`;

export const CreateButton = styled(Button)`
  width: 50%;
`;
export const UnlockButton = styled(Button)`
	margin: 24px 0;
`;
