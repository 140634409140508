import { ERC20BalanceChecker } from '../contracts/erc20-balance-checker';
import { useCallback, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useQuery } from '@tanstack/react-query';
import { useUsdtAddress } from './use-usdt-address';
import { BigNumber } from 'ethers';
import { getByLowercasedKey } from '../features/trade/gmx/v2/lib/positions';

export type GetBalanceFunc = (address?: string | null) => BigNumber;
export type RefetchFunc = () => void;
export interface UseBalancesResult {
	getBalance: GetBalanceFunc;
	refetchBalances: RefetchFunc;
}

// TODO: balances in USD, get rid of aave/useWalletBalances
export function useBalances(address: string | null, tokens: string[], usdtDebt: BigNumber | null, skip = false): UseBalancesResult {
	const { provider, chainId } = useWeb3React();
	const usdtAddress = useUsdtAddress();
	const checker = useMemo(
		() => (provider && address) ? new ERC20BalanceChecker(provider, address) : null,
		[address, provider]
	);
	const { data, isLoading, refetch } = useQuery(
		['tokenBalances', tokens, chainId, address],
		() => checker?.balances(tokens),
		{ enabled: !skip }
	);
	const balances = useMemo(() => {
		if (skip) return { loading: false, data: {} };
		if (isLoading || !data || !usdtDebt) return { loading: true };
		if (usdtAddress && data[usdtAddress]) {
			let usdtBalance = BigNumber.from(data[usdtAddress]).sub(usdtDebt);
			if (usdtBalance.lt(0)) {
				usdtBalance = BigNumber.from(0);
			}
			return {
				loading: false,
				data: {
					...data,
					[usdtAddress]: usdtBalance.toString(),
				}
			};
		}
		return { loading: false, data };
	} ,[data, usdtDebt, isLoading, skip, usdtAddress]);
	return {
		getBalance: useCallback(
			(address?: string | null) => (!address || balances.loading)
				? BigNumber.from(0)
				: BigNumber.from(getByLowercasedKey(balances.data, address) || 0),
			[balances.data, balances.loading]
		),
		refetchBalances: refetch,
	};
}
