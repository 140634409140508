import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { setWalletModalOpen } from '../../store/slices/app';
import { ChainId } from '../../common/constants';
import Select from '../UI/select';
import { ReactComponent as ConnectOffImage } from '../../asset/images/connect_off.svg';
import { ReactComponent as ConnectOnImage } from '../../asset/images/connect_on.svg';
import { useWallet } from '../../features/wallet/use-wallet';
import { selectProfile } from '../../store/slices/profile';
import { Avatar } from '../avatar';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../asset/images/close.svg';
import { breakpoints } from '../UI/breakpoints';
import { useEffect, useState } from 'react';
import { ChainSelect } from '../chain-select';

const languageOptions = [
  { id: "en", label: "EN" },
  { id: "ru", label: "RU" },
];

export function Navbar(): JSX.Element {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { chainId, account, isActive } = useWeb3React();
  const { selectedWallet, disconnect, isUnsupportedChainId } = useWallet();
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [pendingChainId, setPendingChainId] = useState<ChainId | null>(null);

  useEffect(() => {
    if (chainId && pendingChainId && chainId === pendingChainId) setPendingChainId(null);
  }, [chainId, pendingChainId]);

  return (
    <>
      <Container>
        <LeftContainer>
          {!isActive ? (
            <ConnectContainer onClick={() => dispatch(setWalletModalOpen(true))}>
              <ConnectImageContainer>
                <ConnectOffImage />
              </ConnectImageContainer>
              <div>{t(`Navbar.ConnectWallet`)}</div>
            </ConnectContainer>
          ) : (
            <>
              <ConnectContainer onClick={() => dispatch(setWalletModalOpen(true))}>
                <ConnectImageContainer>
                  {isUnsupportedChainId ? <ConnectOffImage /> : <ConnectOnImage />}
                </ConnectImageContainer>
                <WalletTitle>{selectedWallet?.getTitle() || 'Error'}</WalletTitle>
                {selectedWallet && <DisconnectButton onClick={(e) => {
                  e.stopPropagation();
                  disconnect();
                }} />}
              </ConnectContainer>
              <ChainSelect />
            </>
          )}
        </LeftContainer>
        <RightContainer>
          <LangSelect
            options={languageOptions}
            placeholder="EN"
            value={{ id: i18n.language, label: i18n.language.toUpperCase() }}
            name="i18n"
            isMulti={false}
            onChange={(val) => {
              i18n.changeLanguage(String(val?.id));
            }}
          />
          {isActive && account && (
            <StyledAvatar
              url={profile && profile.image ? profile.image : null}
              onClick={() => navigate("/profile")}
              size={48}
              placeholderSeed={account}
              placeholderSize={16}
              placeholderScale={3}
              lastUpdated={profile?.updatedAt}
            />
          )}
        </RightContainer>
      </Container>
    </>
  );
}

const WalletTitle = styled.div`
  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const LangSelect = styled(Select<false>)`
  @media screen and (max-width: 900px) {
  	display: none;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ConnectContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 320px;
  border: 2px solid #efefef;
  padding: 8px;
  margin-right: 16px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  cursor: pointer;
  @media screen and (max-width: 1060px) {
    min-width: 200px;
  }
  @media screen and (max-width: ${breakpoints.tablet}px) {
    min-width: auto;
    padding: 8px 12px;
  }
`;

const ConnectImageContainer = styled.div`
  margin-right: 8px;
  width: 34px;
  height: 34px;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    margin-right: 0;
  }
`;

const DisconnectButton = styled(CloseIcon)`
	margin-left: auto;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-left: 16px;
`;
