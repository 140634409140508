import styled from 'styled-components';

// TODO: it should be span
export const TextSMBold = styled.div<{ color?: string }>`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
`;

export const TextSBold = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
`;

export const TextXSBold = styled(TextSBold)`
  font-size: 11px;
  line-height: 13px;
`;

export const TextS = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
`;

export const TextXS = styled(TextS)`
	font-size: 11px;
	line-height: 13px;
`;

export const TextSM = styled(TextSMBold)`
  font-weight: 400;
  line-height: 16px;
`;

export const TextMBold = styled(TextSMBold)`
  font-size: 15px;
  line-height: 16px;
`;

export const TextM = styled(TextMBold)`
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
	font-style: normal;
`;

export const TextLBold = styled(TextSMBold)`
  font-size: 24px;
  line-height: 48px;
  letter-spacing: -0.02em;
	color: #272B30;
`;

export const TextXSTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
`;

export const TextSTitle = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
`;

export const TextTitle = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
`;
