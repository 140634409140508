import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ensureNotNull } from '../../common/assertions';

export interface AdminContextValues {
	password: string | null;
}

const AdminContext = createContext<AdminContextValues | null>(null);

const LOCALSTORAGE_KEY = 'admin-pass';

export function AdminContextProvider({ children }: { children: ReactNode }): JSX.Element {
	const [password, setPassword] = useState(localStorage.getItem(LOCALSTORAGE_KEY));
	useEffect(() => {
		const onStorage = (e: StorageEvent): void => {
			if (e.storageArea === localStorage) {
				setPassword(localStorage.getItem(LOCALSTORAGE_KEY))
			}
		};
		window.addEventListener('storage', onStorage, false);
		return () => window.removeEventListener('storage', onStorage, false);
	}, []);
	return <AdminContext.Provider value={{ password }}>{children}</AdminContext.Provider>
}

export function useAdminContext(): AdminContextValues {
	return ensureNotNull(useContext(AdminContext));
}
