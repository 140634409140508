import { EnabledServices } from './whitelists';
import { BigNumber } from 'ethers';

export interface ChartData {
	dt: string;
	rate: string;
}

export enum FundState {
	Closed,
	Opened,
	Blocked,
}

export interface Fund {
	id: string;
	manager: string;
	managerShare: string;
	owner: string;
	isPrivate: boolean;
	name: string;
	ownerName: string;
	aum: string;
	realAum: string;
	ownerAvatarUrl: string | null;
	description: string;
	reportingPeriod: number;
	indent: number;
	hwm: boolean;
	holders: number;
	chainId: number;
	performance: number;
	chartData: ChartData[];
	subscriptionFee: number;
	managementFee: number;
	performanceFee: number;
	annualYieldInPercent: number;
	maxDrawdownInPercent: string;
	investmentInfo: FundInvestment | null;
	show: boolean;
	nextReport: string;
	tradingAddress: string;
	updatedAt: number;
	image?: string;
	managerAvatarUrl: string | null;
	managerName: string | null;
	state: FundState;
}

export interface FundInvestment {
	invested: string;
	withdrawals: string;
	incomeInPercent: number;
	pnl: string;
	commissionPaid: string;
	investmentDate: string;
	myAssets: string;
}

export interface RiskManagement {
	managerTrailingStopEnabled: boolean;
	managerTrailingStopValue: string;
	globalTrailingStopEnabled: boolean;
	globalTrailingStopValue: string;
}

export interface RiskManagementValues extends RiskManagement {
	managerTrailingStopTokenRate: BigNumber;
	globalTrailingStopTokenRate: BigNumber;
}

export type DraftFund = Omit<
	Fund,
	'id'
	| 'owner'
	| 'ownerName'
	| 'ownerAvatarUrl'
	| 'manager'
	| 'aum'
	| 'realAum'
	| 'performance'
	| 'holders'
	| 'show'
	| 'nextReport'
	| 'maxDrawdownInPercent'
	| 'annualYieldInPercent'
	| 'investmentInfo'
	| 'ownerInfo'
	| 'chartData'
	| 'image'
	| 'whitelistedTokens'
	| 'tradingAddress'
	| 'chainId'
	| 'managerShare'
	| 'managerAvatarUrl'
	| 'managerName'
	| 'updatedAt'
	| 'state'
> & {
	imageBase64: string | null;
	tokenIds: number[];
	enabledServices: EnabledServices;
	managerAddress: string;
	managerShare: number;
	isPrivate: boolean;
	riskManagement: RiskManagement;
};

export interface FundsState {
	createModalOpen: boolean;
	createSuccess: boolean;
	createLoading: boolean;
	draft: DraftFund;
	fundsById: Record<string, Fund>;
	fundLoadingById: Record<string, boolean>;
	cancelDepositLoadingById: Record<string, boolean>;
	cancelWithdrawLoadingById: Record<string, boolean>;
	setIsClosedLoadingById: Record<string, boolean>;
	claimFundFeesLoadingById: Record<string, boolean>;
	sortBy: FundsSortState;
	applicationsById: Record<string, [string, string, string]>;
}

export interface FundsSortState {
	market: string;
	myFunds: string;
	investment: string;
}

export interface Commissions {
	sf: number;
	mf: number;
	pf: number;
}

export interface ManagerSummary {
	AUM: string;
	commission: string;
	investors: number;
	AUMMonthlyChange: string;
	investorsMonthlyChange: number;
}
