import { BigNumber } from 'ethers';
import { useEffect, useMemo } from 'react';
import { useWeb3Providers } from './use-web3-providers';
import { Datafeed__factory } from '../contracts/types';
import { BASE_CURRENCY_FEED_ADDRESSES, ChainId } from '../common/constants';
import { useQuery } from '@tanstack/react-query';

export function useBaseCurrencyPrice(chainId?: ChainId | null): BigNumber | null {
	const providers = useWeb3Providers();
	const contract = useMemo(() => {
		if (!chainId) return null;
		return Datafeed__factory.connect(BASE_CURRENCY_FEED_ADDRESSES[chainId], providers[ChainId.Arbitrum]);
	}, [chainId, providers]);
	const { data } = useQuery(
		['baseCurrencyPrice', chainId],
		() => contract
			? Promise.all([
				contract.latestAnswer(),
				contract.decimals(),
			]).then(([price, decimals]) => price.div(Math.pow(10, decimals)))
			: null,
		{ refetchInterval: 15000 }
	);
	useEffect(() => () => {
		contract?.removeAllListeners();
	}, [contract]);
	return data || null;
}
