import { toast, ToastContent, ToastOptions } from 'react-toastify';
import React, { useMemo } from 'react';
import { ExternalLink } from '../components/UI/external-link';
import { getExplorerUrl } from './constants';
import { useToastAutoClose } from '../hooks/use-toast-auto-close';
import { Row } from '../components/UI/row';
import { TextS, TextSMBold } from '../components/UI/typography';
import styled from 'styled-components';
import Loading from '../components/UI/loading';

export const helperToast = {
	success: (content: ToastContent, opts?: ToastOptions) => {
		toast.dismiss();
		toast.success(content, opts);
	},
	error: (content: ToastContent, opts?: ToastOptions) => {
		toast.dismiss();
		toast.error(content, opts);
	},
	info: (content: ToastContent, opts?: ToastOptions) => {
		toast.dismiss();
		toast(content, opts);
	},
};

export function showCallContractToast({
	chainId,
	hash,
	toastId,
	title,
}: {
	chainId: number;
	hash?: string;
	toastId?: number;
	title?: string;
}): number {
	if (toastId) {
		toast.update(toastId, { render: <TxToast toastId={toastId} title={title} chainId={chainId} txHash={hash} /> });
		return toastId;
	} else {
		const toastId = Date.now();
		toast(<TxToast toastId={toastId} chainId={chainId} title={title} txHash={hash} />, { toastId, autoClose: false });
		return toastId;
	}
}

// TODO: i18n
export function TxToast({ toastId, chainId, txHash, title }: {
	toastId: number;
	chainId: number;
	title?: string;
	txHash?: string | null;
}): JSX.Element | null {
	const hasError = false;
	useToastAutoClose(!!txHash, toastId);

	const link = useMemo(() => {
		if (!txHash) return <StyledRow>
			Sending transaction
			<StyledLoading size={20} variant="primary" />
		</StyledRow>;

		const txUrl = getExplorerUrl(chainId) + 'tx/' + txHash;
		return (
			<StyledRow>
				Transaction sent
				<StyledLink href={txUrl}>(view status).</StyledLink>
			</StyledRow>
		);
	}, [chainId, txHash]);

	return (
		<Container hasError={hasError}>
			<TextSMBold>{title || 'Transaction sent'}</TextSMBold>
			<Content>{link}</Content>
		</Container>
	);
}

const Content = styled(TextS)`
	margin: 6px 0;
`;


const Container = styled.div<{ hasError: boolean }>`

`;

const StyledLoading = styled(Loading)`
	margin-left: 4px;
`;

const StyledLink = styled(ExternalLink)`
	display: flex;
	margin-left: 4px;
`;

const StyledRow = styled(Row)`
	margin-bottom: 4px;
`;

