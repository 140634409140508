import { useEffect } from 'react';
import { useWallet } from './use-wallet';
import { SUPPORTED_CHAIN_NAMES, SUPPORTED_CHAINS, WALLET_LOCALSTORAGE_KEY } from '../../common/constants';
import { useWeb3React } from '@web3-react/core';
import { availableWallets } from './wallets';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks/redux';
import { setIsUnsupportedChainId } from '../../store/slices/wallet';

export function useRestoreWallet(): void {
	const { t } = useTranslation();
	const { isActive, chainId } = useWeb3React();
	const { connect, disconnect } = useWallet();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (chainId && !SUPPORTED_CHAINS.map(Number).includes(chainId)) {
			toast(t('Modal.Wallet.UnsupportedChain', { supportedChainNames: SUPPORTED_CHAIN_NAMES }), { type: 'error' });
			dispatch(setIsUnsupportedChainId(true));
		} else {
			dispatch(setIsUnsupportedChainId(false));
		}
	}, [chainId, disconnect, dispatch, t]);

	useEffect(() => {
		if (isActive) return;
		const providerKey = localStorage.getItem(WALLET_LOCALSTORAGE_KEY);
		if (!providerKey) return;
		const wallet = availableWallets.find(wallet => wallet.key === providerKey);
		if (!wallet) return;
		connect(wallet, true).then(console.log).catch(console.error);
	}, [connect, isActive]);
}
