import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Loading from './UI/loading';
import { Button, TransparentButton } from './UI/button';
import { useState } from 'react';
import { ModalData, useModalContext } from './modal/modal-provider';
import { TextM } from './UI/typography';

export interface WarningModalContentProps extends ModalData {}

export function WarningModalContent({ id, caption, onContinue }: WarningModalContentProps): JSX.Element | null {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { closeModal, closeCurrentModal } = useModalContext();

	const handleContinue = async (): Promise<void> => {
		try {
			setLoading(true);
			await onContinue?.();
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	}

	// TODO: i18n
	return (
		<>
			<Info>
				{caption}
			</Info>
			<Buttons>
				<CancelButton onClick={() => id ? closeModal(id) : closeCurrentModal()}>
					{t('Common.Button.Cancel')}
				</CancelButton>
				<ContinueButton onClick={handleContinue}>
					{loading && <Loading />}
					{t('Common.Button.Continue')}
				</ContinueButton>
			</Buttons>
		</>
	);
}

const CancelButton = styled(TransparentButton)`
	width: 100%;
  margin-right: 8px;
`;

const Info = styled(TextM)`
	margin-bottom: 32px;
`;

const ContinueButton = styled(Button)`
  width: 100%;
	margin-left: 8px;
`;

const Buttons = styled.div`
	display: flex;
	align-items: center;
`;
