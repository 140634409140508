/*import { useDispatch, useSelector } from 'react-redux';
import { selectTheme } from '../../store/slices/app';*/
import styled from 'styled-components';
import { ReactComponent as LightImage } from '../../asset/images/light.svg';
import { ReactComponent as DarkImage } from '../../asset/images/dark.svg';
import { useTranslation } from 'react-i18next';

export function ThemeSwitcher(): JSX.Element {
	/*const dispatch = useDispatch();
	const theme = useSelector(selectTheme);*/
	const { t } = useTranslation();
	return (
		<Container>
			<ActiveItem>
				<StyledLightImage />
				<ThemeItem>{t(`Sidebar.Theme.Light`)}</ThemeItem>
			</ActiveItem>
			<NonActiveItem>
				<StyledDarkImage />
				<ThemeItem>{t(`Sidebar.Theme.Dark`)}</ThemeItem>
				<Soon>{t('Sidebar.Theme.Soon')}</Soon>
			</NonActiveItem>
		</Container>
	);
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
	width: 100%;
  background: #F4F4F4;
  border-radius: 40px;
`;

const ActiveItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 8px;
	width: 50%;
  background: #FCFCFC;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0 -1px 1px rgba(0, 0, 0, 0.04), inset 0 2px 0 rgba(255, 255, 255, 0.25);
  border-radius: 32px;
	cursor: pointer;
`;

const NonActiveItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 8px;
  color: #737373;
  width: 50%;
`;

const StyledLightImage = styled(LightImage)`
	margin-right: 8px;
`;

const StyledDarkImage = styled(DarkImage)`
  margin-right: 8px;
`;

const ThemeItem = styled.div`
  font-size: 15px;
  font-weight: 600;
  user-select: none;
`;

const Soon = styled.div`
	color: #fff;
  background: #9A9FA5;
  border-radius: 4px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
	padding: 0 8px;
	margin-left: 8px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
	text-transform: lowercase;
`;

